/* eslint-disable jsx-a11y/anchor-is-valid */

type Props = {
  className: string
  description: string
  icon: boolean
  stats: number
  labelColor: string
  textColor: string
}



const CardsWidget7 = ({className, description, icon, stats, labelColor, textColor}: Props) => (
  <div className={`card card-flush ${className}`}
  >
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <div className='card-title d-flex flex-column'>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>33</span>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>Total Inspections</span>
        </div>
      </div>
    </div>
    <div className='card-body d-flex align-items-end pt-0'>
      <div className='d-flex align-items-center flex-column mt-3 w-100'>
        <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
          <span className='text-gray-500'>23 Completed</span>
          <span className='text-gray-500'>10 Pending</span>
          {/* <span>72%</span> */}
        </div>

  
      </div>
    </div>

  </div>
)
export {CardsWidget7}
