import {FC} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {getAuth, useAuth} from '../../modules/auth'
import PieChart from './PieChart'
import Scheduler from '../../../_metronic/partials/widgets/lists/Scheduler'
import {
  ListsWidget6,
  CardsWidget7,
  CardsWidget20,
  ChartsWidget1,
  ChartsWidget3,
  ListsWidget8,
  
} from '../../../_metronic/partials/widgets'
import StaffDash from '../../../_metronic/partials/widgets/lists/StaffDash'

import { ListsWidget10 } from '../../../_metronic/partials/widgets/lists/ListsWidget10'
import BuyerDash from '../../../_metronic/partials/widgets/lists/BuyerDash'

const DashboardPage: FC = () => (
  <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      {/* begin::Col */}
      <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
        <div className='d-flex h-md-100 mb-xl-2' style={{height: '100%'}}>
          <div className='flex-grow-1 me-3'>
            <CardsWidget20
              className='h-200px w-450px'
              description='Active Projects'
              color='#F1416C'
              img={toAbsoluteUrl('/media/patterns/vector-1.png')}
            />
          </div>
          <div className='flex-grow-1 ms-3'>
            <CardsWidget7
              className='h-200px w-450px'
              description='Professionals'
              icon={false}
              stats={357}
              labelColor='dark'
              textColor='gray-300'
            />
          </div>
        </div>
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div>
      <div className='mb-8 mt-n3'>
        <PieChart />
      </div>
    </div>
    {/* end::Row */}

    {/* begin::Row */}
    <div className='row gy-5 g-xl-8'>
      <div className='col-xl-4'>
        <ListsWidget6 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ChartsWidget1 className='card-xl-stretch mb-xl-8' />
      </div>
      <div className='col-xl-4'>
        <ChartsWidget3 className='card-xl-stretch mb-xl-8' />
      </div>
    </div>
    {/* end::Row */}
  </>
)

const InspectorDashboard: FC = () => (
  <>
  <div >
    <ListsWidget8 className='' setFilter={setFilter} />
    {/* Calender compnent render */}
    
    </div>
  </>
)

const SellerDashboard: FC = () =>
   <>
   <ListsWidget10></ListsWidget10>
   </>



const BuyerDashboard: FC = () =>
  <>
  <BuyerDash></BuyerDash>
  </>

const StaffDashboard: FC = () =>
  <>
  <StaffDash></StaffDash>
  </>



const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const auth = useAuth()
  const role = auth.currentUser?.role  

  if (role === 1) {
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <DashboardPage />
      </>
    )
  } else if (role === 2) {
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Inspector Dashboard'})}</PageTitle>
        <InspectorDashboard />
      </>
    )
  } 
  else if (role === 3) {
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Inspector Dashboard'})}</PageTitle>
        <BuyerDashboard />
      </>
    )
  }
  else if (role === 4) {
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Seller Dashboard'})}</PageTitle>
        <SellerDashboard />
      </>
    )
  } 
  
  else if (role === 5) {
    return (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Staff Dashboard'})}</PageTitle>
        <StaffDashboard />
      </>
    )
  }
  else {
    return null
  }
}

export {DashboardWrapper}

function setFilter(filter: string): void {
  throw new Error('Function not implemented.')
}
