import React, {useEffect, useState} from 'react'
import axios from 'axios'
import img1 from '../../../../_metronic/assets/1.webp'
import img2 from '../../../../_metronic/assets/2.jpeg'
import img3 from '../../../../_metronic/assets/3.jpeg'
import img4 from '../../../../_metronic/assets/4.webp'
import img5 from '../../../../_metronic/assets/5.jpeg'
import img6 from '../../../../_metronic/assets/6.jpeg'
import img7 from '../../../../_metronic/assets/House.webp'
import img8 from '../../../../_metronic/assets/abc.webp'
import img9 from '../../../../_metronic/assets/prop.jpeg'
import {useNavigate} from 'react-router-dom'
import {Modal, Button, Form, Col} from 'react-bootstrap'

import {useFormik} from 'formik'
import * as Yup from 'yup'
import {getUserByToken, login} from '../../auth/core/_requests'
import {useAuth} from '../../auth'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9]

const statesMapping: {[key: number]: string} = {
  1: 'New South Wales',
  2: 'Victoria',
  3: 'Queensland',
  4: 'Western Australia',
  5: 'South Australia',
  6: 'Tasmania',
  7: 'Australian Capital Territory',
  8: 'Northern Territory',
}

interface Inspector {
  inspector_id: number
  certification_details: string
  insurance_details: string
  experience_years: number
  rating: number
  is_disabled: boolean
  firstname: string
  lastname: string
  phone_no: string
  availability: string[]
  terms: boolean
  cancel_policy: boolean
  code_conduct: boolean
}

interface CardData {
  service_id: number
  service_name: string
  pricing: string
  description: string
  inspector: Inspector
  imageUrl?: string
  pricingRanges: PricingRange[] // Add this property to hold bedroom pricing ranges
  price_per_bathroom : string
  price_per_garage: string


  
}

interface PricingRange {
  pricing_id: number
  min_bedrooms: number
  max_bedrooms: number
  price: string
}



interface User {
  user_id: number
  email: string
  phone_no: string
  // other properties if needed
}

interface Property {
  property_id: number
  address: string
  city: string
  state: string
  zipcode: string
  seller: {
    seller_id: number
    user_id: number
    firstname: string
    lastname: string
    phone_no: string
    is_disabled: boolean
  } | null
  buyer: any | null
  requests: {
    request_id: number
    request_date: string
    status: boolean
    inspection_date: string | null
    requester_role: number
    assigned_date: string | null
    accept: boolean
  }[]
}

const InspectorDemo: React.FC = () => {
  const [cardsData, setCardsData] = useState<CardData[]>([])
  const [properties, setProperties] = useState<Property[]>([])
  const [showModal, setShowModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string | null>(null)
  const [checkedOptions, setCheckedOptions] = useState(new Set())
  const [selectedSellerRole, setSelectedSellerRole] = useState('')
  const [currentUserId, setCurrentUserId] = useState<number | null>(null) 

  // const [userAlreadyExists, setUserAlreadyExists] = useState(false);
  const [matchingUsers, setMatchingUsers] = useState<User[]>([])
  const [selectedRange, setSelectedRange] = useState<string>('1-3') // Default bedroom range
  const [selectedBathrooms, setSelectedBathrooms] = useState<number>(1) // Default bathroom count
  const [selectedGarages, setSelectedGarages] = useState<number>(1) // Default garage count




  // const [role, setRole] = useState(3)
  const [step, setStep] = useState(1)
  const [selectedRole, setSelectedRole] = useState<number | null>(null)

  const [formData, setFormData] = useState({
    offerAccepted: null as boolean | null,
    coolingOffDate: '',
    goingToAuction: null as boolean | null,
    auctionDate: null,
    bedrooms: 1,
    additionalDwelling: null as boolean | null,
    inspectorNotes: '',
  })

  const [secondPerson, setSecondPerson] = useState(false)
  const [userFormData, setUserFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_no: '',

    state_id: 0,
    role: 0,
  })

  const [addressFormData, setAddressFormData] = useState({
    address: '',
    city: '',
    state: '',
    zipcode: '',
  })

  const [sellingAgentData, setSellingAgentData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    contact: '',
  })

  const [clientDetails, setClientDetails] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_no: '',
  })

  const sellerLastHandleChange = (event: {target: {value: React.SetStateAction<string>}}) => {
    setSelectedSellerRole(event.target.value)
    console.log('Selected Seller Role:', event.target.value)
  }

  const handleClientInputChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setClientDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleClientCheckboxChange = (e: {target: {id: string; checked: boolean}}) => {
    const {id, checked} = e.target
    setCheckedOptions((prev) => {
      const newSet = new Set(prev)
      if (checked) {
        newSet.add(id)
      } else {
        newSet.delete(id)
      }
      return newSet
    })
  }

  const handleSubmit = async () => {
    const requestBody = {
      userId: currentUserId,
      live: selectedOption === '1',
      invester: selectedOption === '3',
      buyersAgent: selectedOption === '2',
      conveyancer: selectedOption === '4',
      contract: checkedOptions.has('checkbox-0'),
      mortgage: checkedOptions.has('checkbox-1'),
      negotiation: checkedOptions.has('checkbox-2'),
      valuation: checkedOptions.has('checkbox-3'),
    }
    console.log(requestBody)

    try {
      const response = await axios.post('https://api.inspection.payplatter.in/api/request-detail', requestBody)
      console.log('Data submitted successfully:', response.data)
      navigate('/website/payment')
    } catch (error) {
      console.error('Error submitting data:', error)
    }
  }

  const handleClientOptionChange = (e: {target: {value: string}}) => {
    setSelectedOption(e.target.value)
  }

  const handleSellingAgentInputChange = (field: any, value: any) => {
    setSellingAgentData((prevData) => ({
      ...prevData,
      [field]: value,
    }))
  }

  const handleAddressInputChange = (field: string, value: string) => {
    setAddressFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleUserInputChange = (field: string, value: string | number) => {
    setUserFormData({
      ...userFormData,
      [field]: value,
    })
  }

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecondPerson(e.target.checked)
  }

  const handleBedRadioChange = (field: keyof typeof formData, value: number) => {
    setFormData({...formData, [field]: value})
  }

  const handleInputChange = (field: keyof typeof formData, value: string) => {
    setFormData({...formData, [field]: value})
  }

  const handleRadioChange = (field: keyof typeof formData, value: string | boolean) => {
    const booleanValue = typeof value === 'string' ? value === 'true' : value
    setFormData({...formData, [field]: booleanValue})
  }

  const handleRoleSelection = (role: number) => {
    setSelectedRole(role)
    setStep(2)
  }

  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get('https://api.inspection.payplatter.in/api/inspection-services')
      .then((response) => {
        const dataWithImages = response.data.map((item: CardData, index: number) => ({
          ...item,
          imageUrl: images[index % images.length],
        }))
        setCardsData(dataWithImages)
        console.log(response)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  // Handle dropdown selection
  // const handleRangeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedRange(event.target.value)
  // }

  // Filter cards based on the selected range
  const filteredCards = cardsData.filter((card) => {
    if (!selectedRange) return true
    const pricingRange = card.pricingRanges.find(range => {
      const rangeValue = selectedRange.split('-').map(Number)
      return range.min_bedrooms === rangeValue[0] && range.max_bedrooms === rangeValue[1]
    })
    return pricingRange !== undefined
  })


  const fetchProperties = async () => {
    try {
      const response = await axios.get(`https://api.inspection.payplatter.in/api/properties`)
      setProperties(response.data)
    } catch (error) {
      console.error('Error fetching properties:', error)
    }
  }

  useEffect(() => {
    fetchProperties()
  }, [])

  const handleOpenProfilePage = (card: CardData) => {
    const inspectorData = {
      firstname: card.inspector.firstname,
      lastname: card.inspector.lastname,
      service_name: card.service_name,
      phone_no: card.inspector.phone_no,
      pricing: card.pricing,
      description: card.description,
      certification_details: card.inspector.certification_details,
      insurance_details: card.inspector.insurance_details,
      experience_years: card.inspector.experience_years,
      rating: card.inspector.rating,
      availability: card.inspector.availability,
      idOfInspector : card.inspector.inspector_id,
    }

    navigate('/website/inspector-detailprofile', {state: inspectorData})
  }

  const handleOrderReportClick = (card: CardData) => {
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const propertySubmitHandle = async () => {
    console.log(formData)

    const apiEndpoint = 'https://api.inspection.payplatter.in/api/guest-user'

    const requestData = {
      cooling: formData.offerAccepted,
      coolingDate: formData.coolingOffDate,
      auction: formData.goingToAuction,
      auctionDate: formData.auctionDate,
      bedrooms: formData.bedrooms,
      dwel: formData.additionalDwelling,
      concerns: formData.inspectorNotes,
    }

    console.log(requestData)
    localStorage.setItem('propertyData', JSON.stringify(requestData))

    try {
      setStep(3)
    } catch (error) {
      console.error('Error submitting form data:', error)
    }
  }

  const [submitDisabled, setSubmitDisabled] = useState(false)

  const userSubmitHandle = async () => {
    const userData = {
      firstname: userFormData.firstname,
      lastname: userFormData.lastname,
      email: userFormData.email,
      phone_no: userFormData.phone_no,
      state_id: userFormData.state_id,
      role: selectedRole,
    }

    const apiEndpoint = 'https://api.inspection.payplatter.in/api/users'

    try {
      const response = await fetch(apiEndpoint)
      if (response.ok) {
        const responseData = await response.json()
        console.log('Fetched users:', responseData)

        // Check if user exists
        const existingUsers = responseData.data.filter(
          (user: {email: string; phone_no: string}) =>
            user.email === userFormData.email || user.phone_no === userFormData.phone_no
        )
        console.log(existingUsers)

        if (existingUsers.length > 0) {
          // Set the matching users and disable the submit button
          setMatchingUsers(existingUsers)
          setSubmitDisabled(true) // Disable the button
        } else {
          // Submit user data if no existing user
          const submitResponse = await fetch(apiEndpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
          })

          if (submitResponse.ok) {
            const submitData = await submitResponse.json()
            console.log('User form data submitted successfully:', submitData)

            const currentUserId = submitData.data[0].user_id
            console.log('Newly created user ID:', currentUserId)
            setCurrentUserId(currentUserId)
            

            setStep(4)
          } else {
            console.error('Failed to submit user form data:', submitResponse.statusText)
          }
        }
      } else {
        console.error('Failed to fetch users:', response.statusText)
      }
    } catch (error) {
      console.error('Error submitting user form data:', error)
    }
  }


  const addressSubmitHandle = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const addressData = {
      address: addressFormData.address,
      city: addressFormData.city,
      state: addressFormData.state,
      zipcode: addressFormData.zipcode,
      user_id: currentUserId,
    }

    console.log(addressData)

    try {
      const response = await fetch('https://api.inspection.payplatter.in/api/properties', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(addressData),
      })

      if (response.ok) {
        const data = await response.json()
        console.log('Address data submitted successfully:', data)
        setStep(5)

        const propertyData = localStorage.getItem('propertyData')
        if (propertyData) {
          const parsedPropertyData = JSON.parse(propertyData)

          const extendedPropertyData = {
            ...parsedPropertyData,
            userId: currentUserId,
          }

          console.log(extendedPropertyData)

          // Submit property data
          const propertyResponse = await fetch('https://api.inspection.payplatter.in/api/guest-user', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(extendedPropertyData),
          })

          if (propertyResponse.ok) {
            const propertyDataResponse = await propertyResponse.json()
            console.log('Property data submitted successfully:', propertyDataResponse)
          } else {
            console.error('Failed to submit property data:', propertyResponse.statusText)
          }
        } else {
          console.error('No property data found in local storage.')
        }
      } else {
        console.error('Failed to submit address data:', response.statusText)
      }
    } catch (error) {
      console.error('Error submitting address data:', error)
    }
  }

  const sellingAgentSubmitHandle = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    console.log('Selling Agent Data:', sellingAgentData)

    try {
      const response = await axios.post(
        `https://api.inspection.payplatter.in/api/agents/${currentUserId}`,
        sellingAgentData
      )
      console.log(sellingAgentData)
      console.log('Agent submitted')

      setStep(6)

      console.log(response)
      console.log(response.data)
    } catch (error) {
      console.error('Error submitting data:', error)
    }
  }

  const ClientSubmitHandle = async () => {
    const requestBody = {
      userId: currentUserId,
      firstname: clientDetails.firstname,
      lastname: clientDetails.lastname,
      email: clientDetails.email,
      phone: clientDetails.phone_no,
    }

    try {
      const response = await fetch('https://api.inspection.payplatter.in/api/client-detail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })

      if (response.ok) {
        console.log('Client data submitted successfully')
        navigate('/website/payment')
      } else {
        console.error('Failed to submit client data')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const SellerLastHandleSubmit = () => {
    if (!selectedSellerRole) {
      alert('Please select a role before submitting.')
      return
    }

    console.log('Form submitted with role:', selectedSellerRole)
    navigate('/website/payment')
  }

  const getHeading = (step: number) => {
    switch (step) {
      case 1:
        return 'I am a'
      case 2:
        return 'Common Details'
      case 3:
        return 'Your Details'
      case 4:
        return 'Property Details'
      case 5:
        return 'Agent’s Details'
      case 6:
        return 'I`m buying to'
      case 7:
        return 'Other'
      default:
        return ''
    }
  }
  let heading = getHeading(step)

  const prevStep = () => {
    setStep((prevStep) => Math.max(prevStep - 1, 1))
  }

  const accordionData = [
    {
      title: 'Get a free contract review!',
      content:
        'Receive a comprehensive review of your property contract from an expert. Ensure all legal aspects are covered before signing.',
    },
    {
      title: 'Get advice about your mortgage',
      content:
        'Consult with a mortgage advisor to understand your financing options and secure the best rates for your property purchase.',
    },
    {
      title: 'Consult with a negotiation expert',
      content:
        'Engage with a negotiation specialist to enhance your bargaining position and get the best deal on your property.',
    },
    {
      title: 'Get a valuation report for your property',
      content:
        'Obtain a detailed valuation report to assess the true market value of your property and make informed decisions.',
    },
  ]

  const [showLoginModal, setShowLoginModal] = useState(false)
  const [typedEmail, setTypedEmail] = useState('')
  const {saveAuth, setCurrentUser} = useAuth()

  const handleLoginClick = (email: string) => {
    setTypedEmail(email)
    setShowLoginModal(true)
  }

  const formik = useFormik({
    initialValues: {
      email: typedEmail || '',
      password: 'Pass_123',
    },
    validationSchema: loginSchema,
    enableReinitialize: true,
    onSubmit: async (values, {setSubmitting, setStatus}) => {
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)

        const {data: user} = await getUserByToken(auth.accessToken)
        setCurrentUser(user)
        console.log(user)

        setCurrentUserId(user.user_id)
        console.log('Current User ID:', user.user_id)

        setStep(4)
        setShowLoginModal(false)
      } catch (error) {
        console.error(error)
        setStatus('The login details are incorrect')
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {}, [setCurrentUserId])



  const [data, setData] = useState(null);


  // const [selectedRange, setSelectedRange] = useState<string>('') // For bedroom range
  // const [selectedBathrooms, setSelectedBathrooms] = useState<number>(1)
  // Handle dropdown selection for bedroom range
  const handleRangeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRange(event.target.value)
  }

  // Handle dropdown selection for number of bathrooms
  const handleBathroomsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedBathrooms(Number(event.target.value))
  }

   // Handle dropdown selection for number of garages
   const handleGaragesChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedGarages(Number(event.target.value))
  }



  return (
    <div className='container'>
      <div className='row justify-content-center'>
      <div className='row  mb-3 justify-content-end'>
        <div className='col-md-2'>
          {/* Dropdown for selecting bedroom range */}
          <select value={selectedRange} onChange={handleRangeChange} className="form-select">
            <option value="1-3">1-3 Bedrooms</option>
            <option value="4-6">4-6 Bedrooms</option>
            <option value="7-9">7-9 Bedrooms</option>
            <option value="10-99">10-99 Bedrooms</option>
          </select>
        </div>
        <div className='col-md-2'>
          {/* Dropdown for selecting number of bathrooms */}
          <select value={selectedBathrooms} onChange={handleBathroomsChange} className="form-select">
            <option value="1">1 Bathroom</option>
            <option value="2">2 Bathrooms</option>
            <option value="3">3 Bathrooms</option>
            <option value="4">4 Bathrooms</option>
            <option value="5">5 Bathrooms</option>
            <option value="6">6+ Bathrooms</option>
          </select>
        </div>
        <div className='col-md-2'>
          {/* Dropdown for selecting number of garages */}
          <select value={selectedGarages} onChange={handleGaragesChange} className="form-select">
            <option value="1">1 Garage</option>
            <option value="2">2 Garages</option>
            <option value="3">3 Garages</option>
            <option value="4">4 Garages</option>
            <option value="5">5 Garages</option>
            <option value="6">6+ Garages</option>
          </select>
        </div>
      </div>
      <div className='row justify-content-center'>
        {cardsData.map((card) => {
          // Find the pricing range for the selected bedroom range
          const pricingRange = card.pricingRanges.find(range => {
            const rangeValue = selectedRange.split('-').map(Number)
            return range.min_bedrooms === rangeValue[0] && range.max_bedrooms === rangeValue[1]
          })

          // Calculate displayed price based on bedroom price, bathroom count, and garage count
          const bedroomPrice = pricingRange ? parseFloat(pricingRange.price) : parseFloat(card.pricing)
          const bathroomPrice = parseFloat(card.price_per_bathroom) * selectedBathrooms
          const garagePrice = parseFloat(card.price_per_garage) * selectedGarages
          const displayedPrice = (bedroomPrice + bathroomPrice + garagePrice).toFixed(2)

          return (
            <div key={card.service_id} className='col-md-10 mb-4'>
              <div className='card' style={{maxWidth: '100%', margin: '0 auto'}}>
                <div className='row g-0'>
                  <div className='col-md-4'>
                    <img
                      src={card.imageUrl}
                      alt={card.service_name}
                      className='img-fluid rounded-start'
                      style={{height: '100%', objectFit: 'cover'}}
                    />
                  </div>
                  <div className='col-md-8'>
                    <div className='card-body'>
                      <h2 className='card-title'>{card.service_name}</h2>
                      <h3 className='card-title badge badge-success'>
                        {`${card.inspector.firstname} ${card.inspector.lastname}`} 
                      </h3>
                      <p className='card-text'>{card.description}</p>
                      {/* Display the price based on selected bedroom, bathroom, and garage counts */}
                      <span className='badge badge-light-success priceBadge'>
                        {`$${displayedPrice}`}
                      </span>
                      <div className='d-flex justify-content-end align-items-end mt-3'>
                        <button
                          className='btn btn-primary'
                          type='button'
                          onClick={() => handleOrderReportClick(card)}
                          style={{padding: '8px 12px'}}
                        >
                          Order Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>

      </div>

      <Modal show={showModal} onHide={handleCloseModal} centered className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>{heading}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 ? (
            <div className='d-flex justify-content-around'>
              <Button variant='primary' onClick={() => handleRoleSelection(3)}>
                <i className='bi bi-person-fill' style={{fontSize: '2rem'}}></i>
                <div>Buyer</div>
              </Button>
              <Button variant='secondary' onClick={() => handleRoleSelection(4)}>
                <i className='bi bi-person-fill' style={{fontSize: '2rem'}}></i>
                <div>Seller</div>
              </Button>
            </div>
          ) : step === 2 ? (
            <Form>
              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>
                  Have you accepted an offer, and are you currently in a cooling-off period?
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    label='Yes'
                    type='radio'
                    name='offerAccepted'
                    onChange={() => handleRadioChange('offerAccepted', true)}
                  />
                  <Form.Check
                    inline
                    label='No'
                    type='radio'
                    name='offerAccepted'
                    onChange={() => handleRadioChange('offerAccepted', false)}
                  />
                </div>
                {formData.offerAccepted === true && (
                  <Form.Control
                    type='date'
                    className='mt-2'
                    value={formData.coolingOffDate}
                    style={{width: '70%'}}
                    onChange={(e) => handleInputChange('coolingOffDate', e.target.value)}
                  />
                )}
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>Is the property scheduled for auction?</Form.Label>
                <div>
                  <Form.Check
                    inline
                    label='Yes'
                    type='radio'
                    name='goingToAuction'
                    onChange={() => handleRadioChange('goingToAuction', true)}
                  />
                  <Form.Check
                    inline
                    label='No'
                    type='radio'
                    name='goingToAuction'
                    onChange={() => handleRadioChange('goingToAuction', false)}
                  />
                </div>
                {formData.goingToAuction === true && (
                  <Form.Control
                    type='date'
                    className='mt-2'
                    value={formData.auctionDate || ''}
                    style={{width: '70%'}}
                    onChange={(e) => handleInputChange('auctionDate', e.target.value)}
                  />
                )}
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>
                  How many bedrooms are available in the property?
                </Form.Label>
                <div>
                  {[1, 2, 3, 4, 5, 6].map((number) => (
                    <Form.Check
                      key={number}
                      inline
                      label={number.toString()}
                      type='radio'
                      name='bedrooms'
                      onChange={() => handleBedRadioChange('bedrooms', number)}
                    />
                  ))}
                </div>
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>
                  Is there an additional dwelling or granny flat on the property?
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    label='Yes'
                    type='radio'
                    name='additionalDwelling'
                    onChange={() => handleRadioChange('additionalDwelling', true)}
                  />
                  <Form.Check
                    inline
                    label='No'
                    type='radio'
                    name='additionalDwelling'
                    onChange={() => handleRadioChange('additionalDwelling', false)}
                  />
                </div>
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>
                  Are there any specific concerns you'd like the inspector to focus on?{' '}
                  <span style={{color: ' #808080'}}>(optional)</span>
                </Form.Label>
                <Form.Control
                  as='textarea'
                  rows={3}
                  style={{width: '70%'}}
                  value={formData.inspectorNotes}
                  onChange={(e) => handleInputChange('inspectorNotes', e.target.value)}
                />
              </Form.Group>

              <div className='d-flex justify-content-between align-items-center'>
                {step > 1 && (
                  <button type='button' onClick={prevStep} className='btn btn-secondary'>
                    Previous
                  </button>
                )}
                {step < 6 && (
                  <Button
                    variant='primary'
                    onClick={propertySubmitHandle}
                    className='btn btn-primary'
                  >
                    Submit and Next
                  </Button>
                )}
              </div>
            </Form>
          ) : step === 3 ? (
            <Form>
              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>First Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter First Name'
                  value={userFormData.firstname}
                  onChange={(e) => handleUserInputChange('firstname', e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>Last Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Last Name'
                  value={userFormData.lastname}
                  onChange={(e) => handleUserInputChange('lastname', e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  value={userFormData.email}
                  onChange={(e) => handleUserInputChange('email', e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>Phone Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Phone Number'
                  value={userFormData.phone_no}
                  onChange={(e) => handleUserInputChange('phone_no', e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>State</Form.Label>
                <Form.Select
                  value={userFormData.state_id}
                  onChange={(e) => handleUserInputChange('state_id', Number(e.target.value))}
                >
                  <option value=''>Select a State</option>
                  {Object.entries(statesMapping).map(([id, name]) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className='mb-5'>
                <Form.Check
                  type='checkbox'
                  label='Add a second person or a company name to the report if you are the successful buyer'
                  checked={secondPerson}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>

              {secondPerson && (
                <>
                  <div style={{display: 'flex', gap: '20px'}}>
                    <Form.Group className='mb-5' style={{flex: 1}}>
                      <Form.Label className='fw-bold'>Second First Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Second First Name'
                        // value={userFormData.secondFirstName}
                        // onChange={(e) => handleUserInputChange('secondFirstName', e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className='mb-5' style={{flex: 1}}>
                      <Form.Label className='fw-bold'>Second Last Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Second Last Name'
                        // value={userFormData.secondLastName}
                        // onChange={(e) => handleUserInputChange('secondLastName', e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </>
              )}

              <div className='d-flex justify-content-between align-items-center'>
                {step > 1 && (
                  <button type='button' onClick={prevStep} className='btn btn-secondary'>
                    Previous
                  </button>
                )}
                {step < 6 && (
                  <Button
                    variant='primary'
                    onClick={userSubmitHandle}
                    className='btn btn-primary'
                    disabled={submitDisabled}
                  >
                    Submit And Next
                  </Button>
                )}
              </div>

             

              {matchingUsers.length > 0 && (
                <div
                  className='alert mt-4 p-4 rounded shadow-sm'
                  style={{backgroundColor: '#f8d7da', borderColor: '#f5c2c7'}}
                >
                  <h5 className='alert-heading text-center mb-4' style={{color: '#721c24'}}>
                    Account Already Exist
                  </h5>
                  {matchingUsers.map((user, index) => (
                    <div
                      key={index}
                      className='p-3 mb-3 rounded border'
                      style={{backgroundColor: '#fdf1f1', borderColor: '#f5c2c7'}}
                    >
                      <p className='mb-1' style={{color: '#7d4045'}}>
                        A user with this{' '}
                        {user.email === userFormData.email ? 'email' : 'phone number'} already
                        exists:
                      </p>
                      <p className='mb-3' style={{fontWeight: 'bold', color: '#a71d2a'}}>
                        {user.email || user.phone_no}
                      </p>
                      <div className='d-flex justify-content-end'>
                        <Button
                          className='btn btn-outline-primary'
                          onClick={() => handleLoginClick(user.email)}
                        >
                          Login
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Form>
          ) : step === 4 ? (
            <Form onSubmit={addressSubmitHandle}>
              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>Address</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Address'
                  value={addressFormData.address}
                  onChange={(e) => handleAddressInputChange('address', e.target.value)}
                />
              </Form.Group>

              <div style={{display: 'flex', gap: '20px'}}>
                <Form.Group className='mb-5' style={{flex: 1}}>
                  <Form.Label className='fw-bold'>City</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter City'
                    value={addressFormData.city}
                    onChange={(e) => handleAddressInputChange('city', e.target.value)}
                  />
                </Form.Group>

                <Form.Group className='mb-5' style={{flex: 1}}>
                  <Form.Label className='fw-bold'>Zipcode</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Zipcode'
                    value={addressFormData.zipcode}
                    onChange={(e) => handleAddressInputChange('zipcode', e.target.value)}
                  />
                </Form.Group>
              </div>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>State</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter State'
                  value={addressFormData.state}
                  onChange={(e) => handleAddressInputChange('state', e.target.value)}
                />
              </Form.Group>

              <div className='d-flex justify-content-between align-items-center'>
                {step > 1 && (
                  <button type='button' onClick={prevStep} className='btn btn-secondary'>
                    Previous
                  </button>
                )}
                {step < 6 && (
                  <Button variant='primary' type='submit' className='btn btn-primary'>
                    Submit and Next
                  </Button>
                )}
              </div>
            </Form>
          ) : step === 5 ? (
            <Form onSubmit={sellingAgentSubmitHandle}>
              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>First Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter First Name'
                  value={sellingAgentData.firstname}
                  onChange={(e) => handleSellingAgentInputChange('firstname', e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>Last Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Last Name'
                  value={sellingAgentData.lastname}
                  onChange={(e) => handleSellingAgentInputChange('lastname', e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  value={sellingAgentData.email}
                  onChange={(e) => handleSellingAgentInputChange('email', e.target.value)}
                />
              </Form.Group>

              <Form.Group className='mb-5'>
                <Form.Label className='fw-bold'>Phone Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Phone Number'
                  value={sellingAgentData.contact}
                  onChange={(e) => handleSellingAgentInputChange('contact', e.target.value)}
                />
              </Form.Group>

              <div className='d-flex justify-content-between align-items-center'>
                {step > 1 && (
                  <button type='button' onClick={prevStep} className='btn btn-secondary'>
                    Previous
                  </button>
                )}
                {step < 6 && (
                  <Button variant='primary' type='submit' className='btn btn-primary'>
                    Submit and Next
                  </Button>
                )}
              </div>
            </Form>
          ) : step === 6 && selectedRole === 3 ? (
            <div>
              <div className='container'>
                <div className='row'>
                  <div className='col-6'>
                    <div className='form-check mb-5'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='roleOptions'
                        id='option1'
                        value='1'
                        onChange={handleClientOptionChange}
                      />
                      <label className='form-check-label' htmlFor='option1'>
                        I want to live
                      </label>
                    </div>
                    <div className='form-check mb-5'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='roleOptions'
                        id='option3'
                        value='3'
                        onChange={handleClientOptionChange}
                      />
                      <label className='form-check-label' htmlFor='option3'>
                        As an Investor
                      </label>
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className='form-check mb-5'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='roleOptions'
                        id='option2'
                        value='2'
                        onChange={handleClientOptionChange}
                      />
                      <label className='form-check-label' htmlFor='option2'>
                        As a Buyer's Agent
                      </label>
                    </div>
                    <div className='form-check'>
                      <input
                        className='form-check-input'
                        type='radio'
                        name='roleOptions'
                        id='option4'
                        value='4'
                        onChange={handleClientOptionChange}
                      />
                      <label className='form-check-label' htmlFor='option4'>
                        As a Conveyancer
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {selectedOption === '1' || selectedOption === '3' ? (
                <div className='card w-85' style={{margin: '0 auto'}}>
                  <div className='card-body'>
                    <div className='accordion' id='accordionPanelsStayOpenExample'>
                      {accordionData.map((item, index) => (
                        <div className='accordion-item' key={index}>
                          <h2 className='accordion-header' id={`panelsStayOpen-heading-${index}`}>
                            <button
                              className='accordion-button'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target={`#panelsStayOpen-collapse-${index}`}
                              aria-expanded='true'
                              aria-controls={`panelsStayOpen-collapse-${index}`}
                            >
                              <input
                                type='checkbox'
                                className='form-check-input me-2'
                                id={`checkbox-${index}`}
                                onChange={handleClientCheckboxChange}
                                style={{marginRight: '10px'}}
                              />
                              <strong className='text-black' style={{marginLeft: '10px'}}>
                                {item.title}
                              </strong>
                            </button>
                          </h2>
                          <div
                            id={`panelsStayOpen-collapse-${index}`}
                            className='accordion-collapse collapse show'
                            aria-labelledby={`panelsStayOpen-heading-${index}`}
                          >
                            <div className='accordion-body'>{item.content}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Navigation Buttons */}
                  <div className='d-flex justify-content-between align-items-center'>
                    {step > 1 && (
                      <button type='button' onClick={prevStep} className='btn btn-secondary'>
                        Previous
                      </button>
                    )}
                    {step < 7 && (
                      <button type='button' onClick={handleSubmit} className='btn btn-primary'>
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              ) : selectedOption === '2' || selectedOption === '4' ? (
                <div className='mt-7'>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault()
                      ClientSubmitHandle()
                    }}
                  >
                    <div className='row mb-5'>
                      <div className='mt-2 mb-3 '>
                        <h2>Client Detail's</h2>
                      </div>
                      <div className='col'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='First Name'
                          name='firstname'
                          value={clientDetails.firstname}
                          onChange={handleClientInputChange}
                        />
                      </div>
                      <div className='col'>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Last Name'
                          name='lastname'
                          value={clientDetails.lastname}
                          onChange={handleClientInputChange}
                        />
                      </div>
                    </div>
                    <div className='mb-5'>
                      <input
                        type='email'
                        className='form-control'
                        placeholder='Email'
                        name='email'
                        value={clientDetails.email}
                        onChange={handleClientInputChange}
                      />
                    </div>
                    <div className='mb-5'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Phone Number'
                        name='phone_no'
                        value={clientDetails.phone_no}
                        onChange={handleClientInputChange}
                      />
                    </div>
                    {/* <button type="submit" className="btn btn-primary">Submit</button> */}
                    <div className='d-flex justify-content-between align-items-center'>
                      {step > 1 && (
                        <button type='button' onClick={prevStep} className='btn btn-secondary'>
                          Previous
                        </button>
                      )}
                      {step < 7 && (
                        <Button variant='primary' type='submit' className='btn btn-primary'>
                          Submit
                        </Button>
                      )}
                    </div>
                  </form>
                </div>
              ) : null}
            </div>
          ) : step === 6 && selectedRole === 4 ? (
            <div>
              <div className='form-check mb-3'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='sellerRole'
                  id='vendor'
                  value='Vendor'
                  onChange={sellerLastHandleChange}
                />
                <label className='form-check-label' htmlFor='vendor'>
                  Vendor
                </label>
              </div>

              <div className='form-check mb-3'>
                <input
                  className='form-check-input'
                  type='radio'
                  name='sellerRole'
                  id='sellingAgent'
                  value='Selling Agent'
                  onChange={sellerLastHandleChange}
                />
                <label className='form-check-label' htmlFor='sellingAgent'>
                  Selling Agent
                </label>
              </div>

              <div className='d-flex justify-content-between align-items-center mt-5'>
                {step > 1 && (
                  <button type='button' onClick={prevStep} className='btn btn-secondary'>
                    Previous
                  </button>
                )}
                {step < 7 && (
                  <button
                    type='button'
                    className='btn btn-primary'
                    onClick={SellerLastHandleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div>
              <h1>Harshal patil</h1>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal
        show={showLoginModal}
        onHide={handleCloseModal}
        centered
        style={{
          backgroundColor: showLoginModal ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <h3
                style={{
                  marginBottom: '1rem',
                  fontSize: '1.2rem',
                  fontWeight: '500',
                }}
              >
                Login here
              </h3>
              <div style={{marginBottom: '1.5rem'}}>
                <div className='fv-row mb-3'>
                  <div className='form-floating'>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='Email'
                      {...formik.getFieldProps('email')}
                      style={{borderRadius: '8px', borderColor: '#ced4da'}}
                    />
                    <label>Email</label>
                    {formik.touched.email && formik.errors.email && (
                      <div style={{color: 'red', fontSize: '0.875rem'}}>{formik.errors.email}</div>
                    )}
                  </div>
                </div>
                <div className='fv-row mb-3'>
                  <div className='form-floating'>
                    <input
                      type='password'
                      className='form-control'
                      placeholder='Password'
                      {...formik.getFieldProps('password')}
                      style={{borderRadius: '8px', borderColor: '#ced4da'}}
                    />
                    <label>Password</label>
                    {formik.touched.password && formik.errors.password && (
                      <div style={{color: 'red', fontSize: '0.875rem'}}>
                        {formik.errors.password}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <button
                  type='submit'
                  className='btn btn-primary'
                  style={{borderRadius: '8px'}}
                  disabled={formik.isSubmitting || !formik.isValid}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default InspectorDemo
