import React from 'react'
import { Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import InspectionRequests from '../../modules/inspections/components/InspectionRequests'
import InspectionReports from '../../modules/inspections/components/InspectionReports'
const widgetsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Order and Payments',
      path: 'orderandpayment',
      isSeparator: false,
      isActive: false,
    },
    
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

const InspectionManagement = () => {
  return (
    <Routes>
        <Route element={<Outlet />}>


            <Route path="inspection-requests" element={<>
                <PageTitle breadcrumbs={widgetsBreadCrumbs}>Inspection Requests</PageTitle>
                <InspectionRequests />
            </>} />

            <Route path="inspection-reports" element={<>
                <PageTitle breadcrumbs={widgetsBreadCrumbs}>Inspection Reports</PageTitle>
                <InspectionReports />
            </>} />




        </Route>
    </Routes>
  )
}


export default InspectionManagement