import React from 'react'
import SearchSection from '../../modules/website/components/SearchSection'
import Reports from '../../modules/website/components/Reports'
import Footer from '../../modules/website/components/Footer'
import Contact from '../../modules/website/components/Contact'

const Home = () => {
  return (
    <div>
      <SearchSection/>
      <Reports></Reports>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  )
}

export default Home
