import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import img1 from '../../../../_metronic/assets/2.jpeg';
import img2 from '../../../../_metronic/assets/1.webp';
import img3 from '../../../../_metronic/assets/3.jpeg';

interface Service {
  icon: string;
  title: string;
  description: string;
}

const serviceList: Service[] = [
  {
    icon: img1,
    title: 'Building & Pest Inspection',
    description: 'Comprehensive building and pest inspection services to ensure your property is in top condition.',
  },
  {
    icon: img2,
    title: 'Strata Reports',
    description: 'Detailed strata reports to help you understand the condition and management of your property.',
  },
  {
    icon: img3,
    title: 'Contract Review',
    description: 'Expert contract review services to safeguard your interests in property transactions.',
  },
];

interface ServiceItemProps {
  service: Service;
}

const ServiceItem: React.FC<ServiceItemProps> = ({ service }) => (
  <Card style={{
    backgroundColor: '#ffffff',
    boxShadow: '0px 8px 44px rgba(227, 238, 255, 0.48)',
    borderRadius: '20px',
    height: '100%',
    border: 'none'
  }}>
    <Card.Body style={{ padding: '1.5rem 2.5rem' }}>
      <div style={{ marginBottom: '1.5rem', textAlign: 'center' }}>
        <img src={service.icon} alt={service.title} style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%', display: 'block', margin: '0 auto' }} />
      </div>
      <h4 style={{ color: '#2b323c', fontSize: '1.5rem', fontWeight: 700, textTransform: 'uppercase', letterSpacing: '1px', marginBottom: '1rem' }}>{service.title}</h4>
      <p style={{ color: '#2b323c', opacity: 0.8, marginBottom: 0 }}>{service.description}</p>
    </Card.Body>
  </Card>
);

const Reports: React.FC = () => {
  return (
    <div id="seller-section">
    <section style={{
      color: '#2b323c',
      backgroundColor: '#ffffff',
      overflow: 'hidden',
      padding: '60px 0',
   
    }}>
      <Container>
        <Row className='justify-content-center mb-md-5'>
          <Col lg={6} style={{ textAlign: 'center' }}>
            <h2 className='mb-7' style={{
              fontWeight: 'bold',
              fontSize: '30px',
              lineHeight: '25px',
              color: '#2b323c',
              marginBottom: '1rem'
            }}>
              Expert Property Reports at Your Fingertips
            </h2>
            <p style={{
              fontSize: '18px',
              lineHeight: '25px',
              color: '#2b323c',
              opacity: 0.8,
              marginBottom: 0
            }}>
              Get the insights you need before you buy. Our expert inspectors offer comprehensive reports at affordable prices
            </p>
          </Col>
        </Row>
        <Row className='text-center'>
          {serviceList.map((service, i) => (
            <Col md={4} style={{ marginTop: '1.5rem' }} key={i}>
              <ServiceItem service={service} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
    </div>
  );
};

export default Reports;
