import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {RequestPage} from '../../modules/users/components/RequestListPage'

const widgetsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Inspections Request',
    path: 'requestinspections',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RequestInspectionsPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='requestinspections'
        element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Inspection Request</PageTitle>
            <RequestPage />
          </>
        }
      />
      <Route element={<Outlet />}></Route>
    </Route>
  </Routes>
)

export default RequestInspectionsPage
