import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Content} from '../../../../_metronic/layout/components/content'
import { UserList } from './UserList'
import { RequestList } from './RequestList'

const RequestListPage: FC = () => {
  return (
    <div className='bg-white'>
      <div>
        <Content>
          <div
            className='col-xxl-12'
            style={{
              top: '60px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <RequestList className='mb-5 mb-xl-8' />
          </div>
        </Content>
      </div>
    </div>
  )
}

const RequestPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Request Inspections'})}</PageTitle>
      <RequestListPage />
    </>
  )
}

export {RequestPage}
