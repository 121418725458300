import { IoStatsChartSharp, IoPeople } from 'react-icons/io5';
import { MdIncompleteCircle } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { RiMoneyDollarCircleFill } from "react-icons/ri";

const StaffDash = () => {
  return (
    <>
      <h3 className='mb-3'>Active Users</h3>
      <section className="py-3 py-md-5">
        <div className="container h-100">
          <div className="row gx-4 gy-4 h-100">
            <div className="col-12 col-md-6 col-lg-3 h-100">
              <div className="card widget-card border-light shadow-sm bg-white rounded-lg h-100">
                <div className="card-body d-flex align-items-center h-100">
                  <div className="d-flex flex-column flex-grow-1">
                    <h5 className="card-title mb-3">Inspectors</h5>
                    <h4 className="card-subtitle text-body-secondary m-0">10</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-center bg-info rounded-circle text-white p-3 ms-3 opacity-105" >
                    <FaEye size={24} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="card widget-card border-light shadow-sm bg-white rounded-lg h-100">
                <div className="card-body d-flex align-items-center h-100">
                  <div className="d-flex flex-column flex-grow-1">
                    <h5 className="card-title mb-3">Buyers</h5>
                    <h4 className="card-subtitle text-body-secondary m-0">10</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-center bg-info rounded-circle text-white p-3 ms-3">
                    <GiTakeMyMoney size={24} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="card widget-card border-light shadow-sm bg-white rounded-lg h-100">
                <div className="card-body d-flex align-items-center h-100">
                  <div className="d-flex flex-column flex-grow-1">
                    <h5 className="card-title mb-3">Sellers</h5>
                    <h4 className="card-subtitle text-body-secondary m-0">10</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-center bg-info rounded-circle text-white p-3 ms-3">
                    <RiMoneyDollarCircleFill size={24} />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-3">
              <div className="card widget-card border-light shadow-sm bg-white rounded-lg h-100">
                <div className="card-body d-flex align-items-center h-100">
                  <div className="d-flex flex-column flex-grow-1">
                    <h5 className="card-title mb-3">Inspect</h5>
                    <h4 className="card-subtitle text-body-secondary m-0">10</h4>
                  </div>
                  <div className="d-flex align-items-center justify-content-center bg-info rounded-circle text-white p-3 ms-3">
                    <IoStatsChartSharp size={24} />
                  </div>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </section>

      <h3 className='mb-3'>Inspection Details</h3>
      <section className="py-3 py-md-5">
        <div className="container ">


          <div className="card p-4 bg-success bg-opacity-50">
            <div className="">
              <div className="row">
                <div className="col">
                  <div className="card widget-card border-light shadow-sm h-100">
                    <div className="card-body d-flex align-items-start">
                      <div className="d-flex flex-column flex-grow-1">
                        <h5 className="card-title mb-3">Total Inspections</h5>
                        <h4 className="card-subtitle text-body-secondary m-0">20</h4>
                      </div>
                      <div className="d-flex align-items-center justify-content-center bg-success rounded-circle text-white p-3 ms-3">
                        <MdIncompleteCircle size={24}/>
                      </div>
                    </div>
                    <div className="card-body d-flex align-items-start">
                      <div className="d-flex flex-column flex-grow-1">
                        <h5 className="card-title mb-3">Pending Inspection</h5>
                        <h4 className="card-subtitle text-body-secondary m-0">10</h4>
                      </div>
                      <div className="d-flex align-items-center justify-content-center bg-success rounded-circle text-white p-3 ms-3">
                        <MdOutlinePendingActions size={24} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card widget-card border-light shadow-sm mb-4">
                    <div className="card-body d-flex align-items-center">
                      <div className="d-flex flex-column flex-grow-1">
                        <h5 className="card-title mb-3">Inspections Completed</h5>
                        <h4 className="card-subtitle text-body-secondary m-0">10</h4>
                      </div>
                      <div className="d-flex align-items-center justify-content-center bg-success rounded-circle text-white p-3 ms-3">
                        <IoPeople size={24} />
                      </div>
                    </div>
                  </div>
                  <div className="card widget-card border-light shadow-sm">
                    <div className="card-body d-flex align-items-center">
                      <div className="d-flex flex-column flex-grow-1">
                        <h5 className="card-title mb-3">Inspections Done Today</h5>
                        <h4 className="card-subtitle text-body-secondary m-0">10</h4>
                      </div>
                      <div className="d-flex align-items-center justify-content-center bg-success rounded-circle text-white p-3 ms-3">
                        <IoPeople size={24} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default StaffDash;