import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import FileUploadWrapper from '../pages/FileUpload/FileUploadWrapper'
import OrderAndPaymentPage from '../pages/Order_and_payment/OrderAndPaymentPage'
import ReportsPage from '../pages/Reports/ReportsPage'
import RequestInspectionsPage from '../pages/request-inspections/RequestInspectionsPage'
import PropertyDetailsPage from '../modules/website/components/PropertyDetailsPage'
import AssignInspectorPage from '../pages/assignInspector/AssignInspectorPage'
import {TablesWidget10} from '../../_metronic/partials/widgets'
import { Signup } from '../modules/auth/components/Signup'

// Deepak
import InspectionManagement from '../pages/inspection-management/InspectionManagement'


const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))


  const UserManagmentPage = lazy(() => import('../pages/user-management/UserManagmentPage'))
  const WebsiteManagmentPage = lazy(() => import('../pages/website/WebsiteManagmentPage'))

  // const InspectorManagementPage = lazy(() => import('../pages/inspection-management/InspectionManagement'))

  return (
    <Routes>
      <Route
        path='website/*'
        element={
          <SuspensedView>
            <WebsiteManagmentPage />
          </SuspensedView>
        }
      />

      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />



        <Route 
          path="inspections-management/*"
          element={
            <SuspensedView>
              <InspectionManagement />
            </SuspensedView>
          }
        />
        

        <Route path='fileupload' element={<FileUploadWrapper />} />

        <Route path='/signup' element={<Signup/>} />

        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}

        <Route
          path='user/*'
          element={
            <SuspensedView>
              <UserManagmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='assign/*'
          element={
            <SuspensedView>
              <AssignInspectorPage />
            </SuspensedView>
          }
        />

        <Route
          path='inspections/*'
          element={
            <SuspensedView>
              <RequestInspectionsPage />
            </SuspensedView>
          }
        />

        <Route
          path='orderandpayment/*'
          element={
            <SuspensedView>
              <OrderAndPaymentPage />
            </SuspensedView>
          }
        />

        <Route
          path='reports/*'
          element={
            <SuspensedView>
              <ReportsPage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
