/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'
import {Link} from 'react-router-dom'

const ListsWidget10: React.FC = () => {
  return (
    <div className={`card `}>
      <div className={`card-header border-0 py-3 `}></div>

      <div className='card-body p-0'>
        <div className='card-p mt-n10 position-relative'>
          <div className='row g-0'>
            <Link
              to='/inspections/requestinspections'
              className='col-4 bg-light-warning px-6 py-8 rounded-2 me-7 mb-7 d-flex justify-content-between align-items-center hover-transition'
            >
              <div className='d-flex flex-column'>
                <KTSVG
                  path='/media/icons/duotune/general/gen032.svg'
                  className='svg-icon-3x svg-icon-warning d-block my-2'
                />
                <a href='inspections/requestinspections' className='text-warning fw-semibold fs-6'>
                  Total Requested Inspections
                </a>
              </div>
              {/* <h1 className='mb-0 me-3'>25</h1> */}
              <span className='mb-0 me-5 fs-4 badge badge-light-success'>25</span>
            </Link>

            <Link
              to='/inspections/requestinspections'
              className='col-4 bg-light-danger px-6 py-8 rounded-2 me-7 mb-7 d-flex justify-content-between align-items-center hover-transition'
            >
              <div className='d-flex flex-column'>
                <KTSVG
                  path='/media/icons/duotune/general/gen032.svg'
                  className='svg-icon-3x svg-icon-danger d-block my-2'
                />
                <a href='inspections/requestinspections' className='text-danger fw-semibold fs-6'>
                  Total Created Property's
                </a>
              </div>
              <span className='mb-0 me-5 fs-4 badge badge-light-success'>4</span>
            </Link>
          </div>

          <div className='row g-0'>
            <Link
              to='/inspections/requestinspections'
              className='col-4 bg-light-success px-6 py-8 rounded-2 me-7 mb-7 d-flex justify-content-between align-items-center hover-transition'
            >
              <div className='d-flex flex-column'>
                <KTSVG
                  path='/media/icons/duotune/communication/com010.svg'
                  className='svg-icon-3x svg-icon-success d-block my-2'
                />
                <a href='inspections/requestinspections' className='text-success fw-semibold fs-6'>
                  Total Completed Inspections
                </a>
              </div>
              <span className='mb-0 me-5 fs-4 badge badge-light-success'>12</span>
            </Link>
            <style>
              {`
          .hover-transition {
            transition: transform 0.3s ease;
          }

          .hover-transition:hover {
            transform: scale(1.02);
          }
        `}
            </style>
          </div>
        </div>
      </div>
    </div>
  )
}

export {ListsWidget10}

