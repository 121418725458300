import React from 'react'
import {useNavigate} from 'react-router-dom'

const Navbar = () => {
  const Navigate = useNavigate()

  const handleLogin = () => {
    Navigate('/auth/login')
  }

  const handleSignin = () => {
    Navigate('/auth/registration')
  }


  // Function to handle smooth scrolling
  const handleScroll = (e: React.MouseEvent<HTMLAnchorElement>, target: string) => {
    e.preventDefault();
    const element = document.querySelector(target);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <nav className='navbar navbar-expand-lg m-3 shadow-lg opacity-100 bg-gray rounded mt-4 position-fixed'>
        <div className='container-fluid d-flex align-items-center justify-content-between'>
          {/* Logo */}
          <div className='navbar-brand text-white'>
            <div className='collapse navbar-collapse justify-content-center ' id='navbarExample'>
              <ul className='navbar-nav mb-0 gap-1 p-2'>
        

                <li className='nav-item mx-2 hovergreen mt-2 dropdown'>
                  <a
                    className='fw-bold logo dropdown-toggle'
                    aria-current='page'
                    href='./'
                    style={{
                      fontSize: '1.1rem',
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      color: 'black',
                    }}
                    id='locationsDropdown'
                    role='button'
                    data-bs-toggle='dropdown'
                    aria-expanded='false'
                  >
                    Locations
                    <i className='bi bi-chevron-down ms-2'></i>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '0%',
                        height: '2px',
                        backgroundColor: 'black',
                        transition: 'width 0.3s',
                      }}
                    ></div>
                  </a>
                  <ul className='dropdown-menu p-3' aria-labelledby='locationsDropdown'>
                    <li className='dropdown-submenu mb-2'>
                      <a className='dropdown-item ' href='#'>
                        New South Wales
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Sydney
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Newcastle
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Wollongong
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className='dropdown-submenu mb-2'>
                      <a className='dropdown-item' href='#'>
                        Victoria
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Melbourne
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Geelong
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Ballarat
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className='dropdown-submenu mb-2'>
                      <a className='dropdown-item' href='#'>
                        Queensland
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Brisbane
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Gold Coast
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Cairns
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className='dropdown-submenu mb-2'>
                      <a className='dropdown-item' href='#'>
                        South Australia
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Adelaide
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Mount Gambier
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Gawler
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className='dropdown-submenu mb-2'>
                      <a className='dropdown-item' href='#'>
                        Western Australia
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Perth
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Fremantle
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Bunbury
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className='dropdown-submenu mb-2'>
                      <a className='dropdown-item' href='#'>
                        Tasmania
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Hobart
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Launceston
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Devonport
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className='dropdown-submenu'>
                      <a className='dropdown-item' href='#'>
                        Northern Territory
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Darwin
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Alice Springs
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='#'>
                            Katherine
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li className='nav-item mx-2 hovergreen mt-2'>
                  <a
                    className='fw-bold logo '
                    aria-current='page'
                    // href='#'
                    style={{fontSize: '1.1rem', position: 'relative', color: 'black'}}
                    data-bs-toggle='offcanvas'
                    data-bs-target='#offcanvasRight'
                    aria-controls='offcanvasRight'
                  >
                    About Us
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '0%',
                        height: '2px',
                        backgroundColor: 'black',
                        transition: 'width 0.3s',
                      }}
                      className=''
                    ></div>
                  </a>
                </li>
                <li className='nav-item mx-2 hovergreen mt-2'>
                  <a
                    className='fw-bold logo'
                    aria-current='page'
                    href='#contact'
                    onClick={(e) => handleScroll(e, '#contact')}
                    style={{fontSize: '1.1rem', position: 'relative', color: 'black'}}
                  >
                    Contact Us
                    <div
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '0%',
                        height: '2px',
                        backgroundColor: 'black',
                        transition: 'width 0.3s',
                      }}
                      className=''
                    ></div>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* Button */}
          <div className='d-flex align-items-center me-3'>
            <button
              className='btn btn-transparent btn-sm me-2 text-#ffffff '
              onClick={handleLogin}
              style={{padding: '0.575rem 0.75rem'}}
            >
              Login
            </button>
            <button
              className='btn btn-sm ms-1'
              onClick={handleSignin}
              style={{padding: '0.575rem 0.75rem', color: 'white', backgroundColor: '#008471'}}
            >
              Sign Up
            </button>
          </div>
        </div>
      </nav>

      <style>{`
        .hovergreen:hover {
          color: lightgreen !important;
        }
        
        .navbar {
          width: 99%;
          backdrop-filter: blur(10px);
          background-color: rgba(255, 255, 255, 0.6);
          position: fixed;
          top: 0;
          z-index: 1000;
        }
        
        .navbar-brand {
          color: #ffffff;
        }
        
        .dropdown-menu {
          border-radius: 8px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          padding: 0;
        }
        
        .dropdown-item {
          padding: 0.75rem 1.5rem;
          margin-bottom: 5px;
          border-radius: 5px;
        }
        
        .dropdown-item:hover {
          background-color: #f8f9fa;
        }
        
        .nav-item .logo {
          display: flex;
          align-items: center;
        }
        
        .nav-item .logo i {
          margin-left: 8px;
        }
      `}</style>

      <style>{`
        .logo{
        color: #ffffff;
        }

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: 5px;
  padding: 10px 0;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.nav-item .logo:hover > div {
  width: 100%;
}

.dropdown-menu {
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  padding: 0;
}

.dropdown-item {
  padding: 0.75rem 1.5rem;
  margin-bottom: 5px;
  border-radius: 5px;
}

.dropdown-item:hover {
  color: #007bff;
  background-color: #e9ecef;
}

.dropdown-submenu:not(:last-child) {
  margin-bottom: 10px;
}

.dropdown-toggle::after {
  display: none;
}

.nav-item .logo {
  display: flex;
  align-items: center;
}

.nav-item .logo i {
  margin-left: 8px;
}

      `}</style>
    </>
  )
}

export default Navbar
