import React, {useState, ChangeEvent, FormEvent} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const FileUploadWrapper: React.FC = () => {
  const [file, setFile] = useState<File | null>(null)
//   const [customName, setCustomName] = useState<string>('')
  const [uploading, setUploading] = useState<boolean>(false)
  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFile = e.target.files[0]
      const maxSizeInBytes = 1 * 1024 * 1024 


      if (selectedFile.size > maxSizeInBytes) {
        toast.error('File size exceeds 1 MB', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        const fileInput = document.getElementById('formFile') as HTMLInputElement
        if (fileInput) {
          fileInput.value = ''
        }
        return
      }

      setFile(selectedFile)
    }
  }

  const fileUpload = async (e: FormEvent) => {
    e.preventDefault()
    setUploading(true)

    const formData = new FormData()
    if (file) {
      formData.append('file', file)
    }

    try {
      const res = await axios.post('https://api.inspection.payplatter.in/api/file-upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      //   console.log(res);

      if (res.status === 200) {
        toast.success('File uploaded successfully!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setFile(null)
      }
    } catch (err) {
      console.error('Error uploading file:', err)
      toast.error('File not uploaded!', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    } finally {
      setUploading(false)
      const fileInput = document.getElementById('formFile') as HTMLInputElement
      if (fileInput) {
        fileInput.value = ''
      }
    }
  }

  return (
    <div
      style={{
        maxWidth: '600px',
        margin: 'auto',
        padding: '20px',
        border: '1px solid #ccc',
        borderRadius: '5px',
      }}
    >
      <h2>Upload a File</h2>
      <form onSubmit={fileUpload}>
        <div className='mb-3'>
          <label htmlFor='formFile' className='form-label text-gray'>
            Attach your Files Here
          </label>
          <input
            className='form-control'
            type='file'
            id='formFile'
            onChange={onFileChange}
            required
            accept='.pdf, .jpg, .jpeg, .png, .gif'
          />
        </div>

        <div style={{position: 'relative'}}>
          <button
            type='submit'
            className='btn btn-primary'
            style={{
              padding: '10px 20px',
              backgroundColor: '#007bff',
              color: '#fff',
              cursor: 'pointer',
              position: 'relative',
            }}
            disabled={uploading}
          >
            {uploading && (
              <div className='spinner-border text-light' role='status'>
                <span className='visually-hidden'>Loading...</span>
              </div>
            )}
            Upload
          </button>
        </div>
      </form>
    </div>
  )
}

export default FileUploadWrapper
