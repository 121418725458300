/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../helpers'

const MixedWidget2: React.FC = () => {
  return (
    <div className={`card `}>
      <div className={`card-header border-0 py-3 `}></div>

      <div className='card-body p-0'>
        <div className='card-p mt-n10 position-relative'>
          <div className='row g-0'>
            <div className='col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7'>
              <KTSVG
                path='/media/icons/duotune/general/gen032.svg'
                className='svg-icon-3x svg-icon-warning d-block my-2'
              />
              <a href='#' className='text-warning fw-semibold fs-6'>
                Weekly Sales
              </a>
            </div>

            <div className='col bg-light-info px-6 py-8 rounded-2 me-7 mb-7'>
              <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-3x svg-icon-warning d-block my-2'
              />
              <a href='#' className='text-warning fw-semibold fs-6'>
                Harshal Patil
              </a>
            </div>
          </div>

          <div className='row g-0'>
            <div className='col bg-light-danger px-6 py-8 rounded-2 me-7'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs027.svg'
                className='svg-icon-3x svg-icon-danger d-block my-2'
              />
              <a href='#' className='text-danger fw-semibold fs-6 mt-2'>
                Item Orders
              </a>
            </div>

            <div className='col bg-light-success px-6 py-8 rounded-2'>
              <KTSVG
                path='/media/icons/duotune/communication/com010.svg'
                className='svg-icon-3x svg-icon-success d-block my-2'
              />
              <a href='#' className='text-success fw-semibold fs-6 mt-2'>
                Bug Reports
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {MixedWidget2}
