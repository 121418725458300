import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {KTSVG} from '../../../../_metronic/helpers'
import LoadingDataTables from '../../../../_metronic/partials/widgets/loader/LoadingDataTables'
import {useAuth} from '../../auth/core/Auth'
import {Inspector, Request} from '../core/_models'
import {Button, Col, Modal, Row} from 'react-bootstrap'

type Property = {
  property_id: number
  address: string
  city: string
  state: string
  zipcode: string
  seller: number
}

type Props = {
  className: string
}

const dateFormated = (date: any) => {
  if (date && date != null && date != undefined) {
    return new Date(date).toLocaleDateString()
  } else {
    return 'N/A'
  }
}
const RequestList: React.FC<Props> = ({className}) => {
  const auth = useAuth()

  const currentUser = auth.currentUser
  // console.log(currentUser)
  const currenteUserId = currentUser?.user_id
  const currenteUserRole = currentUser?.role
  const currenteUserPhoneNum = currentUser?.phone_no
  // console.log(currenteUserId)

  const [isLoading, setIsLoading] = useState(true)
  const [requests, setRequests] = useState([])
  const [requests2, setRequests2] = useState<Request[]>([])
  const [showCreatePropertyModal, setShowCreatePropertyModal] = useState(false)
  const [showInspectionRequestModal, setShowInspectionRequestModal] = useState(false)
  const [showAssignInspectorModal, setShowAssignInspectorModal] = useState(false)
  const [properties, setProperties] = useState<Property[]>([])
  const [selectedProperty, setSelectedProperty] = useState<Property | null>(null)
  const [selectedRequestId, setSelectedRequestId] = useState<number | null>(null)
  const [inspectors, setInspectors] = useState<Inspector[]>([])
  const [selectedInspector, setSelectedInspector] = useState<Inspector | null>(null)
  const [propertyData, setPropertyData] = useState({
    user_id: currenteUserId,
    address: '',
    city: '',
    state: '',
    zipcode: '',
  })

  const handleCreatePropertySubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log('Current userid is:', currenteUserId)
    console.log(typeof currenteUserId)
    e.preventDefault()

    // Create the payload with the user_id and other property data
    const propertyPayload = {...propertyData}

    try {
      // Send the request to the API
      await axios.post('https://api.inspection.payplatter.in/api/properties', propertyPayload)
      console.log('Property payload:', propertyPayload)

      // Close the modal and refresh the properties list
      setShowCreatePropertyModal(false)
      fetchProperties()
    } catch (error) {
      console.log('Property payload:', propertyPayload)
      console.error('Error creating property:', error)
    }
  }

  // role check
  // seller -> sellerid

  const fetchRequests = async () => {
    try {
      const response = await axios.get(
        `https://api.inspection.payplatter.in/api/inspection-requests/user/${currenteUserId}`
      )
      setRequests(response.data)
      setRequests2(response.data)

      setIsLoading(false)

      const role = response.data[0].requested_by.role
      console.log('Role:', role)
    } catch (error) {
      console.error('Error fetching requests:', error)
    }
  }

  const fetchProperties = async () => {
    try {
      const response = await axios.get(`https://api.inspection.payplatter.in/api/properties/user/${currenteUserId}`)
      setProperties(response.data)
    } catch (error) {
      console.error('Error fetching properties:', error)
    }
  }

  const handleDelete = async (id: number) => {
    try {
      await axios.delete(`https://api.inspection.payplatter.in/api/inspection-requests/${id}`)
      setRequests(requests.filter((request: any) => request.id !== id))
      setRequests2(requests.filter((request: any) => request.id !== id))
    } catch (error) {
      console.error('Error deleting request:', error)
    }
  }
  const fetchInspectors = async () => {
    try {
      const response = await axios.get('https://api.inspection.payplatter.in/api/inspectors')
      setInspectors(response.data)
    } catch (error) {
      console.error('Error fetching Inspectors:', error)
    }
  }
  fetchInspectors()

  useEffect(() => {
    fetchInspectors()
    fetchRequests()
    fetchProperties()
  }, [currenteUserId])

  const handleCreateInspectionRequest = async () => {
    try {
      if (selectedProperty) {
        const payload = {
          property_id: selectedProperty.property_id,
          requested_by: currenteUserId,
        }
        await axios.post('https://api.inspection.payplatter.in/api/inspection-requests', payload)
        setShowInspectionRequestModal(false)
        fetchRequests()
      }
    } catch (error) {
      console.error('Error creating inspection request:', error)
    }
  }

  const handleAssignInspector = async (requestId: number) => {
    try {
      if (selectedInspector) {
        const payload = {
          assigned_inspector_id: selectedInspector.inspector_id,
          status: false,
        }
        await axios.put(`https://api.inspection.payplatter.in/api/inspection-requests/${requestId}`, payload)
        setRequests2((prevRequests) =>
          prevRequests.map((request) =>
            request.request_id === requestId
              ? {...request, assigned_inspector: selectedInspector, status: true}
              : request
          )
        )
        setShowAssignInspectorModal(false)
      }
    } catch (error) {
      console.error('Error assigning inspector:', error)
    }
  }
  // console.log(requests)

  // Some Declarations for Ease
  const notDefined = (
    <span className={`badge badge-light-danger fs-7 fw-semibold`}>Not Assigned</span>
  )

  const [showDownloadModal, setShowDownloadModal] = useState(false)

  const handleShowDownloadModal = () => setShowDownloadModal(true)
  const handleCloseDownloadModal = () => setShowDownloadModal(false)

  const handleDownloadReport = () => {
    // Logic to handle report download
    // For example, triggering a download or redirecting to a download link
    console.log('Downloading report...')
    // Example: window.location.href = '/path/to/report.pdf';
  }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Manage Inspection Requests</span>
        </h3>
        <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-light-primary me-2'
            onClick={() => setShowCreatePropertyModal(true)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Create Property
          </button>
          <button
            type='button'
            className='btn btn-sm btn-light-primary'
            onClick={() => setShowInspectionRequestModal(true)}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            Request Inspection
          </button>
        </div>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          {isLoading ? (
            <LoadingDataTables />
          ) : (
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Request Date</th>
                  <th className='min-w-140px'>Status</th>
                  <th className='min-w-120px'>Property Address</th>
                  <th className='min-w-120px'>Property City</th>
                  <th className='min-w-120px'>Property State</th>
                  <th className='min-w-120px'>Property Zipcode</th>
                  <th className='min-w-120px'>Assigned Inspector</th>
                  <th className='min-w-120px'>Inspection Date</th>
                  <th className='min-w-120px text-end'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {requests2.map((request: any, index: number) => (
                  <tr key={index}>
                    <td>{new Date(request.request_date).toLocaleDateString()}</td>
                    <td>
                      <span
                        className={`badge badge-light-${
                          request.status ? 'success' : 'danger'
                        } fs-7 fw-semibold`}
                      >
                        {request.status ? 'Completed' : 'Pending'}
                      </span>
                    </td>

                    <td>{request.property.address}</td>
                    <td>{request.property.city}</td>
                    <td>{request.property.state}</td>
                    <td>{request.property.zipcode}</td>
                    <td>
                      {request.assigned_inspector?.firstname}{' '}
                      {request.assigned_inspector?.lastname || notDefined}
                    </td>
                    <td>{dateFormated(request.assigned_date)}</td>
                    <td>
                      <button
                        type='button'
                        className='btn btn-sm btn-dark me-2 mb-2'
                        onClick={() => {
                          setShowAssignInspectorModal(true)
                          setSelectedRequestId(request.request_id)
                        }}
                      >
                        Select Inspector
                      </button>
                      <button
                        type='button'
                        className='btn btn-sm btn-dark'
                        onClick={() => {
                          handleShowDownloadModal()
                        }}
                      >
                        Download Report
                      </button>

                      <button
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        onClick={() => handleDelete(request.request_id)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <Modal show={showDownloadModal} onHide={handleCloseDownloadModal} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Download Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Report Details</h5>
          <p>
            <strong>Inspection Date:</strong> August 28, 2024
          </p>
          <p>
            <strong>Property Details:</strong> 123 Example Street, Melbourne, VIC, 3000, Australia
          </p>
          <p>
            <strong>Inspector Details:</strong> Hritik Inspection Service
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseDownloadModal}>
            Close
          </Button>
          <Button variant='primary' onClick={handleDownloadReport}>
            Download Report
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Create Property Modal */}
      {showCreatePropertyModal && (
        <div
          className='modal show d-block'
          style={{
            backgroundColor: showCreatePropertyModal ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
          }}
        >
          <div className='modal-dialog modal-dialog-centered mw-600px'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Create Property</h5>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  onClick={() => setShowCreatePropertyModal(false)}
                >
                  <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                </button>
              </div>
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                <form className='form' onSubmit={handleCreatePropertySubmit}>
                  {/* {setPropertyData({ ...propertyData, seller: currenteUserId })} */}
                  <div className='form-group mb-10'>
                    <label className='form-label'>Address</label>
                    <input
                      type='text'
                      className='form-control'
                      value={propertyData.address}
                      onChange={(e) => setPropertyData({...propertyData, address: e.target.value})}
                      required
                    />
                  </div>
                  <div className='form-group mb-10'>
                    <label className='form-label'>City</label>
                    <input
                      type='text'
                      className='form-control'
                      value={propertyData.city}
                      onChange={(e) => setPropertyData({...propertyData, city: e.target.value})}
                      required
                    />
                  </div>
                  <div className='form-group mb-10'>
                    <label className='form-label'>State</label>
                    <input
                      type='text'
                      className='form-control'
                      value={propertyData.state}
                      onChange={(e) => setPropertyData({...propertyData, state: e.target.value})}
                      required
                    />
                  </div>
                  <div className='form-group mb-10'>
                    <label className='form-label'>Zipcode</label>
                    <input
                      type='text'
                      className='form-control'
                      value={propertyData.zipcode}
                      onChange={(e) => setPropertyData({...propertyData, zipcode: e.target.value})}
                      required
                    />
                  </div>
                  <div className='text-end'>
                    <button type='submit' className='btn btn-primary'>
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Request Inspection Modal */}

      {showInspectionRequestModal && (
        <div
          className='modal show d-block'
          style={{
            backgroundColor: showInspectionRequestModal ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
          }}
        >
          <div className='modal-dialog modal-dialog-centered mw-600px'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Select Property for Inspection</h5>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  onClick={() => setShowInspectionRequestModal(false)}
                >
                  <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                </button>
              </div>
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Address</th>
                      <th>City</th>
                      <th>State</th>
                      <th>Zipcode</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {properties.map((property: Property, index: number) => (
                      <tr key={index}>
                        <td>{property.address}</td>
                        <td>{property.city}</td>
                        <td>{property.state}</td>
                        <td>{property.zipcode}</td>
                        <td>
                          <button
                            className='btn btn-sm btn-primary'
                            onClick={() => setSelectedProperty(property)}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {selectedProperty && (
                  <div className='mt-5'>
                    <h6>Request Inspection for this Property?</h6>
                    <div className='d-flex justify-content-between'>
                      <button className='btn btn-success' onClick={handleCreateInspectionRequest}>
                        Submit
                      </button>
                      <button
                        className='btn btn-secondary'
                        onClick={() => setSelectedProperty(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Assign Inspector Modal */}

      {showAssignInspectorModal && (
        <div
          className='modal show d-block'
          style={{
            backgroundColor: showAssignInspectorModal ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
          }}
        >
          <div className='modal-dialog modal-dialog-centered mw-600px'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>Select "Inspector" to assign</h5>
                <button
                  type='button'
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  onClick={() => setShowAssignInspectorModal(false)}
                >
                  <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                </button>
              </div>
              <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Phone No.</th>
                    </tr>
                  </thead>
                  <tbody>
                    {inspectors.map((inspector: Inspector, index: number) => (
                      <tr key={index}>
                        <td>
                          {inspector.firstname} {inspector.lastname}
                        </td>
                        <td>{inspector.phone_no}</td>
                        <td>{inspector.email}</td>
                        <td>{inspector.is_disabled}</td>
                        <td>
                          <button
                            className='btn btn-sm btn-primary'
                            onClick={() => setSelectedInspector(inspector)}
                          >
                            Select
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {selectedInspector && (
                  <div className='mt-5'>
                    <h6>Assign this Inspector for the Request?</h6>
                    <div className='d-flex justify-content-between'>
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => {
                          if (selectedRequestId !== null && selectedInspector?.inspector_id) {
                            handleAssignInspector(selectedRequestId)
                            console.log('request id:', selectedRequestId)
                            console.log('inspectoro id:', selectedInspector.inspector_id)
                          }
                        }}
                      >
                        Submit
                      </button>

                      <button
                        className='btn btn-secondary'
                        onClick={() => setSelectedInspector(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export {RequestList}
