import React from 'react'
import { KTSVG } from '../../../helpers'
import { Link } from 'react-router-dom'
import Scheduler from './Scheduler'

type Props = {
  className: string
  setFilter: (filter: string) => void
}

const ListsWidget8: React.FC<Props> = ({ className, setFilter }) => {
  return (
    <div className='d-flex' style={{ backgroundColor: 'white' }}>
      {/* Container for card */}
      <div className='card-container' style={{ marginRight: '20px' }}>
        <div className='card card-xl-stretch mb-5 mb-xl-8 w-450px'>
          <div className='card-header align-items-center border-0 mt-2'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='fw-bold text-dark'>Inspections Details</span>
            </h3>
          </div>

          <div className='card-body'>
            <div
              className='d-flex align-items-sm-center mb-7'
              onClick={() => setFilter('active')}
            >
              <Link to='/reports/inspections' className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
                <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                  <h1 className='text-gray-800 fw-bold text-hover-primary fs-6'>
                    Active Inspections
                  </h1>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='me-6'>
                    {/* <span className='fw-bold badge badge-light-dark fs-7 fw-bold'>View</span> */}
                  </div>
                  <div className='btn btn-icon btn-light btn-sm border-0'>
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2 svg-icon-primary' />
                  </div>
                </div>
              </Link>
            </div>

            <div
              className='d-flex align-items-sm-center mb-7'
              onClick={() => setFilter('completed')}
            >
              <Link to='/reports/inspections' className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
                <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                  <h1 className='text-gray-800 fw-bold text-hover-primary fs-6'>
                    Completed Inspections
                  </h1>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='me-6'>
                    {/* <span className='fw-bold badge badge-light-success fs-7 fw-bold'>View</span> */}
                  </div>
                  <div className='btn btn-icon btn-light btn-sm border-0'>
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2 svg-icon-primary' />
                  </div>
                </div>
              </Link>
            </div>

            <div
              className='d-flex align-items-sm-center mb-7'
              onClick={() => setFilter('all')}
            >
              <Link to='/reports/inspections' className='d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2'>
                <div className='flex-grow-1 my-lg-0 my-2 me-2'>
                  <h1 className='text-gray-800 fw-bold text-hover-primary fs-6'>
                    All Inspections
                  </h1>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='me-6'>
                    {/* <span className='fw-bold badge badge-light-warning fs-7 fw-bold'>View</span> */}
                  </div>
                  <div className='btn btn-icon btn-light btn-sm border-0'>
                    <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-2 svg-icon-primary' />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Container for scheduler */}
      {/* <div className='scheduler-container'  style={{ backgroundColor: '' }}>
        <Scheduler />
      </div> */}

<div
  className='scheduler-container'
  style={{
    backgroundColor: '#f8f9fa', // Light gray background color for better contrast
    borderRadius: '8px', // Rounded borders
    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth effect
    padding: '20px' // Adding some padding for spacing inside the container
  }}
>
<div  style={{
    borderRadius: '8px', // Rounded borders
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth effect
    padding: '10px' // Adding some padding for spacing inside the container
  }}>
<Scheduler />
  </div>
</div>

    </div>
  )
}

export { ListsWidget8 }