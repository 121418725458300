

import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';

type Inspection = {
  name: string;
  buildingName: string;
  owner: string;
  address: string;
  propertyOwner: string;
  status: string;
};

type Props = {
  className: string;
};

const TablesWidget11: React.FC<Props> = ({ className }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedInspection, setSelectedInspection] = useState<Inspection | null>(null);

  const handleOpenModal = (inspection: Inspection) => {
    setSelectedInspection(inspection);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedInspection(null);
  };

  const inspections: Inspection[] = [
    {
      name: 'Inspection 1',
      buildingName: 'Marina Lighthouse',
      owner: 'Ajay Patil',
      address: '888 Marina Beach Lane, Chennai, TN 600004',
      propertyOwner: 'Sushant Jadhav',
      status: 'Final',
    },
    {
      name: 'Inspection 2',
      buildingName: 'Taj Mahal',
      owner: 'Nitin Baviskar',
      address: '123 Taj Road, Agra, UP 282001',
      propertyOwner: 'Prashant Kulkarni',
      status: 'Draft',
    },
    {
      name: 'Inspection 3',
      buildingName: 'Anand Bhavan',
      owner: 'Minal Pawar',
      address: '456 Anand Nagar, Pune, MH 411001',
      propertyOwner: 'Nirmal Jadhav',
      status: 'Final',
    },
    {
      name: 'Inspection 4',
      buildingName: 'Victoria Memorial',
      owner: 'Abhi Patil',
      address: '202 Victoria Street, Kolkata, WB 700071',
      propertyOwner: 'Nayan Dhongadi',
      status: 'Draft',
    },
    {
      name: 'Inspection 5',
      buildingName: 'Mysore Palace',
      owner: 'Sarita Deshmukh',
      address: '404 Palace Road, Mysore, KA 570001',
      propertyOwner: 'Siddhesh Jadhav',
      status: 'Final',
    },
    {
      name: 'Inspection 6',
      buildingName: 'Gateway of India',
      owner: 'Ravi Sharma',
      address: '123 Marine Drive, Mumbai, MH 400020',
      propertyOwner: 'Anita Desai',
      status: 'Final',
    },
    {
      name: 'Inspection 7',
      buildingName: 'Hawa Mahal',
      owner: 'Sunita Rani',
      address: '789 Hawa Mahal Road, Jaipur, RJ 302001',
      propertyOwner: 'Rohit Agarwal',
      status: 'Draft',
    },
    {
      name: 'Inspection 8',
      buildingName: 'Lotus Temple',
      owner: 'Deepak Soni',
      address: '456 Lotus Street, Delhi, DL 110001',
      propertyOwner: 'Geeta Kumari',
      status: 'Draft',
    },
    {
      name: 'Inspection 9',
      buildingName: 'Rashtrapati Bhavan',
      owner: 'Priya Gupta',
      address: '789 President’s Estate, Delhi, DL 110004',
      propertyOwner: 'Rajesh Verma',
      status: 'Final',
    },
    {
      name: 'Inspection 10',
      buildingName: 'Charminar',
      owner: 'Suresh Yadav',
      address: '321 Charminar Road, Hyderabad, TS 500002',
      propertyOwner: 'Kavita Singh',
      status: 'Final',
    },
  ];
  

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Inspections List</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                <th className='ps-4 min-w-200px rounded-start'>Requester</th>
                <th className='min-w-200px'>Type</th>
                <th className='min-w-200px'>Property Details</th>
                <th className='min-w-200px'>Request Date</th>
                <th className='min-w-200px'>Assigned Date</th>
                <th className='min-w-200px'>Inspection Date</th>
                <th className='min-w-150px'>Status</th>
                <th className='min-w-200px text-center rounded-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {inspections.map((inspection, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex justify-content-start flex-column'>
                        <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                          {inspection.name}
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {inspection.buildingName}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {inspection.owner}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {inspection.address}
                    </a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'>
                      {inspection.propertyOwner}
                    </a>
                  </td>
                  <td>
                    <span className={`badge badge-light-${inspection.status === 'Final' ? 'success' : 'danger'} fs-7 fw-semibold`}>
                      {inspection.status}
                    </span>
                  </td>
                  <td className='text-center'>
                    <button
                      className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                      onClick={() => handleOpenModal(inspection)}
                    >
                      View
                    </button>
                    <button className='btn btn-bg-light btn-color-muted btn-active-color-primary btn-sm px-4'>
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
        </div>
      </div>
      {/* end::Body */}

      {/* Modal */}
      <Modal 
      show={showModal} 
      onHide={handleCloseModal}
      centered 
      size="lg"
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton>
        <Modal.Title>Inspection Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4" style={{ maxHeight: '75vh', overflowY: 'auto' }}>
        {selectedInspection && (
          <div className="bg-light p-4 rounded shadow-sm">
            <h5 className="mb-3 text-primary">{selectedInspection.name}</h5>
            <div className="mb-3">
              <strong>Building Name:</strong>
              <span className="ms-2">{selectedInspection.buildingName}</span>
            </div>
            <div className="mb-3">
              <strong>Owner:</strong>
              <span className="ms-2">{selectedInspection.owner}</span>
            </div>
            <div className="mb-3">
              <strong>Address:</strong>
              <span className="ms-2">{selectedInspection.address}</span>
            </div>
            <div className="mb-3">
              <strong>Property Owner:</strong>
              <span className="ms-2">{selectedInspection.propertyOwner}</span>
            </div>
            <div className="mb-3">
              <strong>Status:</strong>
              <span className={`badge ${selectedInspection.status === 'Final' ? 'bg-success' : 'bg-danger'} ms-2`}>
                {selectedInspection.status}
              </span>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
    </div>
  );
};

export { TablesWidget11 };
