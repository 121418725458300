import axios from 'axios';

// Fetch sidebar data from backend based on user ID
export const getSidebarData = async (userId) => {
  try {
    const response = await axios.get(`https://api.inspection.payplatter.in/api/sidebar/${userId}`);
    
    if (response.data.success) {
      return {
        roleBasedSidebar: response.data.data.roleBasedSidebar,
        userBasedSidebar: response.data.data.userBasedSidebar || null,  // Handle user-based sidebar as null
      };
    } else {
      console.error('Failed to fetch sidebar data:', response.data.message);
      return {
        roleBasedSidebar: [],
        userBasedSidebar: null,
      };
    }
  } catch (error) {
    console.error('Failed to fetch sidebar data', error);
    return {
      roleBasedSidebar: [],
      userBasedSidebar: null,
    };
  }
};
