import React, {useState, useEffect, useRef} from 'react'
import {Button, Col, Form, Modal, Row, Table} from 'react-bootstrap'
import {
  CreateDefect,
  ExtendedDefect,
  CreateInspectionReport,
  InspectionRequest,
  InspectionRequestOfInspector,
} from '../../users/core/_models'
import {
  createInspectionReport,
  fetchInspectionRequests,
  getInspectionRequestsOfInspector,
} from '../../users/core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {startOfMonth, endOfMonth, subMonths, startOfYear, endOfYear} from 'date-fns'
import {useAuth} from '../../auth/core/Auth'
import axios from 'axios'
import {Request} from '../core/_modules'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../_metronic/helpers'
// import jsPDF from 'jspdf';
import ReactToPrint from 'react-to-print'
import Select, { MultiValue } from 'react-select'
import AddExistingReportModal from './AddExistingReportModal'
import { InspectorProfilePhoto } from '../../users/core/_models'
import blankprofilepic from '../../../../_metronic/assets/blankprofilepic.jpg'

type Props = {
  className: string
}

interface SubType {
  finding_id: number
  finding_name: string
  information: string
}

interface FindingType {
  finding_type_id: number
  finding_type_name: string
  findingCategory: {
    finding_cat_id: number
  }
}

interface Finding {
  finding_id: number
  finding_name: string
  information: string
  findingType: {
    finding_type_id: number
    finding_type_name: string
  }
}


const InspectionsReports: React.FC<Props> = ({className}) => {
  const [showModal, setShowModal] = useState(false)
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showAddReportModal, setShowAddReportModal] = useState(false)
  const [selectedInspection, setSelectedInspection] = useState<InspectionRequest | null>(null)
  const [filter, setFilter] = useState<string>('all')
  const [dateFilter, setDateFilter] = useState<string>('all')
  const [customStartDate, setCustomStartDate] = useState<string>('')
  const [fetchedByIdData, setFetchedByIdData] = useState([])
  const [customEndDate, setCustomEndDate] = useState<string>('')
  const [inspections, setInspections] = useState<InspectionRequest[]>([])
  const [categories, setCategories] = useState<any[]>([])
  const [selectedCategory, setSelectedCategory] = useState<any>(null)
  const [selectedType, setSelectedType] = useState<any>(null)
  const [additionalOptions, setAdditionalOptions] = useState<any[]>([])
  const [selectedFindingInfo, setSelectedFindingInfo] = useState<string>('')
  const [selectedFindings, setSelectedFindings] = useState<any[]>([])

  const [findingsData, setFindingsData] = useState<Finding[]>([])

  const [findingTypesReport, setFindingTypesReport] = useState<any[]>([])
  const [filteredTypes, setFilteredTypes] = useState<any[]>([])

  const [showExistingReportModal, setShowExistingReportModal] = useState<boolean>(false);
  // Function to open the modal
  const handleShowExistingReportModal = () => setShowExistingReportModal(true);
  
  // Function to close the modal
  const handleCloseExistingReportModal = () => setShowExistingReportModal(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://api.inspection.payplatter.in/api/finding-category')
        const data = await response.json()
        setCategories(data)
      } catch (error) {
        console.error('Error fetching categories:', error)
      }
    }

    const fetchFindingTypes = async () => {
      try {
        const response = await fetch(
          `https://api.inspection.payplatter.in/api/finding-type/user/${currenteUserId}`
        )
        const data = await response.json()
        setFindingTypesReport(data)
      } catch (error) {
        console.error('Error fetching finding types:', error)
      }
    }

    fetchCategories()
    fetchFindingTypes()
  }, [])

  const [safetyHazards, setSafetyHazards] = useState<ExtendedDefect[]>([
    {type: 'Safety Hazard', building: '', location: '', images: [],
      selectedCategory: null,
      selectedType: null,
      additionalOptions: [],
      selectedFindings: [],
      selectedFindingInfo: '',
      filteredTypesList: [],
      findingsData: []
    },
  ])

  const [majorDefects, setMajorDefects] = useState<CreateDefect[]>([
    {type: 'Major Defects', building: '', location: '',
      selectedFindings: [],
      selectedFindingInfo: '', images: []},
  ])
  const [minorDefects, setMinorDefects] = useState<CreateDefect[]>([
    {type: 'Minor Defects', building: '', location: '',
      selectedFindings: [],
      selectedFindingInfo: '', images: []},
  ])
  const [furtherInspections, setFurtherInspections] = useState<CreateDefect[]>([
    {
      type: 'Further Inspections',
      building: '',
      location: '',
      selectedFindings: [],
      selectedFindingInfo: '',
      images: [],
    },
  ])
  const [notedItems, setNotedItems] = useState<CreateDefect[]>([
    {type: 'Noted Items', building: '', location: '',
      selectedFindings: [],
      selectedFindingInfo: '', images: []},
  ])

  
  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null)
  const [facingMode, setFacingMode] = useState<'user' | 'environment'>('user'); // State to track camera type
  const videoRef = useRef<HTMLVideoElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const handleAddSafetyHazard = () => {
    setSafetyHazards((prev) => [
      ...prev,
      {type: 'Safety Hazard', building: '', location: '', images: [],
        selectedCategory: null,
        selectedType: null,
        additionalOptions: [],
        selectedFindings: [],
        selectedFindingInfo: '',
        filteredTypesList: [],
        findingsData: []
      }
    ])
    setSelectedFindingInfo('')
    setSelectedFindings([])

  }

  const handleAddMajorDefect = () => {
    setMajorDefects((prev) => [
      ...prev,
      {type: 'Major Defect', building: '', location: '',
      selectedFindings: [],
      selectedFindingInfo: '', images: []},
    ])
  }

  const handleAddMinorDefect = () => {
    setMinorDefects((prev) => [
      ...prev,
      {type: 'Minor Defect', building: '', location: '',
        selectedFindings: [],
        selectedFindingInfo: '', images: []},
    ])
  }

  const handleAddFurtherInspection = () => {
    setFurtherInspections((prev) => [
      ...prev,
      {
        type: 'Further Inspection',
        building: '',
        location: '',
        selectedFindings: [],
        selectedFindingInfo: '',
        images: [],
      },
    ])
  }

  const handleAddNotedItem = () => {
    setNotedItems((prev) => [
      ...prev,
      {type: 'Noted item', building: '', location: '',
        selectedFindings: [],
        selectedFindingInfo: '', images: []},
    ])
  }

  const handleRemoveSafetyHazard = (index: number) => {
    setSafetyHazards(safetyHazards.filter((_, i) => i !== index))
  }
  const handleRemoveMajorDefect = (index: number) => {
    setMajorDefects(majorDefects.filter((_, i) => i !== index))
  }
  const handleRemoveMinorDefect = (index: number) => {
    setMinorDefects(minorDefects.filter((_, i) => i !== index))
  }
  const handleRemoveFurtherInspection = (index: number) => {
    setFurtherInspections(furtherInspections.filter((_, i) => i !== index))
  }
  const handleRemoveNotedItem = (index: number) => {
    setNotedItems(notedItems.filter((_, i) => i !== index))
  }

  const handleSafetyHazardChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target
    setSafetyHazards((prev) => {
      const updated = [...prev]
      updated[index] = {
        ...updated[index],
        [name]: value,
      }
      return updated
    })
  }

  const handleMajorDefectChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target

    const updatedMajorDefects = [...majorDefects]
    updatedMajorDefects[index] = {
      ...updatedMajorDefects[index],
      [name]: value,
    }

    setMajorDefects(updatedMajorDefects)
  }

  const handleMinorDefectChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target

    const updatedMinorDefects = [...minorDefects]
    updatedMinorDefects[index] = {
      ...updatedMinorDefects[index],
      [name]: value,
    }

    setMinorDefects(updatedMinorDefects)
  }

  const handleFurtherInspectionChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target

    const updatedFurtherInspections = [...furtherInspections]
    updatedFurtherInspections[index] = {
      ...updatedFurtherInspections[index],
      [name]: value,
    }

    setFurtherInspections(updatedFurtherInspections)
  }

  const handleNotedItemChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {name, value} = event.target
      const updatedNotedItems = [...notedItems]
      updatedNotedItems[index] = {...updatedNotedItems[index], [name]: value}
      setNotedItems(updatedNotedItems)
    }


    const handleCategoryChange = async (index: number, selectedOption: any) => {
      const categoryId = selectedOption ? selectedOption.value : null
      setSelectedCategory(categoryId)
      setSafetyHazards((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          selectedCategory: categoryId,
          selectedType: null,
          additionalOptions: [],
          selectedFindings: [],
          selectedFindingInfo: '',
          filteredTypesList: [],
          findingsData: []
        }
        return updated
      })
  
      if (categoryId === 1) {
        // If the selected category is "Default" (finding_cat_id === 1), fetch all types
        try {
          const response = await fetch('https://api.inspection.payplatter.in/api/finding-type')
          const data = await response.json()
  
          // Filter the types to only show the ones with finding_cat_id === 1
          const filtered = data.filter((type: any) => type.findingCategory.finding_cat_id === 1)
          setFilteredTypes(filtered)
          setSafetyHazards((prev) => {
            const updated = [...prev]
            updated[index] = { ...updated[index], filteredTypesList: filtered }
            return updated
          })
        } catch (error) {
          console.error('Error fetching finding types for Default category:', error)
        }
      } else {
        // Continue with the current logic for fetching types based on inspector
        const filtered = findingTypesReport.filter(
          (type) => type.findingCategory.finding_cat_id === categoryId
        )
        setFilteredTypes(filtered)
        setSafetyHazards((prev) => {
          const updated = [...prev]
          updated[index] = { ...updated[index], filteredTypesList: filtered }
          return updated
        })
      }
    }
  

    
  const handleTypeChange = async (index: number, selectedOption: any) => {
    const selectedTypeId = selectedOption ? selectedOption.value : null
    setSelectedType(selectedTypeId)
    setSafetyHazards((prev) => {
      const updated = [...prev]
      updated[index] = { ...updated[index], selectedType: selectedTypeId, selectedFindings: [], selectedFindingInfo: '' }
      return updated
    })


    // Clear the selected options
    setSelectedFindings([])

    // Fetch new findings based on the selected type ID
    try {
      const response = await fetch(`https://api.inspection.payplatter.in/api/finding/type/${selectedTypeId}`)
      const data = await response.json()

      // Set findings data and options for the findings dropdown
      setFindingsData(data) // Assuming findingsData is the state to hold the data for the selected type
      setAdditionalOptions(
        data.map((finding: Finding) => ({
          value: finding.finding_id,
          label: finding.finding_name,
        }))
      )
      const options = data.map((finding: any) => ({
        value: finding.finding_id,
        label: finding.finding_name
      }))
      setSafetyHazards((prev) => {
        const updated = [...prev]
        updated[index] = { ...updated[index], additionalOptions: options, findingsData: data }
        return updated
      })

      // Clear the selected findings information
      setSelectedFindingInfo('')
      
    } catch (error) {
      console.error('Error fetching findings:', error)
      setAdditionalOptions([])
    }
  }

 
  // Function to handle changes in the Select component
const handleFindingsChange = (index: number, selectedOptions: MultiValue<{ label: string; value: string }>) => {
  // Convert options to an array of strings
  const selectedFindings = selectedOptions.map((option) => option.label);
  const selectedFindingInfo = selectedOptions
    .map((option) => {
      const finding = safetyHazards[index].findingsData.find((f: Finding) => f.finding_name === option.label);
      return finding ? finding.information : '';
    })
    .join('\n'); // Concatenate for `selectedFindingInfo`

  setSafetyHazards((prev) => {
    const updated = [...prev];
    updated[index] = {
      ...updated[index],
      selectedFindings, // Array of strings
      selectedFindingInfo, // Single concatenated string
    };
    return updated;
  });
};
  
  
  


    const handleSafetyHazardImageChange = (
      index: number,
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const files = event.target.files;
      if (files) {
        const newFiles = Array.from(files);
        const imageUrls = newFiles.map((file) => URL.createObjectURL(file));
    
        setSafetyHazards((prev) => {
          const updated = [...prev];
          updated[index] = {
            ...updated[index],
            images: imageUrls,
            files: newFiles, // Store actual file objects
          };
          return updated;
        });
      }
    };
    

    // Function to remove an image from the file input
    const handleRemoveImage = (index: number, imgIndex: number) => {
      const fileInput = document.querySelector<HTMLInputElement>(`#safetyHazardImages-${index}`); // Get file input by ID
      
      if (fileInput && fileInput.files) {
        // Convert FileList to an array of Files
        const files = Array.from(fileInput.files) as File[];
        
        // Filter out the unwanted image based on imgIndex
        const newFiles = files.filter((_, i) => i !== imgIndex);
    
        // Create a new DataTransfer object to store the remaining files
        const dataTransfer = new DataTransfer();
        newFiles.forEach((file) => dataTransfer.items.add(file)); // Add remaining files to DataTransfer
    
        // Update the file input with the new file list
        fileInput.files = dataTransfer.files;
    
        // Update your React state accordingly
        setSafetyHazards((prev) => {
          const updated = [...prev];
          updated[index].images = updated[index].images!.filter((_, i) => i !== imgIndex);
          updated[index].files = newFiles; // Update the files state
          return updated;
        });
      }
    };
    

    
    
    

  const handleMajorDefectImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setMajorDefects((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const handleMinorDefectImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setMinorDefects((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const handleFurtherInspectionImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setFurtherInspections((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const handleNotedItemImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setNotedItems((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const startCamera = async () => {
    try {
      const constraints = {
        video: {
          facingMode: { exact: facingMode }, // Use "environment" for rear camera
        },
      };

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
      setCameraStream(stream)
      if (videoRef.current) {
        videoRef.current.srcObject = stream
        videoRef.current.onloadedmetadata = () => {
          videoRef.current?.play()
        }
      }
    } catch (error) {
      console.error('Error accessing camera:', error)
      toast.error('Failed to access camera')
    }
  }

  const takePhoto = (index: number) => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d')
      if (context) {
        // Set the canvas dimensions to the video dimensions
        canvasRef.current.width = videoRef.current.videoWidth
        canvasRef.current.height = videoRef.current.videoHeight
        // Draw the current frame from the video onto the canvas
        context.drawImage(
          videoRef.current,
          0,
          0,
          videoRef.current.videoWidth,
          videoRef.current.videoHeight
        )
        const imageUrl = canvasRef.current.toDataURL('image/png')
        console.log('Captured Image URL:', imageUrl);
        setSafetyHazards((prev) => {
          const updated = [...prev]
          updated[index] = {
            ...updated[index],
            images: [...updated[index].images, imageUrl],
          }
          return updated
        })
      }
    }
  }

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop())
      setCameraStream(null)
    }
  }

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user')); // Toggle between 'user' and 'environment'
    stopCamera(); // Stop the current camera before switching
    startCamera(); // Restart with the new camera mode
  };

  useEffect(() => {
    return () => {
      stopCamera() // Stop camera when component unmounts
    }
  }, [])

  const fetchInspectionRequests = async () => {
    try {
      const response = await axios.get('https://api.inspection.payplatter.in/api/inspection-requests')
      setInspections(response.data)
    } catch (error) {
      console.error('Error fetching inspection requests:', error)
      toast.error('Failed to fetch inspection requests')
    }
  }

  // Fetch inspection requests on component mount
  useEffect(() => {
    fetchInspectionRequests()
  }, [])

  const auth = useAuth()

  const currentUser = auth.currentUser
  // console.log(currentUser)
  const currenteUserId = currentUser?.user_id
  const currenteUserRole = currentUser?.role
  const currenteUserPhoneNum = currentUser?.phone_no
  const currenteUserName = currentUser?.firstname + ' ' + currentUser?.lastname

  useEffect(() => {
    const loadData = async () => {
      const data = await getInspectionRequestsOfInspector(currenteUserId)
      setInspections(data)
    }
    loadData()
  }, [])

  const handleOpenModal = (inspection: InspectionRequest) => {
    setSelectedInspection(inspection)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setShowAddReportModal(false)
    setSelectedInspection(null)
  }

  const handleOpenAcceptModal = (inspection: InspectionRequest) => {
    setSelectedInspection(inspection)
    setShowAcceptModal(true)
    setShowModal(false)
  }

  const handleCloseAcceptModal = () => {
    setShowAcceptModal(false)
    setSelectedInspection(null)
    setShowModal(false)
  }

  const handleOpenRejectModal = (inspection: InspectionRequest) => {
    setSelectedInspection(inspection)
    setShowRejectModal(true)
    setShowModal(false)
  }

  const handleCloseRejectModal = () => {
    setShowRejectModal(false)
    setSelectedInspection(null)
    setShowModal(false)
  }

  const handleOpenAddReportModal = () => setShowAddReportModal(true)
  const handleCloseAddReportModal = () => setShowAddReportModal(false)

  // console.log(inspections)

  const getFilteredInspections = () => {
    let filtered = inspections.filter((inspection) => {
      if (filter === 'all') return true
      if (filter === 'active') return inspection.status
      if (filter === 'completed') return !inspection.status
      return true
    })

    const today = new Date()

    if (dateFilter === 'thisMonth') {
      const start = startOfMonth(today)
      const end = endOfMonth(today)
      filtered = filtered.filter((inspection) => {
        const requestDate = new Date(inspection.request_date)
        return requestDate >= start && requestDate <= end
      })
    } else if (dateFilter === 'lastThreeMonths') {
      const start = subMonths(startOfMonth(today), 3)
      const end = endOfMonth(today)
      filtered = filtered.filter((inspection) => {
        const requestDate = new Date(inspection.request_date)
        return requestDate >= start && requestDate <= end
      })
    } else if (dateFilter === 'year') {
      const start = startOfYear(today)
      const end = endOfYear(today)
      filtered = filtered.filter((inspection) => {
        const requestDate = new Date(inspection.request_date)
        return requestDate >= start && requestDate <= end
      })
    } else if (dateFilter === 'custom' && customStartDate && customEndDate) {
      const start = new Date(customStartDate)
      const end = new Date(customEndDate)
      filtered = filtered.filter((inspection) => {
        const requestDate = new Date(inspection.request_date)
        return requestDate >= start && requestDate <= end
      })
    }

    return filtered
  }

  const handleDateFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDateFilter(e.target.value)
    if (e.target.value !== 'custom') {
      setCustomStartDate('')
      setCustomEndDate('')
    }
  }

  const loadData = async () => {
    const data = await getInspectionRequestsOfInspector(currenteUserId)
    setInspections(data)
  }

  useEffect(() => {
    loadData()
  }, [])

  const fetchInspectionRequestById = async (id: number) => {
    try {
      const response = await axios.get(`https://api.inspection.payplatter.in/api/inspection-requests/${id}`)
      setFetchedByIdData(response.data)
    } catch (error) {
      console.error('Error fetching inspection request:', error)
      toast.error('Failed to fetch inspection request')
    }
  }

  const handleAcceptInspectionRequest = (id: number) => {
    console.log('Hanlde Accrept Inspection Called')
    axios
      .put(`https://api.inspection.payplatter.in/api/inspection-requests/${id}/accept_request`)
      .then(() => {
        toast.success('Inspection request accepted')
        fetchInspectionRequests()
        handleCloseAcceptModal()
        fetchInspectionRequestById(id)
        loadData()
      })
      .catch((error) => {
        console.error('Error accepting inspection request:', error)
        toast.error('Failed to accept inspection request')
      })
  }

  const handleDeassignInspectorRequest = async (requestId: number) => {
    try {
      await axios.put(`https://api.inspection.payplatter.in/api/inspection-requests/${requestId}/deassign`)

      setSelectedInspection((prevInspections) =>
        prevInspections
          ? prevInspections.map((inspection: InspectionRequest) =>
              inspection.request_id === requestId
                ? {...inspection, assigned_inspector: null, assigned_date: null, status: false}
                : inspection
            )
          : null
      )

      toast.success('Inspector de-assigned successfully')
      handleCloseRejectModal() // Close the modal after successful API call
      fetchInspectionRequests()

      // Refresh the page
      window.location.reload()
    } catch (error) {
      console.error('Error de-assigning inspector:', error)
      toast.error('Failed to de-assign inspector')
    }
  }

  const [formData, setFormData] = useState<CreateInspectionReport>({
    inspectionDate: new Date(),
    propertyAddress: '',
    city: '',
    state: '',
    zipcode: '',
    clientName: '',
    principalName: '',
    jobAddress: '',
    clientEmail: '',
    clientPhoneNumber: '',
    consultant: '',
    companyName: '',
    companyAddress: '',
    companyPostcode: '',
    companyEmail: '',
    companyContactNumbers: '',
    safetyHazard: false,
    majorDefect: false,
    minorDefect: false,
    overallCondition: '',
    buildingType: [],
    companyOrStrataTitle: false,
    floor: '',
    furnished: false,
    numberOfBedrooms: 0,
    occupied: false,
    orientation: '',
    otherBuildingElements: '',
    otherTimberBuildingElements: '',
    roof: '',
    storeys: '',
    walls: '',
    weather: '',
    areasInspected: '',
    inaccessibleAreas: '',
    obstructionsOrLimitations: '',
    riskOfUndetectedDefects: '',
    defects: [],
    additionalComments: '',
    recommendations: '',
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    console.log(formData.safetyHazard)

    const defectsPayload = safetyHazards.map((defect) => ({
      type: defect.type,
      building: defect.building,
      location: defect.location,
      selectedFindings: defect.selectedFindings, // Expecting this as string[]
      selectedFindingInfo: defect.selectedFindingInfo, // Expecting this as a string
      images: defect.images || [],
    }))

    const data: CreateInspectionReport = {
      ...formData,
      defects: [
        ...defectsPayload,
        ...majorDefects,
        ...minorDefects,
        ...furtherInspections,
        ...notedItems,
      ],
    }

    console.log("Payload:", data)  // <-- Add this line

    try {
      const response = await axios.post('https://api.inspection.payplatter.in/api/inspection-reports', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      console.log('submitted')
      console.log(response)
      // Clear form and defect states
      setFormData({
        inspectionDate: new Date(),
        propertyAddress: '',
        city: '',
        state: '',
        zipcode: '',
        clientName: '',
        principalName: '',
        jobAddress: '',
        clientEmail: '',
        clientPhoneNumber: '',
        consultant: '',
        companyName: '',
        companyAddress: '',
        companyPostcode: '',
        companyEmail: '',
        companyContactNumbers: '',
        safetyHazard: false,
        majorDefect: false,
        minorDefect: false,
        overallCondition: '',
        buildingType: [],
        companyOrStrataTitle: false,
        floor: '',
        furnished: false,
        numberOfBedrooms: 0,
        occupied: false,
        orientation: '',
        otherBuildingElements: '',
        otherTimberBuildingElements: '',
        roof: '',
        storeys: '',
        walls: '',
        weather: '',
        areasInspected: '',
        inaccessibleAreas: '',
        obstructionsOrLimitations: '',
        riskOfUndetectedDefects: '',
        defects: [],
        additionalComments: '',
        recommendations: '',
      })
      setSafetyHazards([])
      setMajorDefects([])
      setMinorDefects([])
      setFurtherInspections([])
      setNotedItems([])
    } catch (error) {
      console.error('Failed to create report:', error)
      console.log(error)
      toast.error('Failed to create inspection report.')
    }
  }

  function handleChange(event: React.ChangeEvent<{name?: string; value: any}>) {
    const {name, value} = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name!]: value, // value is an array of selected options
    }))
  }

  const handleDateChange = (date: Date | null) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      inspectionDate: date as Date,
    }))
  }

  const reportStyles = {
    receipt: {
      maxWidth: '100%',
      margin: '0',
      padding: '20px',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '10px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '2px solid #007bff',
      paddingBottom: '15px',
      marginBottom: '20px',
    },
    logo: {
      maxWidth: '150px',
    },
    logoImage: {
      maxWidth: '100%',
    },
    info: {
      textAlign: 'right',
    },
    companyName: {
      fontSize: '26px',
      color: '#007bff',
      margin: 0,
    },
    companyAddress: {
      fontSize: '14px',
      color: '#666',
      margin: '5px 0',
    },
    section: {
      marginBottom: '30px',
      pageBreakInside: 'avoid', // Add this to prevent section from breaking
    },
    sectionTitle: {
      fontSize: '22px',
      color: '#333',
      backgroundColor: '#e9ecef',
      padding: '12px',
      borderRadius: '8px',
      margin: 0,
      borderLeft: '5px solid #007bff',
    },
    details: {
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    },
    subSectionTitle: {
      fontSize: '18px',
      color: '#007bff',
      marginTop: '10px',
      marginBottom: '10px',
    },
    footer: {
      textAlign: 'center',
      fontSize: '14px',
      color: '#777',
      marginTop: '30px',
      borderTop: '1px solid #ddd',
      paddingTop: '15px',
    },
    checkbox: {
      marginRight: '10px',
    },
    defectItem: {
      marginBottom: '15px',
      padding: '10px',
      border: '1px solid #eee',
      borderRadius: '5px',
      backgroundColor: '#f9f9f9',
      pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
    },
    fieldLabel: {
      fontWeight: 'bold',
    },
    documents: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      marginTop: '10px',
      pageBreakInside: 'avoid',
    },
  }

  const componentRef = useRef<HTMLDivElement>(null)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showUploadModal, setShowUploadModal] = useState(false)

  const handleShowConfirmModal = () => setShowConfirmModal(true)
  const handleCloseConfirmModal = () => setShowConfirmModal(false)

  const handleShowUploadModal = () => setShowUploadModal(true)
  const handleCloseUploadModal = () => setShowUploadModal(false)

  const handleConfirm = () => {
    handleCloseConfirmModal()
    handleShowUploadModal()
  }

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files
    if (files) {
      // Handle file upload logic here
      console.log('Files to upload:', files)
    }
  }

  const [showModal2, setShowModal2] = useState(false)
  const [findingTypes, setFindingTypes] = useState<FindingType[]>([])
  const [showAddModal3, setShowAddModal3] = useState(false)
  const [newFindingTypeName, setNewFindingTypeName] = useState('')

  const [showSubTypeModal, setShowSubTypeModal] = useState(false)
  const [selectedFindingType, setSelectedFindingType] = useState<number | null>(null) // Allow numbers and null
  const [subTypes, setSubTypes] = useState<SubType[]>([])

  const [selectedFindingTypeId, setSelectedFindingTypeId] = useState<number | null>(null)

  const [showAddSubTypeModal, setShowAddSubTypeModal] = useState(false)
  const [newSubTypeName, setNewSubTypeName] = useState('')
  const [newSubTypeInfo, setNewSubTypeInfo] = useState('')

  const handleCloseSubTypeModal = () => setShowSubTypeModal(false)

  const handleOpenAddModal = () => setShowAddModal3(true)
  const handleCloseAddModal = () => setShowAddModal3(false)

  const handleOpenModal2 = () => setShowModal2(true)
  const handleCloseModal2 = () => setShowModal2(false)

  const handleOpenAddSubTypeModal = () => setShowAddSubTypeModal(true)
  const handleCloseAddSubTypeModal = () => setShowAddSubTypeModal(false)
  // const handleCloseSubTypeModal = () => setShowSubTypeModal(false);

  const handleAddSubType = async () => {
    if (newSubTypeName.trim() && newSubTypeInfo.trim() && selectedFindingTypeId) {
      const newSubType = {
        finding_name: newSubTypeName,
        information: newSubTypeInfo,
        finding_type_id: selectedFindingTypeId,
        user_id: currenteUserId,
      }

      try {
        const response = await fetch('https://api.inspection.payplatter.in/api/finding', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newSubType),
        })

        if (response.ok) {
          const result = await response.json()
          console.log('New Subtype Added:', result)

          // Update subTypes state
          setSubTypes([
            ...subTypes,
            {
              finding_id: result.finding_id, // Assuming backend returns finding_id
              finding_name: newSubTypeName,
              information: newSubTypeInfo,
            },
          ])

          // Reset form fields
          setNewSubTypeName('')
          setNewSubTypeInfo('')
          handleCloseAddSubTypeModal()
        } else {
          console.error('Failed to add subtype')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    } else {
      alert('Please fill in all fields.')
    }
  }

  useEffect(() => {
    if (showModal2) {
      fetchFindingTypes()
    }
  }, [showModal2])

  const handleFindingTypeClick = async (findingTypeId: number) => {
    try {
      const response = await axios.get(`https://api.inspection.payplatter.in/api/finding/type/${findingTypeId}`)
      setSubTypes(response.data) // Set the fetched subtypes
      setSelectedFindingType(findingTypeId)
      setSelectedFindingTypeId(findingTypeId) // Set the selected finding type
      setShowSubTypeModal(true) // Open the modal
    } catch (error) {
      console.error('Error fetching subtypes:', error)
    }
  }

  // const handleFindingTypeClick = (findingTypeId: number) => {
  //   setSelectedFindingTypeId(findingTypeId);
  //   setShowSubTypeModal(true);
  // };

  const fetchFindingTypes = () => {
    axios
      .get<FindingType[]>(`https://api.inspection.payplatter.in/api/finding-type/user/${currenteUserId}`)
      .then((response) => {
        setFindingTypes(response.data)
      })
      .catch((error) => {
        console.error('There was an error fetching the finding types!', error)
      })
  }

  const handleAddFinding = () => {
    const newFinding = {
      finding_type_name: newFindingTypeName,
      finding_cat_id: 2,
      user_id: currenteUserId,
    }

    axios
      .post('https://api.inspection.payplatter.in/api/finding-type', newFinding)
      .then((response) => {
        setNewFindingTypeName('')
        setShowAddModal3(false)
        fetchFindingTypes() // Refresh the findings list to include the new finding
      })
      .catch((error) => {
        console.error(
          'There was an error adding the new finding type!',
          error.response.data.message
        )
      })
  }

  //scroll match functions and code
  const formRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  // Scroll syncing logic
  const handleScrollSync = () => {
    if (formRef.current && previewRef.current) {
      const formScrollTop = formRef.current.scrollTop;
      const formScrollHeight = formRef.current.scrollHeight - formRef.current.clientHeight;
      
      const previewScrollHeight = previewRef.current.scrollHeight - previewRef.current.clientHeight;
      

       // Check if the scroll event is detected
      //  console.log("Form Scroll Top:", formScrollTop);
      //  console.log("Form Scroll Height:", formScrollHeight);
      //  console.log("Preview Scroll Height:", previewScrollHeight);


      // Calculate scroll ratio
      const scrollRatio = formScrollTop / formScrollHeight;

      // Set preview scroll based on form scroll
      previewRef.current.scrollTop = scrollRatio * previewScrollHeight;

      // console.log("Preview Scroll Top:", previewRef.current.scrollTop);
    }
  };

  useEffect(() => {
    // This effect will run when the modal is opened
    const formElement = formRef.current;

    if (showAddReportModal && formElement) {
      // Attach scroll event listener when modal is open
      formElement.addEventListener('scroll', handleScrollSync);

      // Cleanup: Remove scroll event listener when modal closes
      return () => {
        formElement.removeEventListener('scroll', handleScrollSync);
      };
    }
  }, [showAddReportModal]); // Depend on modal state


  //logofetch


  const [profilePic, setProfilePic] = useState<string>('')
  const [loading, setLoading] = useState(false)
  

  const fetchProfilePhoto = async () => {
    try {
      const response = await axios.get<InspectorProfilePhoto>(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/profilepic`
      )
      setProfilePic(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/profilepic`
      )
      setLoading(false)
    } catch (err) {
      console.error('Error fetching Profilepic:', err)
      setLoading(false)
    }
  }
  



  //style bbo
  <style>
    {
      `
      .table-wrapper {
  position: relative;
  overflow-x: auto;
}

.arrow-indicator {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: none; /* Hidden by default */
  z-index: 1;
  cursor: pointer;
}

.table-wrapper.show-arrow .arrow-indicator {
  display: block;
}

      `
    }
  </style>

useEffect(() => {
  fetchProfilePhoto()
}, [
  handleOpenModal,
  handleCloseModal,
  handleChange,
  handleSubmit,
  handleOpenAddReportModal
  
])
  return (
    <>
      <div className={`card ${className}`}>
        <div className='card-header border-0 pt-5 d-flex justify-content-between'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Inspections List</span>
          </h3>
          <div className='d-flex align-items-center'>
          <button 
        onClick={handleShowExistingReportModal}
        className='btn btn-dark btn-sm border border-dark rounded-sm px-4 shadow-sm me-3 d-flex align-items-center'
        style={{ whiteSpace: 'nowrap' }}
      >
        <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2 me-1' />
        <span>Add Existing Report</span>
      </button>

            <button
              className='btn btn-dark btn-sm border border-dark rounded-sm px-4 shadow-sm me-3'
              onClick={handleOpenModal2}
              style={{ whiteSpace: 'nowrap' }}  // Prevents wrapping
            >
              <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-2 me-1' />
              <span>Add Custom Defects</span>
            </button>

            <select
              className='form-select form-select-sm me-3'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value='all'>All Status</option>
              <option value='active'>Completed</option>
              <option value='completed'>Incomplete</option>
            </select>

            <select
              className='form-select form-select-sm me-3'
              value={dateFilter}
              onChange={handleDateFilterChange}
            >
              <option value='all'>Date Range</option>
              <option value='thisMonth'>This Month</option>
              <option value='lastThreeMonths'>Last Three Months</option>
              <option value='year'>This Year</option>
              <option value='custom'>Custom</option>
            </select>
            {dateFilter === 'custom' && (
              <div className='d-flex flex-column position-relative'>
                <div className='d-flex'>
                  <input
                    type='date'
                    className='form-control form-control-sm me-2'
                    value={customStartDate}
                    onChange={(e) => setCustomStartDate(e.target.value)}
                  />
                  <input
                    type='date'
                    className='form-control form-control-sm me-2'
                    value={customEndDate}
                    onChange={(e) => setCustomEndDate(e.target.value)}
                  />
                  <button
                    className='btn btn-sm btn-primary'
                    onClick={() => setDateFilter('custom')}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                <th className='min-w-300px text-center rounded-end'>Manage Report</th>
                <th className='min-w-150px'>Status</th>
                  <th className='min-w-150px'>Accepted/Rejected</th>
                  <th className='ps-4 min-w-150px rounded-start'>Requester</th>
                  <th className='min-w-150px'>Type</th>
                  <th className='min-w-300px'>Property Details</th>
                  <th className='min-w-150px'>Request Date</th>
                  <th className='min-w-150px'>Assigned Date</th>
                  <th className='min-w-150px'>Inspection Date</th>
                  
                  
                </tr>
              </thead>
              <tbody>
                {getFilteredInspections().map((inspection) => (
                  <tr key={inspection.request_id}>
                    <td className='text-center'>
                      {inspection.accept ? (
                        <>
                          <button
                            className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            style={{color: 'white'}}
                            onClick={() => handleOpenAddReportModal()}
                          >
                            Create Report
                          </button>
                          <button
                            className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4'
                            style={{color: 'white'}}
                            onClick={() => handleShowConfirmModal()}
                          >
                            Upload Report
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                            style={{color: 'white'}}
                            onClick={() => handleOpenModal(inspection)}
                          >
                            Take Action
                          </button>
                        </>
                      )}

                      {/* <button
                        className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                        style={{color: 'white'}}
                        onClick={() => handleOpenModal(inspection)}
                      >
                        Take Action
                      </button>
                      <button
                        className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4 me-2 mb-2'
                        style={{color: 'white'}}
                        onClick={() => handleOpenAddReportModal()}
                      >
                        Add Report
                      </button> */}
                    </td>
                    <td>
                      <span
                        className={`badge badge-light-${
                          inspection.status ? 'success' : 'danger'
                        } fs-7 fw-semibold`}
                      >
                        {inspection.status ? 'Completed' : 'Incomplete'}
                      </span>
                    </td>

                    <td>
                      <span
                        className={`badge badge-light-${
                          inspection.accept ? 'success' : 'danger'
                        } fs-7 fw-semibold`}
                      >
                        {inspection.accept ? 'Accepted' : 'Not Accepted'}
                      </span>
                    </td>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {inspection.requested_by.firstname} {inspection.requested_by.lastname}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {inspection.requester_role}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {inspection.property.address}, {inspection.property.city},{' '}
                        {inspection.property.state} - {inspection.property.zipcode}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(inspection.request_date).toLocaleDateString()}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(inspection.assigned_date).toLocaleDateString()}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {inspection.inspection_date
                          ? new Date(inspection.inspection_date).toLocaleDateString()
                          : 'Not Started'}
                      </a>
                    </td>



                    
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Confirmation Modal */}
        <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} size='lg' centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Sending Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to send this report to the respective client?
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseConfirmModal}>
              Cancel
            </Button>
            <Button variant='primary' onClick={handleConfirm}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Upload Modal */}
        <Modal show={showUploadModal} onHide={handleCloseUploadModal} size='lg' centered>
          <Modal.Header closeButton>
            <Modal.Title>Upload Report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Upload PDF Report</Form.Label>
                <Form.Control type='file' accept='.pdf' onChange={handleUpload} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseUploadModal}>
              Close
            </Button>
            <Button variant='primary' onClick={handleCloseUploadModal}>
              Upload
            </Button>
          </Modal.Footer>
        </Modal>

        {/* View Inspection Modal */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          size='lg'
          dialogClassName='modal-90w'
        >
          <Modal.Header closeButton>
            <Modal.Title>Inspection Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className='p-4' style={{maxHeight: '75vh', overflowY: 'auto'}}>
            {selectedInspection && (
              <div className='bg-light p-4 rounded shadow-sm'>
                <h5 className='mb-3 text-primary'>
                  {new Date(selectedInspection.request_date).toLocaleDateString()}
                </h5>
                <div className='mb-3'>
                  <strong>Requester:</strong>
                  <span className='ms-2'>
                    {selectedInspection.requested_by.firstname}{' '}
                    {selectedInspection.requested_by.lastname}
                  </span>
                </div>
                <div className='mb-3'>
                  <strong>Requester Role:</strong>
                  <span className='ms-2'>{selectedInspection.requester_role}</span>
                </div>
                <div className='mb-3'>
                  <strong>Property Address:</strong>
                  <span className='ms-2'>{selectedInspection.property.address}</span>
                </div>
                <div className='mb-3'>
                  <strong>City:</strong>
                  <span className='ms-2'>{selectedInspection.property.city}</span>
                </div>
                <div className='mb-3'>
                  <strong>State:</strong>
                  <span className='ms-2'>{selectedInspection.property.state}</span>
                </div>
                <div className='mb-3'>
                  <strong>Zipcode:</strong>
                  <span className='ms-2'>{selectedInspection.property.zipcode}</span>
                </div>
                <div className='mb-3'>
                  <strong>Inspection got assigned to you on:</strong>
                  <span className='ms-2'>
                    {new Date(selectedInspection.assigned_date).toLocaleDateString()}
                  </span>
                </div>

                <div className='mb-3'>
                  <strong>Status:</strong>
                  <span
                    className={`badge ${
                      selectedInspection.status ? 'bg-success' : 'bg-danger'
                    } ms-2`}
                  >
                    {selectedInspection.status ? 'Completed' : 'Incomplete'}
                  </span>
                </div>
                <div
                  style={{width: '80%', height: '1px', backgroundColor: '#ccc', margin: '10px 0px'}}
                ></div>
                <div className='mb-3'>
                  <strong>Accept OR Reject The Inspection Request:</strong>
                  <span className='ms-2'>
                    <button
                      className='btn btn-sm px-4 me-2'
                      style={{backgroundColor: 'green', color: 'white'}}
                      onClick={() => handleOpenAcceptModal(selectedInspection)}
                    >
                      Accept
                    </button>
                    <button
                      className='btn btn-sm px-4'
                      style={{backgroundColor: 'red', color: 'white'}}
                      onClick={() => handleOpenRejectModal(selectedInspection)}
                    >
                      Reject
                    </button>
                  </span>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>

        {/* View Accept Modal 1 */}
        {/* Modal for accepting an inspection request */}
        <Modal show={showAcceptModal} onHide={handleCloseAcceptModal} size='lg' centered>
          <Modal.Header closeButton>
            <Modal.Title>Accept Inspection Request?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseAcceptModal}>
              Close
            </Button>

            <Button
              variant='primary'
              onClick={() => {
                if (selectedInspection?.request_id !== undefined) {
                  handleAcceptInspectionRequest(selectedInspection.request_id)
                } else {
                  console.error('Inspection request ID is not available')
                }
              }}
            >
              Accept
            </Button>
          </Modal.Footer>
        </Modal>
        {/* View Reject Modal 2 */}
        {/* Modal for rejecting an inspection request */}
        <Modal show={showRejectModal} onHide={handleCloseRejectModal} size='lg' centered>
          <Modal.Header closeButton>
            <Modal.Title>Reject Inspection Request</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseRejectModal}>
              Close
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                if (selectedInspection) {
                  handleDeassignInspectorRequest(selectedInspection.request_id)
                }
              }}
            >
              Reject
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Report Modal */}
        <Modal show={showAddReportModal} onHide={handleCloseModal} size='lg' fullscreen>
        <Modal.Header closeButton>
  <div className="d-flex justify-content-between align-items-center w-100">
    <Modal.Title>Add Report</Modal.Title>
    <div>
    <Button variant='outline-dark' size="sm" style={{border:"solid black 2px"}} className='mx-10'>
        Save Draft
      </Button>
      <ReactToPrint
        trigger={() => (
          <Button variant='primary' style={{ marginBottom: '2px' }}>
            Download PDF
          </Button>
        )}
        content={() => componentRef.current}
        pageStyle='@page { margin: 0; } body { margin: 0; padding: 0; }'
      />
    </div>
  </div>
</Modal.Header>

          <Modal.Body
            style={{
              display: 'flex',
              height: '80vh',
              // overflowY: 'auto',
              overflow: 'hidden',
              padding: '20px',
              fontFamily: 'Arial, sans-serif',
              color: '#333',
            }}

          >
            {/* <Row> */}
            {/* Form Section */}
            {/* <Col md={7}> */}
            <div 
            ref={formRef}
              style={{
                flex: 1,
                overflowY: 'auto',
                paddingRight: '20px',
                borderRight: '2px solid #ccc',
                height: '100%',
              }}
            >
              {/* <Form onSubmit={handleSubmit} onClick={() => setShowAddReportModal(false)}> */}
              <Form onSubmit={handleSubmit}>
                

                {/* Section 1: Basic Information */}
                <div className="form-section" id="form-section-1">
                <h5
                  className='mt-5'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Basic Information
                </h5>
                <Row>
                  <Col md={6} className='mt-5'>
                    <Form.Group controlId='inspectionDate'>
                      <Form.Label>Inspection Date &nbsp;</Form.Label>
                      <DatePicker
                        selected={formData.inspectionDate}
                        placeholderText='Select Date'
                        onChange={handleDateChange}
                        className='form-control'
                        id='inspectionDate'
                        name='inspectionDate'
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-5'>
                  <Col md={6}>
                    <Form.Group controlId='propertyAddress'>
                      <Form.Label>Property Address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter property address'
                        name='propertyAddress'
                        value={formData.propertyAddress}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='city'>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter city'
                        name='city'
                        value={formData.city}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col md={6}>
                    <Form.Group controlId='state'>
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter state'
                        name='state'
                        value={formData.state}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='zipcode'>
                      <Form.Label>Zipcode</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter zipcode'
                        name='zipcode'
                        value={formData.zipcode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>

                <hr className='my-4 mt-12 mb-8  mx-auto' />

                {/* Section 2: The Parties */}
                <div className="form-section" id="form-section-2">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  The Parties
                </h5>
                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='clientName'>
                      <Form.Label>Client Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter client name'
                        name='clientName'
                        value={formData.clientName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='principalName'>
                      <Form.Label>Name of the Principal (If Applicable)</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter name of the principal'
                        name='principalName'
                        value={formData.principalName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='jobAddress'>
                      <Form.Label>Job Address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter job address'
                        name='jobAddress'
                        value={formData.jobAddress}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='clientEmail'>
                      <Form.Label>Client's Email Address</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='Enter client email'
                        name='clientEmail'
                        value={formData.clientEmail}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='clientPhoneNumber'>
                      <Form.Label>Client's Phone Number</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter client phone number'
                        name='clientPhoneNumber'
                        value={formData.clientPhoneNumber}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='consultant'>
                      <Form.Label>Consultant</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter consultant name'
                        name='consultant'
                        value={formData.consultant}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>

                <hr className='my-4 mt-12 mb-8  mx-auto' />

                {/* Section 3: Company Details */}
                <div className="form-section" id="form-section-3">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Company Details
                </h5>
                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='companyName'>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter company name'
                        name='companyName'
                        value={formData.companyName}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='companyAddress'>
                      <Form.Label>Company Address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter company address'
                        name='companyAddress'
                        value={formData.companyAddress}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='companyPostcode'>
                      <Form.Label>Company Postcode</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter company postcode'
                        name='companyPostcode'
                        value={formData.companyPostcode}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='companyEmail'>
                      <Form.Label>Company Email</Form.Label>
                      <Form.Control
                        type='email'
                        placeholder='Enter company email'
                        name='companyEmail'
                        value={formData.companyEmail}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='companyContactNumbers'>
                      <Form.Label>Company Contact Numbers</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter company contact numbers'
                        name='companyContactNumbers'
                        value={formData.companyContactNumbers}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>

                <hr className='my-4 mt-12 mb-8  mx-auto' />

                {/* Section A: Results of Inspection - Summary */}
                <div className="form-section" id="form-section-4">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Section A: Results of Inspection - Summary
                </h5>
                <Row className='mt-6'>
                  <Col md={12}>
                    <Form.Group controlId='safetyHazard'>
                      <Form.Label>Safety Hazard</Form.Label>
                      {['Yes, There are Safety Hazards reported in this report'].map((type) => (
                      <Form.Check
                      key={type}
                      label={type}
                        type='checkbox'
                        name='safetyHazard'
                        checked={formData.safetyHazard}
                        onChange={(e) => setFormData({...formData, safetyHazard: e.target.checked})}
                      />
                      ))}
                    </Form.Group>
                  </Col>

                  <Col md={12} className='mt-5'>
                    <Form.Group controlId='majorDefect'>
                      <Form.Label>Major Defect</Form.Label>
                      {['Yes, There are Major Defect reported in this report'].map((type) => (
                      <Form.Check
                      key={type}
                      label={type}
                        type='checkbox'
                        name='majorDefect'
                        checked={formData.majorDefect}
                        onChange={(e) => setFormData({...formData, majorDefect: e.target.checked})}
                      />
                      ))}
                    </Form.Group>
                  </Col>

                  <Col md={12} className='mt-5'>
                    <Form.Group controlId='minorDefect'>
                      <Form.Label>Minor Defect</Form.Label>
                      {['Yes, There are Minor Defect reported in this report'].map((type) => (
                      <Form.Check
                      key={type}
                      label={type}
                        type='checkbox'
                        name='minorDefect'
                        checked={formData.minorDefect}
                        onChange={(e) => setFormData({...formData, minorDefect: e.target.checked})}
                      />
                      ))}
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={12}>
                    <Form.Group controlId='overallCondition'>
                      <Form.Label>Overall Condition</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        name='overallCondition'
                        placeholder='Enter overall condition'
                        value={formData.overallCondition}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>

                <hr className='my-4 mt-12 mb-8  mx-auto' />

                {/* Section B: General Description of the Property */}
                <div className="form-section" id="form-section-5">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Section B: General Description of the Property
                </h5>
                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='buildingType'>
                      <Form.Label>Building Type</Form.Label>
                      <div>
                        {['Residential', 'Commercial', 'Industrial'].map((type) => (
                          <Form.Check
                            key={type}
                            type='checkbox'
                            style={{marginBottom:"10px"}}
                            label={type}
                            value={type}
                            checked={formData.buildingType.includes(type)}
                            onChange={(e) => {
                              const {checked, value} = e.target
                              setFormData((prevState) => {
                                const newBuildingType = checked
                                  ? [...prevState.buildingType, value]
                                  : prevState.buildingType.filter((t) => t !== value)

                                return {
                                  ...prevState,
                                  buildingType: newBuildingType,
                                }
                              })
                            }}
                          />
                        ))}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId='companyOrStrataTitle'>
                      <Form.Label>Company or Strata Title</Form.Label>
                      <Form.Check
                        type='checkbox'
                        name='companyOrStrataTitle'
                        checked={formData.companyOrStrataTitle}
                        onChange={(e) => {
                          const {checked} = e.target
                          setFormData((prevState) => ({
                            ...prevState,
                            companyOrStrataTitle: checked,
                          }))
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='floor'>
                      <Form.Label>Floor</Form.Label>
                      <Form.Control
                        type='text'
                        name='floor'
                        placeholder='Enter floor'
                        value={formData.floor}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='furnished'>
                      <Form.Label>Furnished</Form.Label>
                      <Form.Control
                        as='select'
                        name='furnished'
                        value={formData.furnished ? 'Yes' : 'No'}
                        onChange={(e) => {
                          const value = e.target.value
                          setFormData((prevState) => ({
                            ...prevState,
                            furnished: value === 'Yes',
                          }))
                        }}
                      >
                        <option value=''>Select</option>
                        <option value='Yes'>Yes</option>
                        <option value='No'>No</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='numberOfBedrooms'>
                      <Form.Label>No. of Bedrooms</Form.Label>
                      <Form.Control
                        type='number'
                        name='numberOfBedrooms'
                        placeholder='Enter number of bedrooms'
                        value={
                          formData.numberOfBedrooms !== undefined
                            ? formData.numberOfBedrooms.toString()
                            : ''
                        }
                        onChange={(e) => {
                          const {name, value} = e.target
                          setFormData((prevState) => ({
                            ...prevState,
                            [name]: value ? parseInt(value, 10) : undefined, // Parse value to number or set as undefined if empty
                          }))
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='occupied'>
                      <Form.Label>Occupied</Form.Label>
                      <Form.Control
                        as='select'
                        name='occupied'
                        value={formData.occupied.toString()} // Convert boolean to string
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            occupied: event.target.value === 'true', // Convert string back to boolean
                          })
                        }}
                      >
                        <option value='true'>Yes</option>
                        <option value='false'>No</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='orientation'>
                      <Form.Label>Orientation</Form.Label>
                      <Form.Control
                        as='select'
                        name='orientation'
                        value={formData.orientation}
                        onChange={handleChange}
                      >
                        <option value=''>Select</option>
                        <option value='North'>North</option>
                        <option value='South'>South</option>
                        <option value='East'>East</option>
                        <option value='West'>West</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='otherBuildingElements'>
                      <Form.Label>Other Building Elements</Form.Label>
                      <Form.Control
                        type='text'
                        name='otherBuildingElements'
                        placeholder='Enter other building elements'
                        value={formData.otherBuildingElements}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='otherTimberBuildingElements'>
                      <Form.Label>Other Timber Building Elements</Form.Label>
                      <Form.Control
                        type='text'
                        name='otherTimberBuildingElements'
                        placeholder='Enter other timber building elements'
                        value={formData.otherTimberBuildingElements}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='roof'>
                      <Form.Label>Roof</Form.Label>
                      <Form.Control
                        type='text'
                        name='roof'
                        placeholder='Enter roof details'
                        value={formData.roof}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='storeys'>
                      <Form.Label>Storeys</Form.Label>
                      <Form.Control
                        type='number'
                        name='storeys'
                        placeholder='Enter number of storeys'
                        value={formData.storeys}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId='walls'>
                      <Form.Label>Walls</Form.Label>
                      <Form.Control
                        type='text'
                        name='walls'
                        placeholder='Enter wall details'
                        value={formData.walls}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={6}>
                    <Form.Group controlId='weather'>
                      <Form.Label>Weather</Form.Label>
                      <Form.Control
                        type='text'
                        name='weather'
                        placeholder='Enter weather details'
                        value={formData.weather}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>

                <hr className='my-4 mt-12 mb-8  mx-auto' />
                <div className="form-section" id="form-section-6">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Section C: Accessibility
                </h5>
                <Row className='mt-6'>
                  <Col md={12}>
                    <Form.Group controlId='areasInspected'>
                      <Form.Label>Areas Inspected</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        name='areasInspected'
                        placeholder='Enter areas inspected'
                        value={formData.areasInspected}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={12}>
                    <Form.Group controlId='inaccessibleAreas'>
                      <Form.Label>Inaccessible Areas</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        name='inaccessibleAreas'
                        placeholder='Enter inaccessible areas'
                        value={formData.inaccessibleAreas}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={12}>
                    <Form.Group controlId='obstructionsOrLimitations'>
                      <Form.Label>Obstructions/Limitations</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        name='obstructionsOrLimitations'
                        placeholder='Enter obstructions and limitations'
                        value={formData.obstructionsOrLimitations}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className='mt-6'>
                  <Col md={12}>
                    <Form.Group controlId='riskOfUndetectedDefects'>
                      <Form.Label>Risk of Undetected Defects</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        name='riskOfUndetectedDefects'
                        placeholder='Enter risk of undetected defects'
                        value={formData.riskOfUndetectedDefects}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>
                <hr className='my-5 mt-12 mb-8  mx-auto' />

                
                <h5 className='mt-6'>Section D Significant Items</h5>
                <div className="form-section" id="form-section-7">
                <h5
                  className='mt-6'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  D1: Safety Hazard
                </h5>
                {safetyHazards.map((defect, index) => (
                  <React.Fragment key={index}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <span></span>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-color-primary'
                        onClick={() => handleRemoveSafetyHazard(index)}
                      >
                        <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                      </button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId={`safetyHazardBuilding-${index}`}>
                          <Form.Label>Building</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter safety hazard building'
                            name='building'
                            value={defect.building}
                            onChange={(e) => handleSafetyHazardChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`safetyHazardLocation-${index}`}>
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter safety hazard location'
                            name='location' // Name should match property in safetyHazards
                            value={defect.location}
                            onChange={(e) => handleSafetyHazardChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col md={6}>
                        <Form.Group controlId={`safetyHazardCategory-${index}`}>
                          <Form.Label>Category of Finding</Form.Label>
                          <Select
                            options={categories.map((cat) => ({
                              value: cat.finding_cat_id,
                              label: cat.cat_name,
                            }))}
                            onChange={(option) => handleCategoryChange(index, option)}
                            value={defect.selectedCategory ? { value: defect.selectedCategory, label: categories.find((cat) => cat.finding_cat_id === defect.selectedCategory)?.cat_name } : null}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`safetyHazardType-${index}`}>
                          <Form.Label>Type of Finding</Form.Label>
                          <Select
                            options={filteredTypes.map((type) => ({
                              value: type.finding_type_id,
                              label: type.finding_type_name,
                            }))}
                            onChange={(option) => handleTypeChange(index, option)}
                            value={defect.selectedType ? { value: defect.selectedType, label: defect.filteredTypesList.find((type) => type.finding_type_id === defect.selectedType)?.finding_type_name } : null}
                          />
                        </Form.Group>
                      </Col>

                      <Row className='mt-3'>
                        {additionalOptions.length > 0 && (
                          <Col md={6}>
                            <Form.Group controlId={`majorDefectFinding-${index}`}>
                              <Form.Label>Findings</Form.Label>
                              <Select
                                isMulti // This enables multiple selections in the dropdown
                                options={(defect.additionalOptions || []).map((option) => ({
                                  label: option.label,
                                  value: option.label, // Use `label` as both the label and value to ensure `string[]`
                                }))}
                                value={defect.selectedFindings.map((finding) => ({
                                  label: finding,
                                  value: finding, // Maintain `string` values for `selectedFindings`
                                }))}
                                onChange={(selected) => handleFindingsChange(index, selected)}

                              />
                            </Form.Group>
                          </Col>
                        )}

                        <Col md={6}>
                          <Form.Group controlId={`majorDefectInformation-${index}`}>
                            <Form.Label>Information</Form.Label>
                            <Form.Control
                              as='textarea'
                              rows={4}
                              value={defect.selectedFindingInfo}
                              readOnly
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Row>

                    <Row className='mt-3'>
                      <Col md={6}>
                        <Form.Group controlId={`safetyHazardImages-${index}`}>
                          <Form.Label>Images (Safety Hazards)</Form.Label>
                          <input
  type='file'
  multiple
  id={`safetyHazardImages-${index}`} // Unique ID for the file input
  onChange={(e) => handleSafetyHazardImageChange(index, e)}
  style={{
    display: 'block',
    width: '100%',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    lineHeight: '1.5',
    color: '#495057',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid #ced4da',
    borderRadius: '.25rem',
    transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
  }}
/>

                        </Form.Group>
                      </Col>
                      <Col
                        md={6}
                        style={{alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}
                      >
                        {!cameraStream && (
                        <Button onClick={startCamera} className='btn-secondary'>
                          Open Camera
                        </Button>
                        )}
                        {cameraStream && (
                          <>
                          <Button onClick={toggleCamera} className="btn-primary me-2">
                Switch Camera (Currently {facingMode === 'user' ? 'Front' : 'Rear'})
              </Button>
                          <Button onClick={startCamera} className='btn-primary btn-dark'>
                          Start Camera
                        </Button>
                            <video
                              ref={videoRef}
                              style={{width: '100%', marginTop: '10px', border: '1px solid black'}}
                              autoPlay
                              playsInline
                              muted
                            />
                            <canvas ref={canvasRef} style={{display: 'none'}} />
                            <Button onClick={() => takePhoto(index)} className='me-2'>
                              Take Photo
                            </Button>
                            <Button onClick={stopCamera}>Close Camera</Button>
                          </>
                        )}
                      </Col>
                    </Row>

                    <hr className='my-4 mt-8 mb-8 mx-auto' />
                  </React.Fragment>
                ))}

                <Button onClick={handleAddSafetyHazard}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another Safety
                  Hazard
                </Button>
                </div>

                <hr className='my-4 mt-8 mb-8  mx-auto' />

                {/* Section D: Major Defects */}
                <div className="form-section" id="form-section-8">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  D2: Major Defects
                </h5>

                {majorDefects.map((defect, index) => (
                  <React.Fragment key={index}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <span></span>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-color-primary'
                        onClick={() => handleRemoveMajorDefect(index)}
                      >
                        <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                      </button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId={`majorDefectBuilding-${index}`}>
                          <Form.Label>Building</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter major defect building'
                            name='building' // Name should match property in majorDefects
                            value={defect.building}
                            onChange={(e) => handleMajorDefectChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`majorDefectLocation-${index}`}>
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter major defect location'
                            name='location' // Name should match property in majorDefects
                            value={defect.location}
                            onChange={(e) => handleMajorDefectChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md={6}>
                        <Form.Group controlId={`majorDefectFinding-${index}`}>
                          <Form.Label>Finding</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter major defect finding'
                            name='finding' // Name should match property in majorDefects
                            value={defect.selectedFindings}
                            onChange={(e) => handleMajorDefectChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`majorDefectInformation-${index}`}>
                          <Form.Label>Information</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter major defect information'
                            name='information' // Name should match property in majorDefects
                            value={defect.selectedFindingInfo}
                            onChange={(e) => handleMajorDefectChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md={12}>
                        <Form.Group controlId={`majorDefectImages-${index}`}>
                          <Form.Label>Images (Major Defects)</Form.Label>
                          <input
                            type='file'
                            multiple
                            onChange={(e) => handleMajorDefectImageChange(index, e)}
                            style={{
                              display: 'block',
                              width: '100%',
                              padding: '.375rem .75rem',
                              fontSize: '1rem',
                              lineHeight: '1.5',
                              color: '#495057',
                              backgroundColor: '#fff',
                              backgroundClip: 'padding-box',
                              border: '1px solid #ced4da',
                              borderRadius: '.25rem',
                              transition:
                                'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <hr className='my-4 mt-8 mb-8 mx-auto' />
                  </React.Fragment>
                ))}

                <Button onClick={handleAddMajorDefect}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another Major
                  Defect
                </Button>
                </div>

                <hr className='my-4 mt-8 mb-8  mx-auto' />

                {/* Section E: Minor Defects */}
                <div className="form-section" id="form-section-9">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  D3: Minor Defects
                </h5>

                {minorDefects.map((defect, index) => (
                  <React.Fragment key={index}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <span></span>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-color-primary'
                        onClick={() => handleRemoveMinorDefect(index)}
                      >
                        <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                      </button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId={`minorDefectBuilding-${index}`}>
                          <Form.Label>Building</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter minor defect building'
                            name='building' // Name should match property in minorDefects
                            value={defect.building}
                            onChange={(e) => handleMinorDefectChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`minorDefectLocation-${index}`}>
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter minor defect location'
                            name='location' // Name should match property in minorDefects
                            value={defect.location}
                            onChange={(e) => handleMinorDefectChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md={6}>
                        <Form.Group controlId={`minorDefectFinding-${index}`}>
                          <Form.Label>Finding</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter minor defect finding'
                            name='finding' // Name should match property in minorDefects
                            value={defect.selectedFindings}
                            onChange={(e) => handleMinorDefectChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`minorDefectInformation-${index}`}>
                          <Form.Label>Information</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter minor defect information'
                            name='information' // Name should match property in minorDefects
                            value={defect.selectedFindingInfo}
                            onChange={(e) => handleMinorDefectChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md={12}>
                        <Form.Group controlId={`minorDefectImages-${index}`}>
                          <Form.Label>Images (Minor Defects)</Form.Label>
                          <input
                            type='file'
                            multiple
                            onChange={(e) => handleMinorDefectImageChange(index, e)}
                            style={{
                              display: 'block',
                              width: '100%',
                              padding: '.375rem .75rem',
                              fontSize: '1rem',
                              lineHeight: '1.5',
                              color: '#495057',
                              backgroundColor: '#fff',
                              backgroundClip: 'padding-box',
                              border: '1px solid #ced4da',
                              borderRadius: '.25rem',
                              transition:
                                'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr className='my-4 mt-8 mb-8 mx-auto' />
                  </React.Fragment>
                ))}

                <Button onClick={handleAddMinorDefect}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another Minor
                  Defect
                </Button>
                </div>

                <hr className='my-4 mt-8 mb-8  mx-auto' />
                <div className="form-section" id="form-section-10">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  D4: Further Inspections
                </h5>
                {furtherInspections.map((defect, index) => (
                  <React.Fragment key={index}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <span></span>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-color-primary'
                        onClick={() => handleRemoveFurtherInspection(index)}
                      >
                        <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                      </button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId={`furtherInspectionBuilding-${index}`}>
                          <Form.Label>Building</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter further inspection building'
                            name='building' // Name should match property in furtherInspections
                            value={defect.building}
                            onChange={(e) => handleFurtherInspectionChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`furtherInspectionLocation-${index}`}>
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter further inspection location'
                            name='location' // Name should match property in furtherInspections
                            value={defect.location}
                            onChange={(e) => handleFurtherInspectionChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md={6}>
                        <Form.Group controlId={`furtherInspectionFinding-${index}`}>
                          <Form.Label>Finding</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter further inspection finding'
                            name='finding' // Name should match property in furtherInspections
                            value={defect.selectedFindings}
                            onChange={(e) => handleFurtherInspectionChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`furtherInspectionInformation-${index}`}>
                          <Form.Label>Information</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter further inspection information'
                            name='information' // Name should match property in furtherInspections
                            value={defect.selectedFindingInfo}
                            onChange={(e) => handleFurtherInspectionChange(index, e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md={12}>
                        <Form.Group controlId={`furtherInspectionImages-${index}`}>
                          <Form.Label>Images (Further Inspections)</Form.Label>
                          <input
                            type='file'
                            multiple
                            onChange={(e) => handleFurtherInspectionImageChange(index, e)}
                            style={{
                              display: 'block',
                              width: '100%',
                              padding: '.375rem .75rem',
                              fontSize: '1rem',
                              lineHeight: '1.5',
                              color: '#495057',
                              backgroundColor: '#fff',
                              backgroundClip: 'padding-box',
                              border: '1px solid #ced4da',
                              borderRadius: '.25rem',
                              transition:
                                'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr className='my-4 mt-8 mb-8 mx-auto' />
                  </React.Fragment>
                ))}

                <Button onClick={handleAddFurtherInspection}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another
                </Button>
                </div>

                <hr className='my-4 mt-8 mb-8  mx-auto' />

                <div className="form-section" id="form-section-11">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  D5: Conclusion - Assessment of overall condition of property
                </h5>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId='recommendations'>
                      <Form.Label>Recommendations</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder='Enter Conclusion and overall condition of property'
                        name='recommendations'
                        value={formData.recommendations}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>

                <hr className='my-4 mt-8 mb-8  mx-auto' />

                {/* Section F: Noted Items */}
                <div className="form-section" id="form-section-12">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  D6: Noted Items OR Undefined Defects
                </h5>
                {notedItems.map((defect, index) => (
                  <React.Fragment key={index}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <span></span>
                      <button
                        type='button'
                        className='btn btn-sm btn-icon btn-active-color-primary'
                        onClick={() => handleRemoveNotedItem(index)}
                      >
                        <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                      </button>
                    </div>
                    <Row>
                      <Col md={6}>
                        <Form.Group controlId={`notedItemBuilding-${index}`}>
                          <Form.Label>Building</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter noted item building'
                            name='building'
                            value={defect.building}
                            onChange={handleNotedItemChange(index)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`notedItemLocation-${index}`}>
                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            type='text'
                            placeholder='Enter noted item location'
                            name='location'
                            value={defect.location}
                            onChange={handleNotedItemChange(index)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md={6}>
                        <Form.Group controlId={`notedItemFinding-${index}`}>
                          <Form.Label>Finding</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter noted item finding'
                            name='finding'
                            value={defect.selectedFindings}
                            onChange={handleNotedItemChange(index)}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group controlId={`notedItemInformation-${index}`}>
                          <Form.Label>Information</Form.Label>
                          <Form.Control
                            as='textarea'
                            rows={3}
                            placeholder='Enter noted item information'
                            name='information'
                            value={defect.selectedFindingInfo}
                            onChange={handleNotedItemChange(index)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col md={12}>
                        <Form.Group controlId={`notedItemImages-${index}`}>
                          <Form.Label>Images (Noted Items)</Form.Label>
                          <input
                            type='file'
                            multiple
                            onChange={(e) => handleNotedItemImageChange(index, e)}
                            style={{
                              display: 'block',
                              width: '100%',
                              padding: '.375rem .75rem',
                              fontSize: '1rem',
                              lineHeight: '1.5',
                              color: '#495057',
                              backgroundColor: '#fff',
                              backgroundClip: 'padding-box',
                              border: '1px solid #ced4da',
                              borderRadius: '.25rem',
                              transition:
                                'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <hr className='my-4 mt-8 mb-8 mx-auto' />
                  </React.Fragment>
                ))}

                <Button onClick={handleAddNotedItem}>
                  <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another Noted
                  Item
                </Button>
                </div>

                <hr className='my-4 mt-8 mb-8  mx-auto' />

                {/* Section G: General Recommendations */}
                <div className="form-section" id="form-section-13">
                <h5
                  className='mt-4'
                  style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px 12px',
                    borderRadius: '4px',
                    display: 'inline-block',
                    marginRight: '10px',
                  }}
                >
                  Additional Comments
                </h5>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId='additionalComments'>
                      <Form.Label>Recommendations</Form.Label>
                      <Form.Control
                        as='textarea'
                        rows={3}
                        placeholder='Enter general recommendations'
                        name='additionalComments' // Name should match property in state
                        value={formData.additionalComments} // Use formData for consistent state handling
                        onChange={handleChange} // Use a unified change handler
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </div>

                {/* Submit Button */}
                <Row>
                  <Col md={4} className='m-auto'>
                    <Button
                      className='mt-5 '
                      variant='primary'
                      type='submit'
                      onClick={() => setShowAddReportModal(true)}
                    >
                      Submit Report
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
            {/* </Col> */}

            <style>
              {`
    @media print {
      @page {
        margin: 10mm; /* Adjust margins as needed */
      }

      .page-header {
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        text-align: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
        font-size: 12px;
        color: #333;
      }

      .page-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        text-align: center;
        border-top: 1px solid #ddd;
        padding-top: 5px;
        font-size: 12px;
        color: #333;
      }

      .page-number:after {
        content: "Page " counter(page);
      }

      .no-break {
        page-break-inside: avoid;
      }

      .page-break {
        page-break-before: always;
      }

      .page-break-after {
        page-break-after: always;
      }
    }
  `}
            </style>
            

            {/* Preview Section */}
            <div 
            ref={previewRef}
              style={{
                flex: 1,
                overflowY: 'auto',
                // position: 'sticky',
                top: '0',
                height: '100%',
                paddingLeft: '20px',
              }}
            >
            
                {/* Header */}
                <div className='page-header'>A Report Generated By Building Inspection Aus</div>

                {/* Footer */}
                <div className='page-footer'>
                  <span className='page-number'></span>
                </div>
                {/* This is where you will render the form preview */}
                {/* Form Preview Section */}
                <div ref={componentRef} style={reportStyles.receipt}>
                  {/* Header Section */}
                  <div style={reportStyles.header}>
                    <div style={reportStyles.logo}>
                    {profilePic ? (
                          <img src={profilePic} alt='Profile' className='fixed-size-image' style={reportStyles.logoImage}/>
                        ) : (
                          <img src={blankprofilepic} alt='Signature' className='fixed-size-image' style={reportStyles.logoImage}/>
                        )}
                      
                    </div>
                    <div
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <h1>{currenteUserName}'s Inspection Service</h1>
                      <p>Block 207, Gera</p>
                      <p>Hinjewade Phase 2, Pune</p>
                    </div>
                  </div>

                  {/* Basic Information Section */}
                  <div
                    className='section no-break preview-section' id="preview-section-1"
                    style={{
                      marginBottom: '30px',
                      pageBreakInside: 'avoid', // Add this to prevent section from breaking
                    }}
                  >
                    
                    <h2 style={reportStyles.sectionTitle}>Basic Information</h2>
                    <div
                      style={{
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fafafa',
                        marginTop: '10px',
                        pageBreakInside: 'avoid', // Add this to prevent details from breaking
                      }}
                    >
                      <p>
                        <strong>Inspection Date:</strong>{' '}
                        {formData.inspectionDate?.toLocaleDateString()}
                      </p>
                      <p>
                        <strong>Property Address:</strong> {formData.propertyAddress}
                      </p>
                      <p>
                        <strong>City:</strong> {formData.city}
                      </p>
                      <p>
                        <strong>State:</strong> {formData.state}
                      </p>
                      <p>
                        <strong>Zipcode:</strong> {formData.zipcode}
                      </p>
                    </div>
                  </div>

                  {/* Parties and Company Information Section */}
                  <div
                    className='section no-break preview-section' id="preview-section-2"
                    style={{
                      marginBottom: '30px',
                      pageBreakInside: 'avoid', // Add this to prevent section from breaking
                    }}
                  >
                    <h2 style={reportStyles.sectionTitle}>Parties and Company Information</h2>
                    <div
                      style={{
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fafafa',
                        marginTop: '10px',
                        pageBreakInside: 'avoid', // Add this to prevent details from breaking
                      }}
                    >
                      <h3>Client Details</h3>
                      <p>
                        <strong>Client Name:</strong> {formData.clientName}
                      </p>
                      <p>
                        <strong>Principal Name:</strong> {formData.principalName}
                      </p>
                      <p>
                        <strong>Job Address:</strong> {formData.jobAddress}
                      </p>
                      <p>
                        <strong>Client Email:</strong> {formData.clientEmail}
                      </p>
                      <p>
                        <strong>Client Phone Number:</strong> {formData.clientPhoneNumber}
                      </p>
                    </div>
                    <div className=' preview-section' id="preview-section-3"
                      style={{
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fafafa',
                        marginTop: '10px',
                        pageBreakInside: 'avoid', // Add this to prevent details from breaking
                      }}
                    >
                      <h3>Company Details</h3>
                      <p>
                        <strong>Company Name:</strong> {formData.companyName}
                      </p>
                      <p>
                        <strong>Company Address:</strong> {formData.companyAddress}
                      </p>
                      <p>
                        <strong>Company Postcode:</strong> {formData.companyPostcode}
                      </p>
                      <p>
                        <strong>Company Email:</strong> {formData.companyEmail}
                      </p>
                      <p>
                        <strong>Company Contact Numbers:</strong> {formData.companyContactNumbers}
                      </p>
                      <p>
                        <strong>Consultant:</strong> {formData.consultant}
                      </p>
                    </div>
                  </div>

                  {/* Inspection Summary Section */}
                  <div
                    className='section no-break preview-section' id="preview-section-4"
                    style={{
                      marginBottom: '30px',
                      pageBreakInside: 'avoid', // Add this to prevent section from breaking
                    }}
                  >
                    <h2 style={reportStyles.sectionTitle}>
                      Section A: Results of Inspection - Summary
                    </h2>
                    <div
                      style={{
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fafafa',
                        marginTop: '10px',
                        pageBreakInside: 'avoid', // Add this to prevent details from breaking
                      }}
                    >
                      <label style={reportStyles.checkbox}>
                        <input type='checkbox' checked={!!formData.safetyHazard} readOnly /> Safety
                        Hazard
                      </label>
                      <label style={reportStyles.checkbox}>
                        <input type='checkbox' checked={!!formData.majorDefect} readOnly /> Major
                        Defect
                      </label>
                      <label style={reportStyles.checkbox}>
                        <input type='checkbox' checked={!!formData.minorDefect} readOnly /> Minor
                        Defect
                      </label>
                      <p>
                        <strong>Overall Condition:</strong> {formData.overallCondition}
                      </p>
                    </div>
                  </div>

                  {/* Property Description Section */}
                  <div
                    className='section no-break preview-section' id="preview-section-5"
                    style={{
                      marginBottom: '30px',
                      pageBreakInside: 'avoid', // Add this to prevent section from breaking
                    }}
                  >
                    <h2 style={reportStyles.sectionTitle}>
                      Section B: General Description of the Property
                    </h2>
                    <div
                      style={{
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fafafa',
                        marginTop: '10px',
                        pageBreakInside: 'avoid', // Add this to prevent details from breaking
                      }}
                    >
                      <p>
                        <strong>Building Type:</strong> {formData.buildingType + ', '}
                      </p>
                      <label style={reportStyles.checkbox}>
                        <strong>Company or Strata Title: </strong>
                        <input type='checkbox' checked={!!formData.companyOrStrataTitle} readOnly />
                      </label>

                      <p>
                        <strong>Floor:</strong> {formData.floor}
                      </p>
                      <p>
                        <strong>Furnished:</strong> {formData.furnished}
                      </p>
                      <p>
                        <strong>Number of Bedrooms:</strong> {formData.numberOfBedrooms}
                      </p>
                      <p>
                        <strong>Occupied:</strong> {formData.occupied}
                      </p>
                      <p>
                        <strong>Orientation:</strong> {formData.orientation}
                      </p>
                      <p>
                        <strong>Other Building Elements:</strong> {formData.otherBuildingElements}
                      </p>
                      <p>
                        <strong>Other Timber Building Elements:</strong>{' '}
                        {formData.otherTimberBuildingElements}
                      </p>
                      <p>
                        <strong>Roof:</strong> {formData.roof}
                      </p>
                      <p>
                        <strong>Storeys:</strong> {formData.storeys}
                      </p>
                      <p>
                        <strong>Walls:</strong> {formData.walls}
                      </p>
                      <p>
                        <strong>Weather:</strong> {formData.weather}
                      </p>
                    </div>
                  </div>

                  {/* Accessibility Section */}
                  <div
                    className='section no-break preview-section' id="preview-section-6"
                    style={{
                      marginBottom: '30px',
                      pageBreakInside: 'avoid', // Add this to prevent section from breaking
                    }}
                  >
                    <h2 style={reportStyles.sectionTitle}>Section C: Accessibility</h2>
                    <div
                      style={{
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fafafa',
                        marginTop: '10px',
                        pageBreakInside: 'avoid', // Add this to prevent details from breaking
                      }}
                    >
                      <p>
                        <strong>Areas Inspected:</strong> {formData.areasInspected}
                      </p>
                      <p>
                        <strong>Inaccessible Areas:</strong> {formData.inaccessibleAreas}
                      </p>
                      <p>
                        <strong>Obstructions and Limitations:</strong>{' '}
                        {formData.obstructionsOrLimitations}
                      </p>
                      <p>
                        <strong>Undetected Defect Risk:</strong> {formData.riskOfUndetectedDefects}
                      </p>
                    </div>
                  </div>

                  {/* Significant Items Section */}
                  <div>
                    <div
                      className='section no-break' 
                      style={{
                        marginBottom: '30px',
                        pageBreakInside: 'avoid', // Add this to prevent section from breaking
                      }}
                    >
                      <h2 style={reportStyles.sectionTitle}>Section D: Significant Items</h2>
                    </div>
                    <div
                      style={{
                        padding: '20px',
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#fafafa',
                        marginTop: '10px',
                        pageBreakInside: 'avoid', // Add this to prevent details from breaking
                      }}
                    >
                      {/* Safety Hazards */}
                      {safetyHazards.length > 0 && (
                        <div
                          className='section no-break preview-section' id="preview-section-7"
                          style={{
                            marginBottom: '30px',
                            pageBreakInside: 'avoid', // Add this to prevent section from breaking
                          }}
                        >
                          <h3>D1: Safety Hazards</h3>
                          {safetyHazards.map((defect, index) => (
                            <div
                              key={`safetyHazard-${index}`}
                              style={{
                                marginBottom: '15px',
                                padding: '10px',
                                border: '1px solid #eee',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
                              }}
                            >
                              <p>
                                <span style={reportStyles.fieldLabel}>Type:</span> {defect.type}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Building:</span>{' '}
                                {defect.building}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Location:</span>{' '}
                                {defect.location}
                              </p>
                              {/* <p>
                                <span style={reportStyles.fieldLabel}>Finding:</span>{' '}
                                {defect.finding}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Information:</span>
                                <p>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: defect.information.replace(/\n/g, '<br />'),
                                    }}
                                  />
                                </p>
                              </p> */}
                              
{/* <p>
  <span style={reportStyles.fieldLabel}>Category of Finding:</span>{' '}
  {categories.find((cat) => cat.finding_cat_id === selectedCategory)?.cat_name || 'N/A'}
</p>
<p>
  <span style={reportStyles.fieldLabel}>Type of Finding:</span>{' '}
  {filteredTypes.find((type) => type.finding_type_id === defect.type)?.finding_type_name || 'N/A'}
</p> */}
<p>
  <span style={reportStyles.fieldLabel}>Findings:</span>
  {selectedFindings && selectedFindings.length > 0
    ? selectedFindings.map((finding, index) => (
        <span key={index}>
          {finding.label}
          {index < selectedFindings.length - 1 ? ', ' : ''}
        </span>
      ))
    : 'N/A'}
</p>
<p>
  <span style={reportStyles.fieldLabel}>Information:</span>
  <span
    dangerouslySetInnerHTML={{
      __html: selectedFindingInfo.replace(/\n/g, '<br /> <br />'),
    }}
  />
</p>

{/* {defect.images?.length > 0 && (
  <div
    style={{
      maxWidth: '900px',
      margin: '10px auto',
      padding: '20px',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '10px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    }}
  >
    {defect.images.map((image, imgIndex) => (
      <div
        key={imgIndex}
        style={{
          width: '31%', // Ensures three images per row
          height: 'calc((9 / 6) * 100%)', // This keeps the 9:6 aspect ratio
          marginBottom: '3%',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <img
          src={image}
          alt={`Safety Hazard Image ${imgIndex + 1}`}
          style={{
            width: '100%', // This ensures the image fills the width of its container
            height: '100%', // Ensures the height fills the container
            objectFit: 'cover', // Crops and fits the image while maintaining its aspect ratio
            borderRadius: '8px',
          }}
        />
      </div>
    ))}
  </div>
)} */}

{defect.images?.length > 0 && (
  <div
    style={{
      maxWidth: '900px', // Container width (for this example)
      margin: '10px auto',
      padding: '20px',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '10px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      flexWrap: 'wrap', // Allow multiple images to wrap into rows
      justifyContent: 'space-between', // Equal spacing between images
    }}
  >
    {defect.images.map((image, imgIndex) => (
      <div
        key={imgIndex}
        style={{
          width: '31%', // 31% width for each image (so 3 images fit per row)
          aspectRatio: '9 / 6', // Maintain the 9:6 aspect ratio
          position: 'relative', // For positioning the image correctly
          marginBottom: '3%',
          overflow: 'hidden', // Ensures excess is cropped
          borderRadius: '8px',
        }}
      >
        <img
          src={image}
          alt={`Safety Hazard Image ${imgIndex + 1}`}
          style={{
            width: '100%', // Ensure image fills the entire container width (31%)
            height: '100%', // Ensure the height is relative to the width, maintaining the 9:6 ratio
            objectFit: 'cover', // Crop the image if it doesn't match the aspect ratio
          }}
        />
        <button 
  onClick={() => handleRemoveImage(index, imgIndex)}
  style={{
    position: 'absolute',
    top: '5px',
    right: '5px',
    background: 'rgba(255, 0, 0, 0.8)', // Slightly darker for better visibility
    border: 'none',
    color: '#fff',
    padding: '3px 8px', // Slightly bigger padding for better touch targets
    borderRadius: '20%', // Keeps the round shape
    cursor: 'pointer',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.15)', // Soft shadow for a modern look
    transition: 'background 0.3s, transform 0.2s', // Smooth transition effects
  }}
  onMouseOver={(e) => e.currentTarget.style.background = 'rgba(255, 0, 0, 0.9)'}
  onMouseOut={(e) => e.currentTarget.style.background = 'rgba(255, 0, 0, 0.8)'}
  onMouseDown={(e) => e.currentTarget.style.transform = 'scale(0.95)'}
  onMouseUp={(e) => e.currentTarget.style.transform = 'scale(1)'}
>
  &times;
</button>

      </div>
    ))}
  </div>
)}





                            </div>
                          ))}
                        </div>
                      )}

                      {/* Major Defects */}
                      {majorDefects.length > 0 && (
                        <div
                          className='section no-break preview-section' id="preview-section-8"
                          style={{
                            marginBottom: '30px',
                            pageBreakInside: 'avoid', // Add this to prevent section from breaking
                          }}
                        >
                          <h3>D2: Major Defects</h3>
                          {majorDefects.map((defect, index) => (
                            <div
                              key={`majorDefect-${index}`}
                              style={{
                                marginBottom: '15px',
                                padding: '10px',
                                border: '1px solid #eee',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
                              }}
                            >
                              <p>
                                <span style={reportStyles.fieldLabel}>Type:</span> {defect.type}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Building:</span>{' '}
                                {defect.building}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Location:</span>{' '}
                                {defect.location}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Finding:</span>{' '}
                                {defect.selectedFindings}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Information:</span>{' '}
                                {defect.selectedFindingInfo}
                              </p>
                              {defect.images?.length > 0 && (
                                <div
                                  style={{
                                    maxWidth: '900px',
                                    margin: '10px auto',
                                    padding: '20px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #ddd',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                                  }}
                                >
                                  {defect.images.map((image, imgIndex) => (
                                    <img
                                      key={imgIndex}
                                      src={image}
                                      alt={`Major Defect Image ${imgIndex + 1}`}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Minor Defects */}
                      {minorDefects.length > 0 && (
                        <div
                          className='section no-break preview-section' id="preview-section-9"
                          style={{
                            marginBottom: '30px',
                            pageBreakInside: 'avoid', // Add this to prevent section from breaking
                          }}
                        >
                          <h3>D3: Minor Defects</h3>
                          {minorDefects.map((defect, index) => (
                            <div
                              key={`minorDefect-${index}`}
                              style={{
                                marginBottom: '15px',
                                padding: '10px',
                                border: '1px solid #eee',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
                              }}
                            >
                              <p>
                                <span style={reportStyles.fieldLabel}>Type:</span> {defect.type}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Building:</span>{' '}
                                {defect.building}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Location:</span>{' '}
                                {defect.location}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Finding:</span>{' '}
                                {defect.selectedFindings}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Information:</span>{' '}
                                {defect.selectedFindingInfo}
                              </p>
                              {defect.images?.length > 0 && (
                                <div
                                  style={{
                                    maxWidth: '900px',
                                    margin: '10px auto',
                                    padding: '20px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #ddd',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                                  }}
                                >
                                  {defect.images.map((image, imgIndex) => (
                                    <img
                                      key={imgIndex}
                                      src={image}
                                      alt={`Minor Defect Image ${imgIndex + 1}`}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Further Inspections */}
                      {furtherInspections.length > 0 && (
                        <div
                          className='section no-break preview-section' id="preview-section-10"
                          style={{
                            marginBottom: '30px',
                            pageBreakInside: 'avoid', // Add this to prevent section from breaking
                          }}
                        >
                          <h3>D4: Further Inspections</h3>
                          {furtherInspections.map((defect, index) => (
                            <div
                              key={`furtherInspection-${index}`}
                              style={{
                                marginBottom: '15px',
                                padding: '10px',
                                border: '1px solid #eee',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
                              }}
                            >
                              <p>
                                <span style={reportStyles.fieldLabel}>Type:</span> {defect.type}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Building:</span>{' '}
                                {defect.building}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Location:</span>{' '}
                                {defect.location}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Finding:</span>{' '}
                                {defect.selectedFindings}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Information:</span>{' '}
                                {defect.selectedFindingInfo}
                              </p>
                              {defect.images?.length > 0 && (
                                <div
                                  style={{
                                    maxWidth: '900px',
                                    margin: '10px auto',
                                    padding: '20px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #ddd',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                                  }}
                                >
                                  {defect.images.map((image, imgIndex) => (
                                    <img
                                      key={imgIndex}
                                      src={image}
                                      alt={`Further Inspection Image ${imgIndex + 1}`}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Comments Field Preview */}
                      <div id="preview-section-1" className=' preview-section'>
                      <h3>D5: Recommendations: </h3>
                      {formData.recommendations && (
                        <div
                          style={{
                            marginBottom: '15px',
                            padding: '20px',
                            border: '1px solid #eee',
                            borderRadius: '5px',
                            backgroundColor: '#f9f9f9',
                            pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
                          }}
                        >
                          <p> <strong>Recommendations:</strong> {formData.recommendations}</p>
                        </div>
                      )}
                      </div>

                      {/* Noted Items */}
                      {notedItems.length > 0 && (
                        <div
                          className='section no-break preview-section' id="preview-section-12"
                          style={{
                            marginBottom: '30px',
                            pageBreakInside: 'avoid', // Add this to prevent section from breaking
                          }}
                        >
                          <h3>D6: Noted Items</h3>
                          {notedItems.map((defect, index) => (
                            <div
                              key={`notedItem-${index}`}
                              style={{
                                marginBottom: '15px',
                                padding: '10px',
                                border: '1px solid #eee',
                                borderRadius: '5px',
                                backgroundColor: '#f9f9f9',
                                pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
                              }}
                            >
                              <p>
                                <span style={reportStyles.fieldLabel}>Type:</span> {defect.type}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Building:</span>{' '}
                                {defect.building}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Location:</span>{' '}
                                {defect.location}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Finding:</span>{' '}
                                {defect.selectedFindings}
                              </p>
                              <p>
                                <span style={reportStyles.fieldLabel}>Information:</span>{' '}
                                {defect.selectedFindingInfo}
                              </p>
                              {defect.images?.length > 0 && (
                                <div
                                  style={{
                                    maxWidth: '900px',
                                    margin: '10px auto',
                                    padding: '20px',
                                    backgroundColor: '#fff',
                                    border: '1px solid #ddd',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                                  }}
                                >
                                  {defect.images.map((image, imgIndex) => (
                                    <img
                                      key={imgIndex}
                                      src={image}
                                      alt={`Noted Item Image ${imgIndex + 1}`}
                                    />
                                  ))}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {/* Fallback for no defects */}
                      {safetyHazards.length === 0 &&
                        majorDefects.length === 0 &&
                        minorDefects.length === 0 &&
                        furtherInspections.length === 0 &&
                        notedItems.length === 0 && <p>No defects available.</p>}
                    </div>
                  </div>
                </div>

                {/* Additional Comments Section */}
                <div
                  className='section no-break preview-section' id="preview-section-13"
                  style={{
                    marginBottom: '30px',
                    pageBreakInside: 'avoid', // Add this to prevent section from breaking
                  }}
                >
                  <h2 style={reportStyles.sectionTitle}>Additional Comments</h2>
                  <div
                    style={{
                      padding: '20px',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      backgroundColor: '#fafafa',
                      marginTop: '10px',
                      pageBreakInside: 'avoid', // Add this to prevent details from breaking
                    }}
                  >
                    <p>
                      <strong>Conclusion:</strong> {formData.additionalComments}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    textAlign: 'center',
                    fontSize: '14px',
                    color: '#777',
                    marginTop: '30px',
                    borderTop: '1px solid #ddd',
                    paddingTop: '15px',
                  }}
                >
                  &copy; 2024 Building Inspection Aus. All rights reserved.
                </div>
              
            </div>
            {/* </Col> */}
            {/* </Row> */}
          </Modal.Body>
        </Modal>
      </div>

      <Modal show={showModal2} onHide={handleCloseModal2} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Your Custom Findings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            className='btn btn-success btn-sm d-flex align-items-center mb-5'
            onClick={handleOpenAddModal}
            style={{marginLeft: 'auto', padding: '0.375rem 0.75rem'}}
          >
            <span className='me-2'>Create new TYPE(collection)</span>
            <i className='bi bi-plus-circle'></i>
          </Button>
          {findingTypes.length > 0 ? (
            <Form>
              <Row>
                {findingTypes
                  .filter((findingType) => findingType.findingCategory.finding_cat_id === 2)
                  .map((findingType) => (
                    <Col key={findingType.finding_type_id} md={6} className='mb-3'>
                      <Form.Group>
                        <Form.Control
                          type='text'
                          value={findingType.finding_type_name}
                          readOnly
                          className='bg-light'
                          onClick={() => handleFindingTypeClick(findingType.finding_type_id)}
                          style={{cursor: 'pointer'}} // Make it look clickable
                        />
                      </Form.Group>
                    </Col>
                  ))}
              </Row>
            </Form>
          ) : (
            <p>Opps..! No finding types available. You can create one using above button.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseModal2}>
            Close
          </Button>
          <Button variant='primary' onClick={handleCloseModal2}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Subtype Modal */}
      <Modal
        show={showSubTypeModal}
        onHide={handleCloseSubTypeModal}
        centered
        size='lg'
        style={{
          backgroundColor: showSubTypeModal ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Your Findings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Button
            className='btn btn-success btn-sm d-flex align-items-center mb-5'
            onClick={handleOpenAddSubTypeModal}
            style={{marginLeft: 'auto', padding: '0.375rem 0.75rem'}}
          >
            <span className='me-2'> Add a defect finding</span>
            <i className='bi bi-plus-circle'></i>
          </Button>

          {subTypes.length > 0 ? (
            <Table striped bordered hover responsive>
              <thead className='thead-dark text-left mb-3'>
                <tr>
                  <th style={{width: '30%'}}>
                    {' '}
                    <strong>Finding Name</strong>
                  </th>
                  <th style={{width: '70%'}}>
                    {' '}
                    <strong>Information</strong>
                  </th>
                </tr>
              </thead>
              <tbody className='text-left'>
                {subTypes.map((subType) => (
                  <tr key={subType.finding_id}>
                    <td style={{width: '30%'}}>{subType.finding_name}</td>
                    <td style={{width: '70%'}}>{subType.information}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>We guess its a fresh TYPE ...No findings available for this finding type.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleCloseSubTypeModal}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showAddSubTypeModal}
        onHide={handleCloseAddSubTypeModal}
        centered
        size='lg'
        style={{
          backgroundColor: showAddSubTypeModal ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Finding Subtype</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label>Finding Name</Form.Label>
              <Form.Control
                type='text'
                placeholder='Enter Finding Name'
                value={newSubTypeName}
                onChange={(e) => setNewSubTypeName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Information</Form.Label>
              <Form.Control
                as='textarea'
                rows={3}
                placeholder='Enter Information'
                value={newSubTypeInfo}
                onChange={(e) => setNewSubTypeInfo(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseAddSubTypeModal}>
            Close
          </Button>
          <Button variant='primary' onClick={handleAddSubType}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Add Findings Modal */}
      <Modal
        show={showAddModal3}
        onHide={handleCloseAddModal}
        centered
        className='modal-dialog-centered'
        style={{
          backgroundColor: showAddModal3 ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Finding type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label className='fw-bold'>Finding Type Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Finding Type Name'
              value={newFindingTypeName}
              onChange={(e) => setNewFindingTypeName(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleAddFinding}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>

        {/* Modal component */}
        <AddExistingReportModal show={showExistingReportModal} handleClose={handleCloseExistingReportModal} />
    </>
  )
}

export {InspectionsReports}
