import React, { useEffect, useState } from 'react'; 
import { useAuth } from '../../../../../app/modules/auth';
import { getSidebarData } from '../../../../../services/sidebarService';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';
import { Menu } from './core/sidebar.types';

const SidebarMenuMain = () => {
  const [mergedSidebar, setMergedSidebar] = useState<Menu[]>([]);
  const { currentUser } = useAuth();

  const mergeMenus = (roleBasedMenus: Menu[], userBasedMenus: Menu[] | null) => {
    const merged = [...roleBasedMenus];

    if (userBasedMenus && userBasedMenus.length > 0) {
      userBasedMenus.forEach((userMenu) => {
        const existingMenuIndex = merged.findIndex((menu) => menu.menu_id === userMenu.menu_id);

        if (existingMenuIndex !== -1) {
          // Merge submenus if the menu already exists
          const roleSubmenus = merged[existingMenuIndex].submenus;
          const userSubmenus = userMenu.submenus;

          // Combine submenus and remove duplicates
          const mergedSubmenus = [
            ...roleSubmenus,
            ...userSubmenus.filter(
              (userSubmenu) => !roleSubmenus.some((roleSubmenu) => roleSubmenu.submenu_id === userSubmenu.submenu_id)
            ),
          ];

          // Update the menu with merged submenus
          merged[existingMenuIndex].submenus = mergedSubmenus;
        } else {
          // Add the user menu if it doesn't exist in role-based menus
          merged.push(userMenu);
        }
      });
    }

    return merged;
  };

  useEffect(() => {
    const fetchSidebar = async () => {
      if (currentUser?.user_id) {
        try {
          const { roleBasedSidebar, userBasedSidebar } = await getSidebarData(currentUser.user_id);
          const merged = mergeMenus(roleBasedSidebar, userBasedSidebar);
          setMergedSidebar(merged);
        } catch (error) {
          console.error('Error fetching sidebar data:', error);
          setMergedSidebar([]);  // Always render the sidebar even if there’s an error
        }
      }
    };

    fetchSidebar();
  }, [currentUser]);

  return (
    <div>
      {mergedSidebar.length > 0 ? (
        mergedSidebar.map((menu: Menu) => (
          <SidebarMenuItemWithSub
            key={menu.menu_id}
            to={menu.path}
            title={menu.name}
            icon="/media/icons/duotune/general/gen025.svg"
          >
            {menu.submenus.map((submenu) => (
              <SidebarMenuItem
                key={submenu.submenu_id}
                to={submenu.path}
                title={submenu.name}
                hasBullet={true}
              />
            ))}
          </SidebarMenuItemWithSub>
        ))
      ) : (
        <p>No sidebar items available.</p>
      )}
    </div>
  );
};

export { SidebarMenuMain };
