import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { pdfjs } from 'react-pdf';
import axios from 'axios';
import { useAuth } from '../../auth';
import { Formik, Form, Field } from 'formik';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js;`

interface PdfModalProps {
  show: boolean;
  handleClose: () => void;
  title: string;
  pdfUrl: string;
  onAccept: (title: string, accepted: boolean) => void;
}

const PdfModal: React.FC<PdfModalProps> = ({ show, handleClose, title, pdfUrl, onAccept }) => {
  const auth = useAuth();
  const currentUserId = auth.currentUser?.user_id;

  const [isReadonly, setIsReadonly] = useState({
    terms: false,
    cancel_policy: false,
    code_conduct: false,
  });

  const [fetchedData, setFetchedData] = useState<any>({}); // Initialize as an empty object

  

  const initialValues = {
    acceptedTerms: fetchedData?.terms || false,
    acceptedCancelPolicy: fetchedData?.cancel_policy || false,
    acceptedCodeConduct: fetchedData?.code_conduct || false,
  };

  const handleAcceptSubmit = async (
    values: { acceptedTerms: boolean; acceptedCancelPolicy: boolean; acceptedCodeConduct: boolean },
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    if (currentUserId) {
      const payload: any = {};

      if (values.acceptedTerms) payload.terms = true;
      if (values.acceptedCancelPolicy) payload.cancel_policy = true;
      if (values.acceptedCodeConduct) payload.code_conduct = true;

      console.log('Payload : ',payload)
      try {
        await axios.put(`https://api.inspection.payplatter.in/api/inspectors/profile/${currentUserId}`, payload);
        onAccept(title, true);
        handleClose();
        toast.success('Successfully accepted.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } catch (error) {
        console.error('Error submitting checkbox values:', error);
        toast.error('Failed to submit. Please try again.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } finally {
        setSubmitting(false);
      }
    }
  };


  useEffect(() => {
    const fetchCheckboxValues = async () => {
      if (currentUserId) {
        try {
          const response = await axios.get(`https://api.inspection.payplatter.in/api/inspectors/profile/${currentUserId}`);
          const { terms, cancel_policy, code_conduct } = response.data;

          setFetchedData(response.data);

          setIsReadonly({
            terms: terms,
            cancel_policy: cancel_policy,
            code_conduct: code_conduct,
          });
        } catch (error) {
          console.error('Error fetching checkbox values:', error);
        }
      }
    };
    fetchCheckboxValues();
  }, [currentUserId,handleAcceptSubmit]);

  return (
    <Modal show={show} onHide={handleClose} dialogClassName='modal-lg'>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={pdfUrl + '#toolbar=0&navpanes=0'}
          width='750'
          height='600'
          style={{ border: 'none', backgroundColor: 'white' }}
          title={title}
        />
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleAcceptSubmit}
        >
          {({ values, handleChange, handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              {title === 'Terms & Conditions' && (
                <>
                  <div className='form-check mt-3'>
                    <Field
                      type='checkbox'
                      className='form-check-input'
                      id='acceptTermsCheckbox'
                      name='acceptedTerms'
                      checked={isReadonly.terms || values.acceptedTerms}
                      onChange={handleChange}
                      disabled={isReadonly.terms}
                    />
                    <label className='form-check-label' htmlFor='acceptTermsCheckbox'>
                      I accept the Terms & Conditions
                    </label>
                  </div>
                  {!isReadonly.terms && (
                    <Modal.Footer>
                      <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                        Submit
                      </button>
                    </Modal.Footer>
                  )}
                </>
              )}

              {title === 'Cancellation Policy' && (
                <>
                  <div className='form-check mt-3'>
                    <Field
                      type='checkbox'
                      className='form-check-input'
                      id='acceptCancelPolicyCheckbox'
                      name='acceptedCancelPolicy'
                      checked={isReadonly.cancel_policy || values.acceptedCancelPolicy}
                      onChange={handleChange}
                      disabled={isReadonly.cancel_policy}
                    />
                    <label className='form-check-label' htmlFor='acceptCancelPolicyCheckbox'>
                      I accept the Cancellation Policy
                    </label>
                  </div>
                  {!isReadonly.cancel_policy && (
                    <Modal.Footer>
                      <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                        Submit
                      </button>
                    </Modal.Footer>
                  )}
                </>
              )}

              {title === 'Code of Conduct' && (
                <>
                  <div className='form-check mt-3'>
                    <Field
                      type='checkbox'
                      className='form-check-input'
                      id='acceptCodeConductCheckbox'
                      name='acceptedCodeConduct'
                      checked={isReadonly.code_conduct || values.acceptedCodeConduct}
                      onChange={handleChange}
                      disabled={isReadonly.code_conduct}
                    />
                    <label className='form-check-label' htmlFor='acceptCodeConductCheckbox'>
                      I accept the Code of Conduct
                    </label>
                  </div>
                  {!isReadonly.code_conduct && (
                    <Modal.Footer>
                      <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
                        Submit
                      </button>
                    </Modal.Footer>
                  )}
                </>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default PdfModal;







