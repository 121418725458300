


// import React, { useState, useEffect } from 'react';
// import { Icon } from '@iconify/react';
// import { useAuth } from '../../auth'; // Assuming your useAuth is in this path

// interface PricingRange {
//   pricing_id: number;
//   min_bedrooms: number;
//   max_bedrooms: number;
//   price: string | number;
// }

// const BedroomPrice: React.FC = () => {
//   const auth = useAuth();
//   const currentUserId = auth.currentUser?.user_id;

//   const [serviceName, setServiceName] = useState<string>('');
//   const [description, setDescription] = useState<string>('');
//   const [pricePerBathroom, setPricePerBathroom] = useState<number | ''>(''); // As number
//   const [pricePerGarage, setPricePerGarage] = useState<number | ''>(''); // As number
//   const [pricingRanges, setPricingRanges] = useState<PricingRange[]>([]);
//   const [editingIndex, setEditingIndex] = useState<number | null>(null); // For editing pricing range
//   const [inspectorId, setInspectorId] = useState<string | null>(null);

//   useEffect(() => {
//     fetchInspectorProfile();
//   }, [currentUserId]);

//   useEffect(() => {
//     const savedServiceData = localStorage.getItem('serviceData');
//     if (savedServiceData) {
//       const parsedData = JSON.parse(savedServiceData);
//       setServiceName(parsedData.service_name || '');
//       setDescription(parsedData.description || '');
//       setPricePerBathroom(parseFloat(parsedData.price_per_bathroom) || 0); // Parse as number
//       setPricePerGarage(parseFloat(parsedData.price_per_garage) || 0); // Parse as number
//       setPricingRanges(parsedData.pricingRanges || []);
//     }
//   }, []);

//   const fetchInspectorProfile = async () => {
//     if (currentUserId) {
//       try {
//         const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/profile/${currentUserId}`);
//         if (response.ok) {
//           const data = await response.json();
//           setInspectorId(data.inspector_id);
//           fetchServiceData(data.inspector_id); // Fetch the inspection service data
//         } else {
//           console.error('Failed to fetch inspector profile:', response.statusText);
//         }
//       } catch (error) {
//         console.error('An error occurred while fetching inspector profile:', error);
//       }
//     } else {
//       console.error('No current user ID available.');
//     }
//   };

//   const fetchServiceData = async (inspectorId: string) => {
//     try {
//       const response = await fetch(`https://api.inspection.payplatter.in/api/inspection-services/inspector/${inspectorId}`);
//       if (response.ok) {
//         const data = await response.json();
//         setServiceName(data.service_name || '');
//         setDescription(data.description || '');
//         setPricePerBathroom(parseFloat(data.price_per_bathroom) || 0); // Ensure number
//         setPricePerGarage(parseFloat(data.price_per_garage) || 0); // Ensure number
//         setPricingRanges(data.pricingRanges || []);

//         // Save the data to localStorage to persist between page refreshes
//         localStorage.setItem('serviceData', JSON.stringify(data));
//       } else {
//         console.error('Failed to fetch service data:', response.statusText);
//       }
//     } catch (error) {
//       console.error('An error occurred while fetching service data:', error);
//     }
//   };

//   const handleServiceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setServiceName(e.target.value);
//   const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);
//   const handlePricePerBathroomChange = (e: React.ChangeEvent<HTMLInputElement>) =>
//     setPricePerBathroom(parseFloat(e.target.value) || 0); // Keep as number
//   const handlePricePerGarageChange = (e: React.ChangeEvent<HTMLInputElement>) =>
//     setPricePerGarage(parseFloat(e.target.value) || 0); // Keep as number

//   const handlePriceChange = (index: number, price: string | number) => {
//     const updatedRanges = [...pricingRanges];
//     updatedRanges[index].price = parseFloat(price as string) || 0; // Convert to number
//     setPricingRanges(updatedRanges);
//   };

//   const handleEdit = (index: number) => setEditingIndex(index);

//   const handleBlur = () => setEditingIndex(null); // Close the input field on blur

//   const handleSubmit = async (e: React.FormEvent) => {
//     e.preventDefault();

//     const payload = {
//       service_name: serviceName || '',
//       description: description || '',
//       price_per_bathroom: pricePerBathroom || 0, // Send as number
//       price_per_garage: pricePerGarage || 0, // Send as number
//       pricingRanges: pricingRanges.map((range) => ({
//         min_bedrooms: range.min_bedrooms,
//         max_bedrooms: range.max_bedrooms,
//         price: parseFloat(range.price as string) || 0, // Ensure price is a number
//       })),
//     };

//     console.log('Payload:', payload); // Log the payload to inspect

//     if (inspectorId) {
//       try {
//         console.log(inspectorId)
//         const response = await fetch(`https://api.inspection.payplatter.in/api/inspection-services/inspector/${inspectorId}`, {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(payload),
//         });

//         if (response.ok) {
//           console.log('Data submitted successfully');
//         } else {
//           const errorText = await response.text();
//           console.error('Failed to submit data:', response.status, errorText);
//         }
//       } catch (error) {
//         console.error('Error submitting data:', error);
//       }
//     } else {
//       console.error('Inspector ID is not available.');
//     }
//   };

//   return (
//     <div className="container mt-5 mb-5">
//       <div className="card shadow-sm">
//         <div className="card-header">
//           <h2 className="card-title">Set Service Pricing</h2>
//         </div>
//         <div className="card-body">
//           <form onSubmit={handleSubmit}>
//             <div className="row g-3">
//               <div className="col-md-6">
//                 <label className="form-label">Service Name</label>
//                 <input
//                   type="text"
//                   value={serviceName}
//                   onChange={handleServiceNameChange}
//                   className="form-control"
//                   placeholder="Enter service name"
//                 />
//               </div>

//               <div className="col-md-6">
//                 <label className="form-label">Description</label>
//                 <input
//                   type="text"
//                   value={description}
//                   onChange={handleDescriptionChange}
//                   className="form-control"
//                   placeholder="Enter description"
//                 />
//               </div>

//               <div className="col-md-6">
//                 <label className="form-label">Price per Bathroom</label>
//                 <input
//                   type="number"
//                   value={pricePerBathroom}
//                   onChange={handlePricePerBathroomChange}
//                   className="form-control"
//                   placeholder="Enter price per bathroom"
//                 />
//               </div>

//               <div className="col-md-6">
//                 <label className="form-label">Price per Garage</label>
//                 <input
//                   type="number"
//                   value={pricePerGarage}
//                   onChange={handlePricePerGarageChange}
//                   className="form-control"
//                   placeholder="Enter price per garage"
//                 />
//               </div>
//             </div>

//             {/* Table to show and edit the pricing ranges */}
//             <div className="table-responsive mt-4">
//               <h4 className="mb-4">Bedroom Price</h4>
//               <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
//                 <thead>
//                   <tr className="fw-bold text-muted">
//                     <th className="min-w-150px">Bedrooms</th>
//                     <th className="min-w-140px">Price</th>
//                     <th className="min-w-80px text-end">Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {pricingRanges.map((range, index) => (
//                     <tr key={range.pricing_id}>
//                       <td>
//                         <span className="text-dark fw-bold text-hover-primary fs-6">{`${range.min_bedrooms}-${range.max_bedrooms}`}</span>
//                       </td>
//                       <td>
//                         {editingIndex === index ? (
//                           <input
//                             type="number"
//                             value={range.price}
//                             onChange={(e) => handlePriceChange(index, e.target.value)}
//                             onBlur={handleBlur}
//                             className="form-control"
//                             style={{ width: '100px' }} // Small input field width
//                             placeholder="Enter price"
//                             autoFocus
//                           />
//                         ) : (
//                           <span className="text-dark fw-bold text-hover-primary d-block fs-6">${range.price}</span>
//                         )}
//                       </td>
//                       <td className="d-flex justify-content-end">
//                         <button
//                           type="button"
//                           className="btn btn-sm"
//                           onClick={() => handleEdit(index)}
//                         >
//                           <Icon icon="flowbite:edit-solid" width="22" height="22" style={{ color: 'gray' }} />
//                         </button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//             </div>

//             <div className="d-flex justify-content-end mt-4">
//               <button type="submit" className="btn btn-primary">
//                 Submit
//               </button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BedroomPrice;



import React, { useState, useEffect } from 'react';
import { Icon } from '@iconify/react';
import { useAuth } from '../../auth'; // Assuming your useAuth is in this path

interface PricingRange {
  pricing_id: number;
  min_bedrooms: number;
  max_bedrooms: number;
  price: string | number;
}

const BedroomPrice: React.FC = () => {
  const auth = useAuth();
  const currentUserId = auth.currentUser?.user_id;

  const [serviceName, setServiceName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [pricePerBathroom, setPricePerBathroom] = useState<number | ''>('');
  const [pricePerGarage, setPricePerGarage] = useState<number | ''>('');
  const [pricingRanges, setPricingRanges] = useState<PricingRange[]>([
    { pricing_id: 1, min_bedrooms: 1, max_bedrooms: 3, price: '' },
    { pricing_id: 2, min_bedrooms: 4, max_bedrooms: 6, price: '' },
    { pricing_id: 3, min_bedrooms: 7, max_bedrooms: 9, price: '' },
    { pricing_id: 4, min_bedrooms: 10, max_bedrooms: 99, price: '' },
  ]);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [inspectorId, setInspectorId] = useState<string | null>(null);

  useEffect(() => {
    fetchInspectorProfile();
  }, [currentUserId]);

  const fetchInspectorProfile = async () => {
    if (currentUserId) {
      try {
        const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/profile/${currentUserId}`);
        if (response.ok) {
          const data = await response.json();
          setInspectorId(data.inspector_id);
          fetchServiceData(data.inspector_id);
        } else {
          console.error('Failed to fetch inspector profile:', response.statusText);
        }
      } catch (error) {
        console.error('An error occurred while fetching inspector profile:', error);
      }
    } else {
      console.error('No current user ID available.');
    }
  };

  const fetchServiceData = async (inspectorId: string) => {
    try {
      const response = await fetch(`https://api.inspection.payplatter.in/api/inspection-services/inspector/${inspectorId}`);
      if (response.ok) {
        const data = await response.json();
        setServiceName(data.service_name || '');
        setDescription(data.description || '');
        setPricePerBathroom(parseFloat(data.price_per_bathroom) || 0);
        setPricePerGarage(parseFloat(data.price_per_garage) || 0);

        const updatedRanges = pricingRanges.map((range, index) => ({
          ...range,
          price: data.pricingRanges[index]?.price || '', // Populate price if exists in fetched data
        }));
        setPricingRanges(updatedRanges);

        localStorage.setItem('serviceData', JSON.stringify(data));
      } else {
        console.error('Failed to fetch service data:', response.statusText);
      }
    } catch (error) {
      console.error('An error occurred while fetching service data:', error);
    }
  };

  const handleServiceNameChange = (e: React.ChangeEvent<HTMLInputElement>) => setServiceName(e.target.value);
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value);
  const handlePricePerBathroomChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPricePerBathroom(parseFloat(e.target.value) || 0);
  const handlePricePerGarageChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPricePerGarage(parseFloat(e.target.value) || 0);

  const handlePriceChange = (index: number, price: string | number) => {
    const updatedRanges = [...pricingRanges];
    updatedRanges[index].price = parseFloat(price as string) || 0;
    setPricingRanges(updatedRanges);
  };

  const handleEdit = (index: number) => setEditingIndex(index);
  const handleBlur = () => setEditingIndex(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const payload = {
      service_name: serviceName || '',
      description: description || '',
      price_per_bathroom: pricePerBathroom || 0,
      price_per_garage: pricePerGarage || 0,
      pricingRanges: pricingRanges.map((range) => ({
        min_bedrooms: range.min_bedrooms,
        max_bedrooms: range.max_bedrooms,
        price: parseFloat(range.price as string) || 0,
      })),
    };

    if (inspectorId) {
      try {
        const response = await fetch(`https://api.inspection.payplatter.in/api/inspection-services/inspector/${inspectorId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          console.log('Data submitted successfully');
        } else {
          const errorText = await response.text();
          console.error('Failed to submit data:', response.status, errorText);
        }
      } catch (error) {
        console.error('Error submitting data:', error);
      }
    } else {
      console.error('Inspector ID is not available.');
    }
  };

  return (
    <div className="container mt-5 mb-5">
      <div className="card shadow-sm">
        <div className="card-header">
          <h2 className="card-title">Set Service Pricing</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="col-md-6">
                <label className="form-label">Service Name</label>
                <input
                  type="text"
                  value={serviceName}
                  onChange={handleServiceNameChange}
                  className="form-control"
                  placeholder="Enter service name"
                />
              </div>

              <div className="col-md-6">
                <label className="form-label">Description</label>
                <input
                  type="text"
                  value={description}
                  onChange={handleDescriptionChange}
                  className="form-control"
                  placeholder="Enter description"
                />
              </div>

              <div className="col-md-6">
                <label className="form-label">Price per Bathroom</label>
                <input
                  type="number"
                  value={pricePerBathroom}
                  onChange={handlePricePerBathroomChange}
                  className="form-control"
                  placeholder="Enter price per bathroom"
                />
              </div>

              <div className="col-md-6">
                <label className="form-label">Price per Garage</label>
                <input
                  type="number"
                  value={pricePerGarage}
                  onChange={handlePricePerGarageChange}
                  className="form-control"
                  placeholder="Enter price per garage"
                />
              </div>
            </div>

            <div className="table-responsive mt-4">
              <h4 className="mb-4">Bedroom Price</h4>
              <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                  <tr className="fw-bold text-muted">
                    <th className="min-w-150px">Bedrooms</th>
                    <th className="min-w-140px">Price</th>
                    <th className="min-w-80px text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {pricingRanges.map((range, index) => (
                    <tr key={range.pricing_id}>
                      <td>
                        <span className="text-dark fw-bold text-hover-primary fs-6">{`${range.min_bedrooms}-${range.max_bedrooms}`}</span>
                      </td>
                      <td>
                        {editingIndex === index ? (
                          <input
                            type="number"
                            value={range.price}
                            onChange={(e) => handlePriceChange(index, e.target.value)}
                            onBlur={handleBlur}
                            className="form-control"
                            style={{ width: '100px' }}
                            placeholder="Enter price"
                            autoFocus
                          />
                        ) : (
                          <span className="text-dark fw-bold text-hover-primary d-block fs-6">${range.price}</span>
                        )}
                      </td>
                      <td className="d-flex justify-content-end">
                        <button
                          type="button"
                          className="btn btn-sm"
                          onClick={() => handleEdit(index)}
                        >
                          <Icon icon="flowbite:edit-solid" width="22" height="22" style={{ color: 'gray' }} />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BedroomPrice;
