

import React, { useState, FormEvent } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useAuth } from '../core/Auth'
import { useNavigate } from 'react-router-dom';

// Define types for form values and errors
interface FormValues {
  firstname: string
  lastname: string
  email: string
  phone_no: string
  password: string
  role: number
  state_id: number
}

interface FormErrors {
  firstname?: string
  lastname?: string
  email?: string
  phone_no?: string
  password?: string
  role?: string
  state_id?: string
}

const statesMapping: { [key: number]: string } = {
  1: 'New South Wales',
  2: 'Victoria',
  3: 'Queensland',
  4: 'Western Australia',
  5: 'South Australia',
  6: 'Tasmania',
  7: 'Australian Capital Territory',
  8: 'Northern Territory',
}

export function Registration() {
  const [loading, setLoading] = useState<boolean>(false)
  const [formValues, setFormValues] = useState<FormValues>({
    firstname: '',
    lastname: '',
    email: '',
    phone_no: '',
    password: '',
    role: 3, 
    state_id: 1, 
  })
  const [errors, setErrors] = useState<FormErrors>({})
  const [status, setStatus] = useState<string>('')
  const { saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()

  // Validate form values
  const validate = (): FormErrors => {
    const errors: FormErrors = {}
    if (!formValues.firstname) errors.firstname = 'First name is required'
    if (!formValues.lastname) errors.lastname = 'Last name is required'
    if (!formValues.email) errors.email = 'Email is required'
    if (!formValues.phone_no) errors.phone_no = 'Phone number is required'
    if (!formValues.password) errors.password = 'Password is required'
    if (!formValues.state_id) errors.state_id = 'State is required'
    // Add further validation rules as needed
    return errors
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors({});
    setStatus('');
  
    const validationErrors = validate();
    if (Object.keys(validationErrors).length) {
      setErrors(validationErrors);
      return;
    }
  
    setLoading(true);
  
    try {
      const userData = {
        firstname: formValues.firstname,
        lastname: formValues.lastname,
        email: formValues.email,
        phone_no: formValues.phone_no,
        role: formValues.role,
        state_id: formValues.state_id,
        password: formValues.password,
      };
  
      const response = await fetch('https://api.inspection.payplatter.in/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });
  
      if (!response.ok) {
        throw new Error('Failed to submit registration data');
      }
  
      const data = await response.json();
      console.log(data);
      navigate('/auth/login');
  
      // Optionally, redirect or show a success message
    } catch (error) {
      console.error(error);
      saveAuth(undefined);
      setStatus('The registration details are incorrect or an error occurred');
    } finally {
      setLoading(false);
    }
  };
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value, type } = event.target as HTMLInputElement | HTMLSelectElement;
  
    if (type === 'checkbox') {
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: (event.target as HTMLInputElement).checked
      }));
    } else {
      setFormValues(prevValues => ({
        ...prevValues,
        [name]: name === 'role' || name === 'state_id' ? Number(value) : value
      }));
    }
  };
  
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const role = Number(event.target.value);
  
    setFormValues(prevValues => ({
      ...prevValues,
      role
    }));
  };
  

  return (
    <form
      className='form w-100'
      onSubmit={handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{status}</div>
        </div>
      )}

      <div className='mb-10 bg-light-info p-6 rounded'>
        <div className='text-primary'>
          Signup with your <strong>Building-Inspection</strong> account.
        </div>
      </div>


      <div className='d-flex justify-content-between align-items-center'>
  {/* FIRST DIV */}
  <div className='mb-10'>
    <label className='form-label fs-6 fw-bolder text-light'>Select Role</label>
    <div className='d-flex gap-3 mt-2'>
      <div className='form-check'>
        <input
          className='form-check-input'
          type='radio'
          id='role-buyer'
          name='role'
          value={3}
          checked={formValues.role === 3}
          onChange={handleRoleChange}
        />
        <label
          className='form-check-label fw-bold text-white'
          htmlFor='role-buyer'
        >
          Buyer
        </label>
      </div>
      <div className='form-check'>
        <input
          className='form-check-input'
          type='radio'
          id='role-seller'
          name='role'
          value={4}
          checked={formValues.role === 4}
          onChange={handleRoleChange}
        />
        <label
          className='form-check-label fw-bold text-white'
          htmlFor='role-seller'
        >
          Seller
        </label>
      </div>
    </div>
  </div>

  {/* SECOND DIV */}
  <div className='mb-10'>
    <label className='form-label fs-6 fw-bolder text-light'>Register as Inspector</label>
    <div className='d-flex gap-3 mt-2'>
      <div className='form-check'>
        <input
          className='form-check-input'
          type='radio'
          id='role-inspector'
          name='role'
          value={2}
          checked={formValues.role === 2}
          onChange={handleRoleChange}
        />
        <label
          className='form-check-label fw-bold text-white'
          htmlFor='role-inspector'
        >
          Inspector
        </label>
      </div>
    </div>
  </div>
</div>






      <div className='d-flex mb-8'>
        <div className='me-2 w-50'>
          <label className='form-label fs-6 fw-bolder text-light'>First Name</label>
          <input
            placeholder='First Name'
            name='firstname'
            value={formValues.firstname}
            onChange={handleChange}
            style={{backgroundColor: "#E8F0FE"}}
            className={clsx(
              'form-control ',
              { 'is-invalid': errors.firstname },
              { 'is-valid': !errors.firstname }
            )}
            type='text'
          />
          {errors.firstname && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{errors.firstname}</span>
            </div>
          )}
        </div>
        <div className='w-50'>
          <label className='form-label fs-6 fw-bolder text-light'>Last Name</label>
          <input
            placeholder='Last Name'
            name='lastname'
            value={formValues.lastname}
            onChange={handleChange}
            style={{backgroundColor: "#E8F0FE"}}
            className={clsx(
              'form-control ',
              { 'is-invalid': errors.lastname },
              { 'is-valid': !errors.lastname }
            )}
            type='text'
          />
          {errors.lastname && (
            <div className='fv-plugins-message-container'>
              <span role='alert'>{errors.lastname}</span>
            </div>
          )}
        </div>
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-light'>Email</label>
        <input
          placeholder='Email'
          name='email'
          value={formValues.email}
          onChange={handleChange}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': errors.email },
            { 'is-valid': !errors.email }
          )}
          type='email'
        />
        {errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{errors.email}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-light'>Phone Number</label>
        <input
          placeholder='Phone Number'
          name='phone_no'
          value={formValues.phone_no}
          onChange={handleChange}
          style={{backgroundColor: "#E8F0FE"}}
          className={clsx(
            'form-control',
            { 'is-invalid': errors.phone_no },
            { 'is-valid': !errors.phone_no }
          )}
          type='text'
        />
        {errors.phone_no && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{errors.phone_no}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-light'>State</label>
        <select
  className='form-control'
  id='state_id'
  name='state_id'
  value={formValues.state_id}
  onChange={handleChange}
  style={{backgroundColor: "#E8F0FE"}}
  required
>
  <option value='' disabled>Select State</option>
  {Object.entries(statesMapping).map(([key, state]) => (
    <option key={key} value={Number(key)}>
      {state}
    </option>
  ))}
</select>

        {errors.state_id && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{errors.state_id}</span>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-light'>Password</label>
        <input
          placeholder='Password'
          name='password'
          value={formValues.password}
          onChange={handleChange}
          style={{backgroundColor: "#E8F0FE"}}
          className={clsx(
            'form-control',
            { 'is-invalid': errors.password },
            { 'is-valid': !errors.password }
          )}
          type='password'
        />
        {errors.password && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{errors.password}</span>
          </div>
        )}
      </div>

      <div className='text-center'>
  <button
    type='submit'
    id='kt_sign_up_submit'
    className='btn btn-lg btn-primary w-100 mb-5'
    disabled={loading}
  >
    {!loading && <span className='indicator-label'>Submit</span>}
    {loading && (
      <span className='indicator-progress' style={{ display: 'block' }}>
        Please wait...{' '}
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    )}
  </button>
  <div className='mt-3'>
    <span className='text-success fw-bold'>
     
      {/* <Link to='/auth/login' className='text-success fw-bold'>
      Do you have an account?{' '} Log in
      </Link> */}
       <Link to='/auth/login' className='link-primary text-decoration-underline'>
          Do you have a account ? Login!
        </Link>
    </span>
  </div>
</div>


    </form>
  )
}
