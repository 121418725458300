import { FC, useEffect, useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';
import { Modal, Button, Form } from 'react-bootstrap';

interface Inspector {
  inspector_id: number;
  firstname: string;
  lastname: string;
  is_available: boolean;
  is_disabled: boolean;
}

interface PricingRange {
  pricing_id: number;
  min_bedrooms: number;
  max_bedrooms: number;
  price: string;
}

interface InspectionService {
  service_id: number;
  service_name: string;
  pricing: string;
  description: string;
  price_per_bathroom: string;
  price_per_garage: string;
  inspector: Inspector;
  pricingRanges: PricingRange[];
  second_sell: number;
  third_sell: number;
  fourth_sell: number;
  fifth_sell: number;
  no_of_sales: number;
}

const PricingManagement: FC = () => {
  const [services, setServices] = useState<InspectionService[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [individualModal, setIndividualModal] = useState<InspectionService | null>(null);
  const [sellPercentages, setSellPercentages] = useState({
    secondSell: 80,
    thirdSell: 60,
    fourthSell: 40,
    fifthSell: 20,
  });

    // Function to fetch services
    const fetchServices = async () => {
      try {
        const response = await fetch('https://api.inspection.payplatter.in/api/inspection-services');
        const data: InspectionService[] = await response.json();
        setServices(data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };
  
    useEffect(() => {
      fetchServices();
    }, []);
    
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  // Show modal for individual service
  const handleShowIndividualModal = (service: InspectionService) => setIndividualModal(service);
  const handleCloseIndividualModal = () => setIndividualModal(null);

// Frontend logic for updating percentages for all services
const handleSaveChanges = async () => {
  try {
    // Loop through all services and send individual update requests for percentages
    for (const service of services) {
      await fetch(`https://api.inspection.payplatter.in/api/inspection-services/${service.service_id}/sell-percentages`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          second_sell: sellPercentages.secondSell,
          third_sell: sellPercentages.thirdSell,
          fourth_sell: sellPercentages.fourthSell,
          fifth_sell: sellPercentages.fifthSell,
        }),
      });
    }
    // Close modal and refresh services list
    setShowModal(false);
    fetchServices(); // Optionally refresh data after update
  } catch (error) {
    console.error('Error updating sell percentages:', error);
  }
};


  const handleSaveIndividualChanges = async () => {
    if (individualModal) {
      try {
        const response = await fetch(`https://api.inspection.payplatter.in/api/inspection-services/${individualModal.service_id}/sell-percentages`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            second_sell: sellPercentages.secondSell,
            third_sell: sellPercentages.thirdSell,
            fourth_sell: sellPercentages.fourthSell,
            fifth_sell: sellPercentages.fifthSell,
          }),
        });
        if (response.ok) {
          // Success
          setIndividualModal(null);
          fetchServices(); // Optionally refresh data after update
        }
      } catch (error) {
        console.error('Error updating sell percentages for service:', error);
      }
    }
  };

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await fetch('https://api.inspection.payplatter.in/api/inspection-services');
        const data: InspectionService[] = await response.json();
        setServices(data);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, []);

  return (
    <div className={`card`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 d-flex justify-content-between'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Pricing Setting</span>
        </h3>
        <div className='d-flex align-items-center'>
        <Button 
        className='btn btn-dark btn-sm border border-dark rounded-sm px-4 shadow-sm me-3 d-flex align-items-center' 
        onClick={handleShowModal}>
          <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-2 me-1' />
  <span>Adjust Prices</span>
        </Button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='w-25px'>
                  <div className='form-check form-check-sm form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value='1'
                      data-kt-check='true'
                      data-kt-check-target='.widget-9-check'
                    />
                  </div>
                </th>
                <th className='min-w-150px'>Inspector&nbsp;Name</th>
                <th className='min-w-100px'>1-3&nbsp;BR</th>
                <th className='min-w-100px'>4-6&nbsp;BR</th>
                <th className='min-w-100px'>7-9&nbsp;BR</th>
                <th className='min-w-100px'>9+&nbsp;BR</th>
                <th className='min-w-100px'>Per&nbsp;BathRoom</th>
                <th className='min-w-100px'>Per&nbsp;Garage</th>
                <th className='min-w-100px'>Times&nbsp;Sold</th> {/* Added for no_of_sales */}
                <th className='min-w-100px'>2<sup>nd</sup>&nbsp;Sell&nbsp;%</th>
                <th className='min-w-100px'>3<sup>rd</sup>&nbsp;Sell&nbsp;%</th>
                <th className='min-w-100px'>4<sup>th</sup>&nbsp;Sell&nbsp;%</th>
                <th className='min-w-100px'>5<sup>th</sup>&nbsp;Sell&nbsp;%</th>
                <th className='min-w-80px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {services.map(service => {
                const inspectorName = `${service.inspector.firstname} ${service.inspector.lastname}`;

                // Get pricing for different bedroom ranges
                const pricing1To3 = service.pricingRanges.find(range => range.min_bedrooms === 1 && range.max_bedrooms === 3)?.price || 'N/A';
                const pricing4To6 = service.pricingRanges.find(range => range.min_bedrooms === 4 && range.max_bedrooms === 6)?.price || 'N/A';
                const pricing7To9 = service.pricingRanges.find(range => range.min_bedrooms === 7 && range.max_bedrooms === 9)?.price || 'N/A';
                const pricing9Plus = service.pricingRanges.find(range => range.min_bedrooms >= 10)?.price || 'N/A';

                return (
                  <tr key={service.service_id}>
                    <td>
                      <div className='form-check form-check-sm form-check-custom form-check-solid'>
                        <input
                          className='form-check-input widget-9-check'
                          type='checkbox'
                          value='1'
                        />
                      </div>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary fs-6'>
                        {inspectorName}
                      </span>
                    </td>
                    <td>
                      <span className='badge badge-light-success d-block fs-6'>
                        ${pricing1To3}
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-success d-block fs-6'>
                        ${pricing4To6}
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-success d-block fs-6'>
                        ${pricing7To9}
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-success d-block fs-6'>
                        ${pricing9Plus}
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning d-block fs-6'>
                        ${service.price_per_bathroom}
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-warning d-block fs-6'>
                        ${service.price_per_garage}
                      </span>
                    </td>
                    <td className='text-end'> {/* Show no_of_sales */}
                      <span className='badge badge-light-dark d-block fs-6'>
                        {service.no_of_sales}
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-info fw-bolder fs-6  '>
                        {service.second_sell}%
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-info fw-bolder fs-6'>
                        {service.third_sell}%
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-info fw-bolder fs-6'>
                        {service.fourth_sell}%
                      </span>
                    </td>
                    <td className='text-end'>
                      <span className='badge badge-light-info fw-bolder fs-6'>
                        {service.fifth_sell}%
                      </span>
                    </td>
                    <td>
                      <div className='d-flex justify-content-end flex-shrink-0'>
                        <button
                          name='Edit'
                          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          onClick={() => handleShowIndividualModal(service)}
                        >
                          <KTSVG
                            path='/media/icons/duotune/art/art005.svg'
                            className='svg-icon-3'
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* end::Body */}

      {/* Modal for Adjusting Prices for All Services */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Adjust Prices for All Services</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="secondSell">
              <Form.Label>2nd Sell %</Form.Label>
              <Form.Control
                type="number"
                value={sellPercentages.secondSell}
                onChange={e => setSellPercentages({ ...sellPercentages, secondSell: parseInt(e.target.value) })}
              />
            </Form.Group>
            <Form.Group controlId="thirdSell">
              <Form.Label>3rd Sell %</Form.Label>
              <Form.Control
                type="number"
                value={sellPercentages.thirdSell}
                onChange={e => setSellPercentages({ ...sellPercentages, thirdSell: parseInt(e.target.value) })}
              />
            </Form.Group>
            <Form.Group controlId="fourthSell">
              <Form.Label>4th Sell %</Form.Label>
              <Form.Control
                type="number"
                value={sellPercentages.fourthSell}
                onChange={e => setSellPercentages({ ...sellPercentages, fourthSell: parseInt(e.target.value) })}
              />
            </Form.Group>
            <Form.Group controlId="fifthSell">
              <Form.Label>5th Sell %</Form.Label>
              <Form.Control
                type="number"
                value={sellPercentages.fifthSell}
                onChange={e => setSellPercentages({ ...sellPercentages, fifthSell: parseInt(e.target.value) })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className='btn btn-secondary' onClick={handleCloseModal}>
            Close
          </Button>
          <Button className='btn btn-dark' onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for Individual Service */}
      {individualModal && (
        <Modal show={true} onHide={handleCloseIndividualModal}>
          <Modal.Header closeButton>
            <Modal.Title>Adjust Prices for {individualModal.service_name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="secondSell">
                <Form.Label>2nd Sell %</Form.Label>
                <Form.Control
                  type="number"
                  value={sellPercentages.secondSell}
                  onChange={e => setSellPercentages({ ...sellPercentages, secondSell: parseInt(e.target.value) })}
                />
              </Form.Group>
              <Form.Group controlId="thirdSell">
                <Form.Label>3rd Sell %</Form.Label>
                <Form.Control
                  type="number"
                  value={sellPercentages.thirdSell}
                  onChange={e => setSellPercentages({ ...sellPercentages, thirdSell: parseInt(e.target.value) })}
                />
              </Form.Group>
              <Form.Group controlId="fourthSell">
                <Form.Label>4th Sell %</Form.Label>
                <Form.Control
                  type="number"
                  value={sellPercentages.fourthSell}
                  onChange={e => setSellPercentages({ ...sellPercentages, fourthSell: parseInt(e.target.value) })}
                />
              </Form.Group>
              <Form.Group controlId="fifthSell">
                <Form.Label>5th Sell %</Form.Label>
                <Form.Control
                  type="number"
                  value={sellPercentages.fifthSell}
                  onChange={e => setSellPercentages({ ...sellPercentages, fifthSell: parseInt(e.target.value) })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className='btn btn-secondary' onClick={handleCloseIndividualModal}>
              Close
            </Button>
            <Button className='btn btn-dark' onClick={handleSaveIndividualChanges}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export { PricingManagement };
