import React from 'react'
import {Container, Form, Nav, Row, Col} from 'react-bootstrap'

const quickLinks = [
  {value: 'Terms & Conditions', href: '#!'},
  {value: 'Privacy Policy', href: '#!'},
  {value: 'Refund Policy', href: '#!'},
]

const services = [
  {value: 'About Us'},
  {value: 'Contact Us'},
  {value: 'Locations'},
  {value: 'Sites'},
]

const languageOptions = [
  {
    value: 'en',
    text: 'English',
  },
  {
    value: 'In',
    text: 'Hindi',
  },
]

const Footer: React.FC = () => {
  return (
   
    <section
      style={{
        color: '#28303b',
        padding: '100px 0 70px 0',
        position: 'relative',
      }}
    >
      <div className='custom-shape-divider-top-1723121746'>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
        >
          <path
            d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
            className='shape-fill'
          />
        </svg>
      </div>

      <Container>
        <Row className='text-center text-sm-start'>
          <Col lg={3} className='mt-4 mt-lg-0'>
            <h1>Building Inspections</h1>
            <p style={{marginTop: '15px', marginBottom: '10px', opacity: 0.5}}>
              Detailed Building Inspections for a Safer Tomorrow
            </p>

            <div className=' justify-content-center mt-4'>
              <a
                href='https://facebook.com'
                target='_blank'
                rel='noopener noreferrer'
                className='mx-3'
              >
                <img
                  src='https://img.icons8.com/fluent/30/000000/facebook-new.png'
                  alt='Facebook'
                />
              </a>
              <a
                href='https://linkedin.com'
                target='_blank'
                rel='noopener noreferrer'
                className='mx-3'
              >
                <img src='https://img.icons8.com/fluent/30/000000/linkedin-2.png' alt='LinkedIn' />
              </a>
              <a
                href='https://instagram.com'
                target='_blank'
                rel='noopener noreferrer'
                className='mx-3'
              >
                <img
                  src='https://img.icons8.com/fluent/30/000000/instagram-new.png'
                  alt='Instagram'
                />
              </a>
              <a
                href='https://twitter.com'
                target='_blank'
                rel='noopener noreferrer'
                className='mx-3'
              >
                <img src='https://img.icons8.com/fluent/30/000000/twitter.png' alt='Twitter' />
              </a>
            </div>
          </Col>

          <Col lg={9}>
            <Row>
              <Col sm={4} className='mt-4 mt-lg-0'>
                <p
                  style={{
                    opacity: 0.25,
                    fontWeight: 'normal',
                    textTransform: 'uppercase',
                    fontSize: 'small',
                    marginBottom: '10px',
                  }}
                >
                  Quick Links
                </p>
                <Nav className='flex-column'>
                  {quickLinks.map((link, i) => (
                    <li key={i} style={{marginBottom: '10px', listStyleType: 'none'}}>
                      <a
                        href={link.href}
                        style={{
                          color: '#28303b',
                          opacity: 0.7,
                          textDecoration: 'none',
                          transition: 'color 0.35s ease-in-out',
                        }}
                      >
                        {link.value}
                      </a>
                    </li>
                  ))}
                </Nav>
              </Col>

              <Col sm={4} className='mt-4 mt-lg-0'>
                <h6
                  style={{
                    opacity: 0.25,
                    fontWeight: 'normal',
                    textTransform: 'uppercase',
                    fontSize: 'small',
                  }}
                >
                  Our Services
                </h6>
                <Nav className='flex-column'>
                  {services.map((service, i) => (
                    <li key={i} style={{marginBottom: '10px', listStyleType: 'none'}}>
                      <span
                        style={{
                          color: '#28303b',
                          textDecoration: 'none',
                          transition: 'color 0.35s ease-in-out',
                        }}
                      >
                        {service.value}
                      </span>
                    </li>
                  ))}
                </Nav>
              </Col>

              <Col sm={4} className='mt-4 mt-lg-0'>
                <h6
                  style={{
                    opacity: 0.25,
                    fontWeight: 'normal',
                    textTransform: 'uppercase',
                    fontSize: 'small',
                  }}
                >
                  Language
                </h6>
                <Form.Select
                  name='language'
                  style={{
                    padding: '8px 16px',
                    backgroundColor: 'rgba(163, 190, 241, 0.14)',
                    borderColor: 'transparent',
                    borderRadius: '10px',
                    color: '#28303b',
                  }}
                >
                  {languageOptions.map((language, i) => (
                    <option value={language.value} key={i}>
                      {language.text}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>

            <div
              style={{
                marginTop: '20px',
                opacity: 0.5,
                textAlign: 'center',
              }}
            >
              <span>Copyright &copy; Dexpert Systems, All rights reserved</span>
              {quickLinks.map((item, i) => (
                <a
                  key={i}
                  className='ms-2'
                  href={item.href}
                  style={{color: '#28303b', textDecoration: 'none', marginLeft: '10px'}}
                >
                  {item.value}
                </a>
              ))}
            </div>
          </Col>
        </Row>
      </Container>

      <style>
        {`
        .custom-shape-divider-top-1723121746 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1723121746 svg {
    position: relative;
    display: block;
    width: calc(131% + 1.3px);
    height: 68px;
}

.custom-shape-divider-top-1723121746 .shape-fill {
    fill: #FFFFFF;
}`}
      </style>
    </section>
   
  )
}

export default Footer
