


import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

const PieChart: React.FC = () => {
  const data = {
    labels: ['Active users', 'Sellers', 'Buyers', 'Total Inspections', 'Inspections Completed', 'Inspections Pending'],
    datasets: [
      {
        label: '',
        data: [69, 26, 43, 33, 23 ,10],
        backgroundColor: ['#EF4444', '#3B82F6', '#FBBF24', '#10B981', '#A78BFA','#2A28AD'],
        hoverOffset: 2,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  return (
    <div style={{ width: '100%', backgroundColor: 'white', padding: '20px', boxSizing: 'border-box'}}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center' }}>
        <div style={{ flex: '1 1 0%', display: 'flex', justifyContent: 'center' }}>
          <div style={{ height: '250px', width: '250px' }}>
            <Pie data={data} options={options} />
          </div>
        </div>
        <div className='ms-8' style={{ flex: '1 1 0%' }}>
          <ul style={{ listStyleType: 'none', padding: '50px' }}>
            {data.labels.map((label, index) => (
              <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <span
                  style={{
                    backgroundColor: data.datasets[0].backgroundColor[index],
                    display: 'inline-block',
                    width: '12px',
                    height: '12px',
                    marginRight: '10px',
                  }}
                ></span>
                <div>
                  {label}: {data.datasets[0].data[index]}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PieChart
