const Payment = () => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100 bg-light">
      <h1 className="mb-4" style={{ color: '#343a40', fontWeight: 'bold' }}>Make Payment Here!</h1>
      <a href="/dashboard">
        <button className="btn btn-primary btn-lg">
          Go to Dashboard
        </button>
      </a>
    </div>
  );
};

export default Payment;
