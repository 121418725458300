import React from 'react'
import PropertyDetailsPage from '../../modules/website/components/PropertyDetailsPage'
import Footer from '../../modules/website/components/Footer'

const PropertyDetail = () => {
  return (
    <div>
      <PropertyDetailsPage></PropertyDetailsPage>
      <Footer></Footer>
    </div>
  )
}

export default PropertyDetail
