import React, { useState, useRef } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface AddExistingReportModalProps {
  show: boolean;
  handleClose: () => void;
}

interface FormData {
  reportDate: Date | null;
  inspectionDate: Date | null;
  propertyAddress: string;
  city: string;
  state: string;
  zipcode: string;
  clientName: string;
  clientRole: 'Buyer' | 'Seller';
}

const AddExistingReportModal: React.FC<AddExistingReportModalProps> = ({ show, handleClose }) => {
  const [formData, setFormData] = useState<FormData>({
    reportDate: new Date(),
    inspectionDate: null,
    propertyAddress: '',
    city: '',
    state: '',
    zipcode: '',
    clientName: '',
    clientRole: 'Buyer',
  });
  const [file, setFile] = useState<File | null>(null);

  // Reference to the hidden file input
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Date | null, field: keyof FormData) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData, file);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} size='xl' >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between align-items-center w-100">
          <Modal.Title>Add Existing Report</Modal.Title>
          {/* <Button variant='outline-dark' size="sm" style={{ border: "solid black 2px" }} className='mx-10'>
            Save Draft
          </Button> */}
        </div>
      </Modal.Header>
      <Modal.Body
        style={{
          display: 'flex',
          height: '80vh',
          overflow: 'hidden',
          padding: '20px',
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        }}
      >
        <div style={{ flex: 1, overflowY: 'auto', paddingRight: '20px', borderRight: '2px solid #ccc', height: '100%' }}>
          <Form onSubmit={handleSubmit}>
            <h5
              className='mt-5'
              style={{
                backgroundColor: '#f0f0f0',
                padding: '8px 12px',
                borderRadius: '4px',
                display: 'inline-block',
                marginRight: '10px',
              }}
            >
              Basic Information
            </h5>
            <Row className='mt-4'>
              <Col md={6}>
                <Form.Group controlId='reportDate'>
                  <Form.Label>Report Date</Form.Label>
                  {/* <DatePicker
                    selected={formData.reportDate}
                    onChange={(date) => handleDateChange(date, 'reportDate')}
                    className='form-control'
                    name='reportDate'
                  /> */}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='inspectionDate'>
                  <Form.Label>Inspection Date</Form.Label>
                  {/* <DatePicker
                    selected={formData.inspectionDate}
                    onChange={(date) => handleDateChange(date, 'inspectionDate')}
                    className='form-control'
                    name='inspectionDate'
                    placeholderText='Select Date'
                  /> */}
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col md={6}>
                <Form.Group controlId='propertyAddress'>
                  <Form.Label>Property Address</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter property address'
                    name='propertyAddress'
                    value={formData.propertyAddress}
                    onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='city'>
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter city'
                    name='city'
                    value={formData.city}
                    onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col md={6}>
                <Form.Group controlId='state'>
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter state'
                    name='state'
                    value={formData.state}
                    onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='zipcode'>
                  <Form.Label>Zipcode</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter zipcode'
                    name='zipcode'
                    value={formData.zipcode}
                    onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col md={6}>
                <Form.Group controlId='clientName'>
                  <Form.Label>Client Name</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter client name'
                    name='clientName'
                    value={formData.clientName}
                    onChange={handleChange as React.ChangeEventHandler<HTMLInputElement>}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId='clientRole'>
                  <Form.Label>Client Role</Form.Label>
                  <Form.Select
                    name='clientRole'
                    value={formData.clientRole}
                    onChange={handleChange as React.ChangeEventHandler<HTMLSelectElement>}
                  >
                    <option value='Buyer'>Buyer</option>
                    <option value='Seller'>Seller</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {/* Drag and Drop PDF Upload */}
            <Row className="mt-4">
              <Col md={12}>
                <Form.Group controlId="fileUpload">
                  <Form.Label>Upload Report PDF</Form.Label>
                  <div
                    style={{
                      border: '2px dashed #ced4da',
                      padding: '20px',
                      textAlign: 'center',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      backgroundColor: '#f8f9fa'
                    }}
                    onClick={() => fileInputRef.current?.click()}
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      const file = e.dataTransfer.files[0];
                      if (file && file.type === 'application/pdf') {
                        setFile(file);
                      }
                    }}
                  >
                    {file ? (
                      <span>{file.name}</span>
                    ) : (
                      <span>Drag & Drop PDF here or click to select file</span>
                    )}
                    <Form.Control
                      type="file"
                      accept="application/pdf"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                    />
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <div className='mt-4 text-center'>
              <Button variant='primary' type='submit'>
                Save Report
              </Button>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddExistingReportModal;
