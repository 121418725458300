

// import React, { useState, useEffect } from 'react';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
// import { addMonths, format } from 'date-fns';
// import { useAuth } from '../../auth';
// import { useLocation } from 'react-router-dom';

// interface InspectorData {
//   firstname: string;
//   lastname: string;
//   service_name: string;
//   phone_no: string;
//   pricing: string;
//   description: string;
//   certification_details: string;
//   insurance_details: string;
//   experience_years: number;
//   rating: number;
//   availability: string[];
//   idOfInspector: number;
// }

// interface LeavePeriod {
//   leave_start_date: string;
//   leave_end_date: string;
// }

// const InspectorUnavailability: React.FC = () => {
//   const [startDate, setStartDate] = useState<Date | undefined>(undefined);
//   const [endDate, setEndDate] = useState<Date | undefined>(undefined);
//   const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
//   const [isEditing, setIsEditing] = useState<boolean>(false);
//   const [inspectorId, setInspectorId] = useState<number | null>(null);
//   const [leavePeriod, setLeavePeriod] = useState<LeavePeriod | null>(null); // Store leave period data
//   const location = useLocation();
//   const inspectorData = (location.state as InspectorData) || {};
//   const auth = useAuth();
//   const currentUserId = auth.currentUser?.user_id;

//   useEffect(() => {
//     // Fetch inspector profile when the component mounts
//     const fetchInspectorProfile = async () => {
//       if (currentUserId) {
//         try {
//           const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/profile/${currentUserId}`);
//           if (response.ok) {
//             const data = await response.json();
//             setInspectorId(data.inspector_id); // Store inspector_id
//             fetchLeavePeriod(data.inspector_id); // Fetch leave period
//           } else {
//             console.error('Failed to fetch inspector profile:', response.statusText);
//           }
//         } catch (error) {
//           console.error('An error occurred while fetching inspector profile:', error);
//         }
//       } else {
//         console.error('No current user ID available.');
//       }
//     };

//     const fetchLeavePeriod = async (id: number) => {
//       try {
//         const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/${id}/leave-period`);
//         if (response.ok) {
//           const data = await response.json();
//           setLeavePeriod(data.data); // Store leave period data
//         } else {
//           console.error('Failed to fetch leave period:', response.statusText);
//         }
//       } catch (error) {
//         console.error('An error occurred while fetching leave period:', error);
//       }
//     };

//     fetchInspectorProfile();
//   }, [currentUserId]);



//   console.log(inspectorId)
//   const handleSubmit = async () => {
//     if (startDate && endDate && inspectorId) {
//       const formattedStartDate = new Date(startDate);
//       const formattedEndDate = new Date(endDate);
//       formattedEndDate.setHours(23, 59, 59, 999);

//       const requestBody = {
//         start_date: formattedStartDate.toISOString(),
//         end_date: formattedEndDate.toISOString(),
//       };

//       try {
//         const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/${inspectorId}/set-leave`, {
//           method: 'PUT',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(requestBody),
//         });

//         if (response.ok) {
//           setIsSubmitted(true);
//           setIsEditing(false);
//           console.log('Unavailability successfully set:', requestBody);
//         } else {
//           console.error('Failed to set unavailability:', response.statusText);
//         }
//       } catch (error) {
//         console.error('An error occurred while setting unavailability:', error);
//       }
//     } else {
//       console.log('Please select a date range.');
//     }
//   };

//   const handleEdit = () => {
//     setIsSubmitted(false);
//     setIsEditing(true);
//   };

//   const formatDate = (dateString: string | undefined) => {
//     if (!dateString) return '';
//     const date = new Date(dateString);
//     return format(date, 'dd MMMM yyyy');
//   };

//   return (
//     <div className='container mt-5 mb-5'>
//       <div className='card shadow-sm'>
//         <div className='card-header text-center text-white'>
//           <h2 className='card-title'>Set Your Unavailability</h2>
//         </div>
//         <div className='card-body'>
//           {!isSubmitted && (
//             <>
//               <label className='form-label mb-3 text-center d-block'>
//                 Select Date Range:
//               </label>
//               <div className='d-flex justify-content-center'>
//                 <DatePicker
//                   selected={startDate}
//                   onChange={(dates: [Date | null, Date | null]) => {
//                     const [start, end] = dates;
//                     setStartDate(start || undefined);
//                     setEndDate(end || undefined);
//                   }}
//                   startDate={startDate}
//                   endDate={endDate}
//                   selectsRange
//                   monthsShown={3}
//                   minDate={new Date()}
//                   maxDate={addMonths(new Date(), 2)}
//                   inline
//                   className="custom-datepicker p-3"
//                 />
//               </div>
//             </>
//           )}

//           {isSubmitted && (
//             <div className='text-center mt-4'>
//               <p className='fw-bold'>
//                 You are unavailable from {formatDate(startDate?.toISOString())} to {formatDate(endDate?.toISOString())}.
//               </p>
//               <button className='btn btn-primary' onClick={handleEdit}>
//                 Edit Unavailability
//               </button>
//             </div>
//           )}

//           {leavePeriod && (
//             <div className='text-center mt-8'>
//               <h4>Current Unavailability</h4>
//               <p>
//               You are unavailable from <b>{formatDate(leavePeriod.leave_start_date)}</b> to <b>{formatDate(leavePeriod.leave_end_date)}</b> 
//               </p>
//               {/* <p>
//                 Leave End: {formatDate(leavePeriod.leave_end_date)}
//               </p> */}
//             </div>
//           )}
//         </div>

//         <div className='card-footer text-center'>
//           {!isSubmitted && (
//             <button className='btn btn-success btn-lg' onClick={handleSubmit}>
//               Set Unavailability
//             </button>
//           )}
//         </div>
//       </div>

//       <style>{`
//         .custom-datepicker .react-datepicker__header {
//           background-color: #007bff;
//           color: white;
//           border-bottom: none;
//         }
//         .custom-datepicker .react-datepicker__current-month {
//           font-size: 1.25rem;
//           font-weight: bold;
//         }
//         .custom-datepicker .react-datepicker__day-name,
//         .custom-datepicker__day {
//           color: #333;
//           font-size: 1rem;
//         }
//         .custom-datepicker .react-datepicker__day--selected,
//         .custom-datepicker__day--in-range {
//           background-color: #28a745;
//           color: white;
//           border-radius: 50%;
//         }
//         .custom-datepicker .react-datepicker__day:hover {
//           background-color: #007bff;
//           color: white;
//         }
//         .custom-datepicker .react-datepicker__day--keyboard-selected {
//           background-color: #28a745;
//           color: white;
//         }
//         .custom-datepicker .react-datepicker__navigation--previous {
//           border-right-color: #007bff;
//         }
//         .custom-datepicker .react-datepicker__navigation--next {
//           border-left-color: #007bff;
//         }
//         .custom-datepicker .react-datepicker__month {
//           margin: 1rem;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default InspectorUnavailability;




import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addMonths, format } from 'date-fns';
import { useAuth } from '../../auth';
import { useLocation } from 'react-router-dom';

interface InspectorData {
  firstname: string;
  lastname: string;
  service_name: string;
  phone_no: string;
  pricing: string;
  description: string;
  certification_details: string;
  insurance_details: string;
  experience_years: number;
  rating: number;
  availability: string[];
  idOfInspector: number;
}

interface LeavePeriod {
  leave_start_date: string;
  leave_end_date: string;
}

const InspectorUnavailability: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [inspectorId, setInspectorId] = useState<number | null>(null);
  const [leavePeriod, setLeavePeriod] = useState<LeavePeriod | null>(null); // Store leave period data
  const location = useLocation();
  const inspectorData = (location.state as InspectorData) || {};
  const auth = useAuth();
  const currentUserId = auth.currentUser?.user_id;

  useEffect(() => {
    // Fetch inspector profile when the component mounts
    const fetchInspectorProfile = async () => {
      if (currentUserId) {
        try {
          const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/profile/${currentUserId}`);
          if (response.ok) {
            const data = await response.json();
            setInspectorId(data.inspector_id); // Store inspector_id
            fetchLeavePeriod(data.inspector_id); // Fetch leave period
          } else {
            console.error('Failed to fetch inspector profile:', response.statusText);
          }
        } catch (error) {
          console.error('An error occurred while fetching inspector profile:', error);
        }
      } else {
        console.error('No current user ID available.');
      }
    };

    const fetchLeavePeriod = async (id: number) => {
      try {
        const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/${id}/leave-period`);
        if (response.ok) {
          const data = await response.json();
          setLeavePeriod(data.data); // Store leave period data
        } else {
          console.error('Failed to fetch leave period:', response.statusText);
        }
      } catch (error) {
        console.error('An error occurred while fetching leave period:', error);
      }
      
    };

    fetchInspectorProfile();
  }, [currentUserId]);

  const handleSubmit = async () => {
    if (startDate && endDate && inspectorId) {
      const formattedStartDate = new Date(startDate);
      const formattedEndDate = new Date(endDate);
      formattedEndDate.setHours(23, 59, 59, 999);

      const requestBody = {
        start_date: formattedStartDate.toISOString(),
        end_date: formattedEndDate.toISOString(),
      };

      try {
        const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/${inspectorId}/set-leave`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          setIsSubmitted(true);
          setIsEditing(false);
          console.log('Unavailability successfully set:', requestBody);
        } else {
          console.error('Failed to set unavailability:', response.statusText);
        }
      } catch (error) {
        console.error('An error occurred while setting unavailability:', error);
      }
    } else {
      console.log('Please select a date range.');
    }
  };

  const handleResetUnavailability = async () => {
    if (inspectorId) {
      try {
        const response = await fetch(`https://api.inspection.payplatter.in/api/inspectors/${inspectorId}/reset-unavailability`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          setLeavePeriod(null); // Clear leave period
          setIsSubmitted(false);
          console.log('Unavailability reset successfully');
        } else {
          console.error('Failed to reset unavailability:', response.statusText);
        }
      } catch (error) {
        console.error('An error occurred while resetting unavailability:', error);
      }
    } else {
      console.log('Inspector ID is missing.');
    }
  };

  const handleEdit = () => {
    setIsSubmitted(false);
    setIsEditing(true);
  };

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy');
  };

  return (
    <div className='container mt-5 mb-5'>
      <div className='card shadow-sm'>
        <div className='card-header text-center text-white'>
          <h2 className='card-title'>Set Your Unavailability</h2>
        </div>
        <div className='card-body'>
          {!isSubmitted && (
            <>
              <label className='form-label mb-3 text-center d-block'>
                Select Date Range:
              </label>
              <div className='d-flex justify-content-center'>
                <DatePicker
                  selected={startDate}
                  onChange={(dates: [Date | null, Date | null]) => {
                    const [start, end] = dates;
                    setStartDate(start || undefined);
                    setEndDate(end || undefined);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={3}
                  minDate={new Date()}
                  maxDate={addMonths(new Date(), 2)}
                  inline
                  className="custom-datepicker p-3"
                />
              </div>
            </>
          )}

          {isSubmitted && (
            <div className='text-center mt-4'>
              <p className='fw-bold'>
                You are unavailable from {formatDate(startDate?.toISOString())} to {formatDate(endDate?.toISOString())}.
              </p>
              <button className='btn btn-primary' onClick={handleEdit}>
                Edit Unavailability
              </button>
            </div>
          )}

          {leavePeriod && (
            <div className='text-center mt-8'>
              <h4>Current Unavailability</h4>
              <p>
              You are unavailable from <b>{formatDate(leavePeriod.leave_start_date)}</b> to <b>{formatDate(leavePeriod.leave_end_date)}</b> 
              </p>
            </div>
          )}
        </div>

        <div className='card-footer text-center'>
          {!isSubmitted && (
            <div className="d-flex justify-content-center">
              <button className='btn btn-success btn-lg me-3' onClick={handleSubmit}>
                Set Unavailability
              </button>
              <button className='btn btn-danger btn-lg' onClick={handleResetUnavailability}>
                Reset Unavailability
              </button>
            </div>
          )}
        </div>
      </div>

      <style>{`
        .custom-datepicker .react-datepicker__header {
          background-color: #007bff;
          color: white;
          border-bottom: none;
        }
        .custom-datepicker .react-datepicker__current-month {
          font-size: 1.25rem;
          font-weight: bold;
        }
        .custom-datepicker .react-datepicker__day-name,
        .custom-datepicker__day {
          color: #333;
          font-size: 1rem;
        }
        .custom-datepicker .react-datepicker__day--selected,
        .custom-datepicker__day--in-range {
          background-color: #28a745;
          color: white;
          border-radius: 50%;
        }
        .custom-datepicker .react-datepicker__day:hover {
          background-color: #007bff;
          color: white;
        }
        .custom-datepicker .react-datepicker__day--keyboard-selected {
          background-color: #28a745;
          color: white;
        }
        .custom-datepicker .react-datepicker__navigation--previous {
          border-right-color: #007bff;
        }
        .custom-datepicker .react-datepicker__navigation--next {
          border-left-color: #007bff;
        }
        .custom-datepicker .react-datepicker__month {
          margin: 1rem;
        }
      `}</style>
    </div>
  );
};

export default InspectorUnavailability;
