import axios from 'axios';
import React, { useEffect, useState } from 'react';

// Adjusted interface definitions to match the API response

interface InspectionReport {
    report_id: number;
    report_date: null | string;
    status: string;
    reportNumber: string;
    inspectionDate: string;
    clientName: string;
    clientEmail: string;
    clientPhoneNumber: string;
    propertyAddress: string;
    city: string;
    state: string;
    zipcode: string;
    overallCondition: string;
    majorDefect: boolean;
    minorDefect: boolean;
    safetyHazard: boolean;
    [key: string]: any; // for additional dynamic keys
}

const InspectionReports = () => {
    const [reports, setReports] = useState<InspectionReport[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchReports = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://api.inspection.payplatter.in/api/inspection-reports');
            setReports(response.data);
            console.log('Fetched inspection reports : ', response);
        } catch (error) {
            console.error('Error fetching inspection reports:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchReports();
    }, []);

    return (
        <div>
            <div className="card">
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold fs-3 mb-1">Inspection Reports</span>
                    </h3>
                </div>

                <div className="card-body py-3">
                    <div className="table-responsive">
                        {loading ? (
                            <span className="center">Loading Inspection Reports......</span>
                        ) : (
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-muted">
                                        <th className="min-w-150px">Inspection Date</th>
                                        <th className="min-w-150px">Report Date</th>
                                        <th className="min-w-150px">Inspector Name</th>
                                        <th className="min-w-150px">Report Number</th>
                                        <th className="min-w-120px">Status</th>
                                        <th className="min-w-120px">Inspector ID</th>
                                        <th className="min-w-120px">Client Name</th>
                                        <th className="min-w-120px">Client Email</th>
                                        <th className="min-w-150px">Contact</th>
                                        <th className="min-w-120px">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reports.map((report) => (
                                        <tr key={report.report_id}>
                                            {/* <td>{report.inspectionDate}</td> */}
                                            <td>{ report.inspectionDate ?
                                                new Date(report.inspectionDate).toLocaleString('en-US', { year: 'numeric',  month: 'long',  day: 'numeric'})
                                                : <>N/A</>
                                                }</td>
                                            <td>{
                                                 report.report_date ? 
                                                 new Date(report.report_date).toLocaleString('en-US',{year:'numeric', month:'long', day:'numeric'})
                                                 :
                                                 <>N/A</>
                                                }
                                            </td>
                                            <td>{''}</td>
                                            <td>{report.reportNumber}</td>
                                            <td>{report.status ? <>{report.status}</> :<>N/A</>}</td>
                                            <td>{''}</td>
                                            <td>{report.clientName ? <>{report.clientName}</> : <>N/A</> }</td>
                                            <td>{report.clientEmail ? <>{report.clientEmail}</> : <>N/A</>}</td>
                                            <td>{report.clientPhoneNumber ? report.clientPhoneNumber : 'N/A'}</td>
                                            <td>
                                                <button className="btn btn-primary btn-sm">Manage</button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InspectionReports;
