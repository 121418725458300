import React, {useState, useEffect, useRef} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import {Formik, useFormik} from 'formik'
import {useAuth} from '../../auth'
import Select, {SingleValue, MultiValue, ActionMeta} from 'react-select'

import PdfModal from './PdfModal'

import {InspectorInsurancePhoto, InspectorProfile, InspectorUpdate} from '../core/_models'
import {InspectorProfilePhoto} from '../core/_models'
import axios, {AxiosRequestConfig, Method} from 'axios'
import SignatureCanvas from 'react-signature-canvas'
import 'add-to-calendar-button'

import blankprofilepicture from '../../../../_metronic/assets/abc.webp'
import blankprofilepic from '../../../../_metronic/assets/blankprofilepic.jpg'
import {Modal, Button, Form, Card} from 'react-bootstrap'

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string(),
  lName: Yup.string(),
  company: Yup.string(),
  contactPhone: Yup.string(),
  companySite: Yup.string(),
  country: Yup.string(),
  language: Yup.string(),
  timeZone: Yup.string(),
  currency: Yup.string(),
})

type OptionType = {
  label: string;
  value: string;
};

interface id_prop {
  userid: number
}

const profileSchema = Yup.object().shape({
  profilePhoto: Yup.string(),
  fname: Yup.string(),
  lName: Yup.string(),
  contactPhone: Yup.string(),
  experience: Yup.number(),
  certificationDetails: Yup.string(),
  insuranceDetails: Yup.number(),
})
const initialValue = {
  profilePhoto: '',
  fname: '',
  lName: '',
  contactPhone: '',
  experience: 0,
  certificationDetails: '',
  insuranceDetails: 0,
}

const ProfileOverview: React.FC = (user_id) => {
  // Mapping the availability values to their corresponding labels
  const mapAvailabilityToLabels = (availabilityCodes: any[], cities: any[]) => {
    return availabilityCodes.map((code) => {
      const foundCity = cities.find((city) => city.city_code === code);
      return foundCity ? { label: `${foundCity.city_name} - ${foundCity.city_code}`, value: foundCity.city_code } : { label: code, value: code };
    });
  };
  

  const [data, setData] = useState<IProfileDetails>(initialValues)

  // const [usersResponse, setUsersResponse] = useState<InspectorProfile | null>(null)
  const [usersResponse, setUsersResponse] = useState<{[key: string]: any}>({})

  const [cities, setCities] = useState<OptionType[]>([]);  // State to store cities from the backend

  // const [availability, setAvailability] = useState<Option[]>([])
  const [availabilities, setAvailabilities] = useState<string[]>([])

  const [modalTitle, setModalTitle] = useState('')
  const [pdfUrl, setPdfUrl] = useState('')
  // const [profilePic, setProfilePic] = useState<InspectorProfilePhoto>({file: null})
  const [profilePic, setProfilePic] = useState<string>('')
  const [insurancePic,setInsurancePic] = useState<string>('')

  const [signatureSrc, setSignatureSrc] = useState<string>('')
  const sigCanvas = useRef<SignatureCanvas>(null)

  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [insuranceFile, setInsuranceFile] = useState<File | null>(null)

  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        values.communications.email = data.communications.email
        values.communications.phone = data.communications.phone
        values.allowMarketing = data.allowMarketing
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        setLoading(false)
      }, 1000)
    },
  })

  const currentUser = auth.currentUser
  // console.log(currentUser)
  const currenteUserId = currentUser?.user_id
  // console.log(currenteUserId)
  const currenteUserRole = currentUser?.role
  const currenteUserPhoneNum = currentUser?.phone_no
  // console.log(currenteUserId)

  const clearSignature = () => {
    sigCanvas.current?.clear()
  }

  const saveSignature = () => {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL()
      const link = document.createElement('a')
      link.href = dataUrl
      link.download = 'signature.png'
      link.click()
    }
  }
  const uploadSignature = () => {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL()
      const blob = dataURLToBlob(dataUrl)

      const formData = new FormData()
      formData.append('fileType', 'signature')
      formData.append('role', currenteUserRole?.toString() || '')
      formData.append('user_id', currenteUserId?.toString() || '')
      formData.append('phone_no', currenteUserPhoneNum?.toString() || '')
      formData.append('file', blob, 'signature.png')
      formData.append('description', 'signature image')

      const config: AxiosRequestConfig<FormData> = {
        method: 'post' as Method,
        maxBodyLength: Infinity,
        url: 'https://api.inspection.payplatter.in/api/file-upload',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data))
        })
        .catch((error) => {
          console.log(error)
        })
    }
    fetchSignature()
  }

  // Fetch cities from the backend
  useEffect(() => {
    axios.get('https://api.inspection.payplatter.in/api/cities')
      .then(response => {
        const cityOptions = response.data.data.map((city: { city_name: any; city_code: any }) => ({
          label: `${city.city_name} - ${city.city_code}`,  // Display city name and postal code
          value: city.city_code,  // Use city_code as the value
        }));
        setCities(cityOptions);  // Set cities as OptionType[]
      })
      .catch(error => {
        console.error('Error fetching cities:', error);
      });
  }, []);

  const handlecceptance = (title: string, accepted: boolean) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [title]: accepted,
      terms: title === 'Terms & Conditions' ? accepted : prevFormData.terms,
      cancel_policy: title === 'Cancellation Policy' ? accepted : prevFormData.cancel_policy,
      code_conduct: title === 'Code of Conduct' ? accepted : prevFormData.code_conduct,
    }))
    handleCloseModal()
    calculateProfileCompletion()
  }

  const [showModal, setShowModal] = useState<boolean>(false)

  const handleOpenModal = (title: string, url: string) => {
    setModalTitle(title)
    setPdfUrl(url)
    setShowModal(true)
  }

  const handleCloseModal = () => setShowModal(false)

  // ==============> Profile completion
  const [profileCompletion, setProfileCompletion] = useState(0) // Initialize with 50%

  const calculateProfileCompletion = () => {
    const fields = [
      'firstname',
      'lastname',
      'phone_no',
      'experience_years',
      'certification_details',
      'insurance_details',
      'availability',
      'cancel_policy',
      'code_conduct',
      'terms',
    ]

    let completedFields = 0

    fields.forEach((field) => {
      if (field === 'availability') {
        // Check if availability has any selected values
        if (availabilities.length > 0) {
          completedFields++
        }
      } else {
        // Check for other fields in usersResponse
        if (
          usersResponse?.[field] !== '' &&
          usersResponse?.[field] !== null &&
          usersResponse?.[field] !== undefined
        ) {
          completedFields++
        }
      }
    })

    // Check separately for signatureSrc
    if (signatureSrc !== '' && signatureSrc !== null && signatureSrc !== undefined) {
      completedFields++
    }

    // Calculate the percentage of completed fields
    const profileCompletion = Math.round((completedFields / (fields.length + 1)) * 100)

    // Update state with the calculated profile completion
    setProfileCompletion(profileCompletion)
  }

  //=========================================================

  type City = {
    city_id: number;
    city_name: string;
    city_code: string;
  };
  
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Fetch inspector profile data
        const profileResponse = await axios.get<InspectorProfile>(
          `https://api.inspection.payplatter.in/api/inspectors/profile/${currenteUserId}`
        );
        setUsersResponse(profileResponse.data);
  
        // Fetch cities dynamically from the backend
        const citiesResponse = await axios.get('https://api.inspection.payplatter.in/api/cities');
        const cityList: City[] = citiesResponse.data.data;  // Type cities as City[]
  
        // Map cityList to the format expected by react-select
        const optionList: OptionType[] = cityList.map((city) => ({
          label: `${city.city_name} - ${city.city_code}`,  // Display city_name and city_code
          value: city.city_code,  // Use city_code as the value
        }));
  
        // Map availability (city codes) from the profile to the city list
        const mappedAvailabilities = profileResponse.data.availability.map((code) => {
          const foundCity = cityList.find((city) => city.city_code === code);
          return foundCity ? foundCity.city_code : code;  // Ensure only city_code is stored
        });
  
        setAvailabilities(mappedAvailabilities);  // Use city_code strings for availability
        setCities(optionList);  // Set cities for dropdown options as OptionType[]
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error('Error fetching profile or cities:', err);
      }
    };
  
    fetchProfile();
  }, [currenteUserId]);
  // console.log(usersResponse);

  

   // Handle change for availabilities
   const handleChange = (selectedOptions: MultiValue<OptionType>) => {
    const selectedCityCodes = selectedOptions.map(option => option.value);  // Extract city_code
    setAvailabilities(selectedCityCodes);  // Update availabilities with city codes
  };

  const fetchProfilePhoto = async () => {
    try {
      const response = await axios.get<InspectorProfilePhoto>(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/profilepic`
      )
      setProfilePic(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/profilepic`
      )
      setLoading(false)
    } catch (err) {
      console.error('Error fetching Profilepic:', err)
      setLoading(false)
    }
  }
  const fetchInsuracePhoto = async () => {
    try {
      const response = await axios.get<InspectorInsurancePhoto>(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/insurance`
      )
      setInsurancePic(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/insurance`
      )
      setLoading(false)
      // console.log(response)
    } catch (err) {
      console.error('Error fetching Insurance:', err)
      setLoading(false)
    }
  }

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setImageFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageSrc(reader.result as string)
      }
      reader.readAsDataURL(file)
      fetchProfilePhoto()
    }
    calculateProfileCompletion()
  }
  fetchProfilePhoto()

  const uploadProfile = () => {
    if (!imageFile) return

    // console.log(imageFile)
    const formData3 = new FormData()
    formData3.append('fileType', 'profilepic')
    formData3.append('user_id', currenteUserId?.toString() || '')
    formData3.append('role', currenteUserRole?.toString() || '')
    formData3.append('phone_no', currenteUserPhoneNum?.toString() || '')
    formData3.append('file', imageFile)

    const config: AxiosRequestConfig<FormData> = {
      method: 'post' as Method,
      maxBodyLength: Infinity,
      url: 'https://api.inspection.payplatter.in/api/file-upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData3,
    }

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }


  const uploadInsurance = () => {
    if (!insuranceFile) return

    // console.log(imageFile)
    const formData3 = new FormData()
    formData3.append('fileType', 'insurance')
    formData3.append('user_id', currenteUserId?.toString() || '')
    formData3.append('role', currenteUserRole?.toString() || '')
    formData3.append('phone_no', currenteUserPhoneNum?.toString() || '')
    formData3.append('file', insuranceFile)

    const config: AxiosRequestConfig<FormData> = {
      method: 'post' as Method,
      maxBodyLength: Infinity,
      url: 'https://api.inspection.payplatter.in/api/file-upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData3,
    }

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data))
        console.log('FormData content:', Array.from(formData3.entries())); 
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (imageFile) {
      uploadProfile()
      fetchProfilePhoto()
      calculateProfileCompletion()
    }
  }, [imageFile])

  useEffect(() => {
    if (insuranceFile) {
      uploadInsurance()
      fetchInsuracePhoto()
      calculateProfileCompletion()
    }
  }, [insuranceFile])

  const [formData, setFormData] = useState<InspectorUpdate>({
    firstname: '',
    lastname: '',
    phone_no: '',
    availability: [],
    terms: true,
    cancel_policy: true,
    code_conduct: true,
    certification_details: '',
    insurance_details: '',
    experience_years: 0,
  })

  const updatedData = {
    ...formData,
    availability: availabilities,
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const updatedData = {
      ...formData,
      availability: availabilities,
    }

    try {
      const response = await axios.put(
        `https://api.inspection.payplatter.in/api/inspectors/profile/${currenteUserId}`,
        updatedData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      console.log('Data submitted successfully:', response.data)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error submitting data:', error.response?.data || error.message)
      } else {
        console.error('Unexpected error:', error)
      }
    }

    calculateProfileCompletion()
  }

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value, type} = e.target

    // Handle number fields correctly
    const newValue = type === 'number' ? Number(value) : value

    setUsersResponse((prevState) => {
      if (prevState === null || '') {
        return {
          [name]: newValue,
        } as unknown as InspectorProfile
      }

      return {
        ...prevState,
        [name]: newValue,
      }
    })
    calculateProfileCompletion()
  }

  const handleCheckboxChange = (isChecked: boolean) => {
    console.log('Checkbox Value:', isChecked)
    calculateProfileCompletion()
  }

  type OptionType = {value: string; label: string}

  const fetchSignature = async () => {
    try {
      const response = await axios.get<InspectorProfilePhoto>(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/signature`
      )
      // console.log(response.data) // Verify the response structure
      setSignatureSrc(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/signature`
      )
      setLoading(false)
    } catch (err) {
      console.error('Error fetching signature:', err)
      setLoading(false)
    }
  }
  fetchSignature()

  useEffect(() => {
    fetchSignature()
  }, [])

  useEffect(() => {
    fetchProfilePhoto()
    fetchSignature()
    calculateProfileCompletion()
  }, [
    handlecceptance,
    handleOpenModal,
    handleCloseModal,
    handleChange,
    handleChange2,
    handleCheckboxChange,
    handleSubmit,
    
  ])

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false) // Changed variable names
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [expiryDate, setExpiryDate] = useState<string>('')

  const openModal = () => setIsModalVisible(true) // Changed function names
  const closeModal = () => setIsModalVisible(false)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
  }

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExpiryDate(event.target.value)
  }

  const handleSubmitInsurance = () => {
    if (!selectedFile || !expiryDate) {
      alert('Please select a file and set an expiry date.');
      return;
    }
    if (selectedFile) {
      setInsuranceFile(selectedFile);  
      fetchInsuracePhoto(); 
    }  
    calculateProfileCompletion(); 
    // setSelectedFile(null);
    // setExpiryDate('');
  
    // Close the modal after submission
    closeModal();
  };
  

  
  return (
    <div className='card mb-5 mb-xl-10'>
      {/* progressbar */}
      <div className='card-footer d-flex justify-content-end py-0 px-0'>
        <progress value={profileCompletion} max='100' style={{width: '100%', height: '10px'}} />
      </div>
      {/* ------------------- */}
      <style>{` .fixed-size-image {  width: 200px;  height: 200px;  object-fit: cover;  }`}</style>

      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0 d-flex justify-content-between align-items-center w-100'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
          <span className='badge badge-dark p-4'>Profile Completed {profileCompletion} %</span>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
      <Formik
  initialValues={{
    firstname: usersResponse?.firstname || '',
    lastname: usersResponse?.lastname || '',
    phone_no: usersResponse?.phone_no || '',
    experience_years: usersResponse?.experience_years || '',
    certification_details: usersResponse?.certification_details || '',
    insurance_details: usersResponse?.insurance_details || '',
    availability: mapAvailabilityToLabels(
      usersResponse?.availability || [],
      cities // Dynamically fetched cities list
    ),
  }}
  validationSchema={profileSchema}
  onSubmit={async (values) => {
    const updatedValues = {
      ...values,
      firstname: values.firstname || usersResponse?.firstname,
      lastname: values.lastname || usersResponse?.lastname,
      phone_no: values.phone_no || usersResponse?.phone_no,
      experience_years: values.experience_years || usersResponse?.experience_years,
      certification_details:
        values.certification_details || usersResponse?.certification_details,
      insurance_details: values.insurance_details || usersResponse?.insurance_details,
      availability: availabilities,  // Ensure this uses dynamic city codes
    }

    console.log(updatedValues)

    try {
      const response = await axios.put(
        `https://api.inspection.payplatter.in/api/inspectors/profile/${currenteUserId}`,
        updatedValues,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )
      console.log(response)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error submitting data:', error.response?.data || error.message)
      } else {
        console.error('Unexpected error:', error)
      }
    }
    calculateProfileCompletion()
  }}
>
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Profile Photo</label>

                  <div className='col-lg-8'>
                    <div className='image-input image-input-outline' data-kt-image-input='true'>
                      <div className='image-input-wrapper w-200px h-200px'>
                        {profilePic ? (
                          <img src={profilePic} alt='Profile' className='fixed-size-image' />
                        ) : (
                          <img src={blankprofilepic} alt='Signature' className='fixed-size-image' />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {profilePic ? null : (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className='required'>Your profile</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        className='form-control form-control-lg form-control-solid'
                        type='file'
                        id='formFile'
                        required
                        accept='.jpg, .jpeg, .png'
                        onChange={handleImageUpload}
                      />
                    </div>
                  </div>
                )}

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          name='firstname'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='First name'
                          value={usersResponse?.firstname || ''}
                          onChange={handleChange2}
                        />
                      </div>

                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          name='lastname'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Last name'
                          value={usersResponse?.lastname || ''}
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Contact Phone</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='tel'
                      name='phone_no'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Phone number'
                      value={usersResponse?.phone_no}
                      onChange={handleChange2}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Experience Years</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='number'
                      name='experience_years'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Certification Details'
                      value={usersResponse?.experience_years}
                      onChange={handleChange2}
                    />
                  </div>
                </div>

                {/* <div className='row mb-6 align-items-center'>
  <label className='col-lg-4 col-form-label fw-bold fs-6'>
    <span className='required'>Insurance Certification </span>
  </label>
  <div className='col-lg-8'>
    <button className='form-control form-control-lg form-control-solid'>
      Upload Certificate
    </button>
  </div>
</div> */}

                <div>
                  {/* Updated Insurance Certification Field */}
                  <div className='row mb-6 align-items-center'>
                    <label
                      className='col-lg-4 col-form-label fw-bold fs-6 cursor-pointer'
                      onClick={openModal}
                    >
                      <span className='required'>Insurance Certification </span>
                    </label>
                    <div className='col-lg-8'>
                      <button
                        className='form-control form-control-lg form-control-solid'
                        onClick={openModal}
                      >
                        Upload Certificate
                      </button>
                    </div>
                  </div>

                  {/* Modal for File Upload and Expiry Date */}
                  <Modal
                    show={isModalVisible}
                    onHide={closeModal}
                    centered
                    size='lg' // Increased modal size
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Upload Insurance Certificate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='card p-4 shadow-sm'>
                        <Form>
                          {/* Updated Card UI for File Upload */}
                          <Card
                            className='text-center mb-4'
                            style={{padding: '20px', border: '2px dashed #6c757d'}}
                          >
                            <Card.Body>
                              <div className='d-flex align-items-center justify-content-center flex-column'>
                                <i
                                  className='bi bi-upload'
                                  style={{
                                    fontSize: '3rem',
                                    color: '#6c757d',
                                    marginBottom: '15px',
                                  }}
                                ></i>
                                <Card.Text className='text-muted mb-3'>
                                  Drag & Drop your file here or click to select a file
                                </Card.Text>
                                <Button
                                  variant='outline-secondary'
                                  onClick={() => document.getElementById('fileUpload')?.click()}
                                >
                                  Choose File
                                </Button>
                              </div>
                            </Card.Body>
                            <Form.Control
                              type='file'
                              id='fileUpload'
                              accept='.jpg, .jpeg, .png, .pdf'
                              onChange={handleFileChange}
                              style={{display: 'none'}}
                            />
                            {selectedFile && (
                              <div className='mt-3'>
                                <p>Selected File: {selectedFile.name}</p>
                              </div>
                            )}
                          </Card>

                          {/* Expiry Date Field */}
                          <Form.Group controlId='expiryDate'>
                            <Form.Label>Expiry Date</Form.Label>
                            <Form.Control
                              type='date'
                              value={expiryDate}
                              onChange={handleDateChange}
                            />
                          </Form.Group>
                        </Form>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant='primary'
                        onClick={handleSubmitInsurance}
                      >
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                <div className='row mb-6'>
      <label className='col-lg-4 col-form-label fw-bold fs-6'>
        <span className='required'>Availability</span>
      </label>
      <div className='col-lg-8 fv-row'>
      <Select
      className='form-select-solid react-select-styled react-select-solid react-select-sm'
      classNamePrefix='react-select'
      options={cities}  // Options are now of type OptionType[]
      placeholder='Select Availabilities'
      value={cities.filter(city => availabilities.includes(city.value))}  // Filter selected cities
      onChange={handleChange}  // Updated to handle MultiValue<OptionType>
      isMulti
    />
      </div>
    </div>

                <div className='row mb-6 cursor-pointer'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Conditions
                  </label>

                  <div className='col-lg-8 d-flex justify-content-between'>
                    {/* Terms and Conditions */}
                    <div className='fv-row me-3'>
                      <div
                        onClick={() =>
                          handleOpenModal('Terms & Conditions', '/media/pdf/TermsAndConditions.pdf')
                        }
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        Terms & Condition
                      </div>
                      <div className='form-text'>Please Accept Terms & Conditions</div>
                    </div>

                    {/* Cancellation Policy */}
                    <div className='fv-row me-3'>
                      <div
                        onClick={() =>
                          handleOpenModal(
                            'Cancellation Policy',
                            '/media/pdf/CancellationPolicy.pdf'
                          )
                        }
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        Cancellation Policy
                      </div>
                      <div className='form-text'>Please Accept Cancellation Policy</div>
                    </div>

                    {/* Code of Conduct */}
                    <div className='fv-row'>
                      <div
                        onClick={() =>
                          handleOpenModal('Code of Conduct', '/media/pdf/CodeOfConduct.pdf')
                        }
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        Code of Conduct
                      </div>
                      <div className='form-text'>Please Accept Code of Conduct</div>
                    </div>
                  </div>

                  {/* Modal Component */}
                  <PdfModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    title={modalTitle}
                    pdfUrl={pdfUrl}
                    onAccept={handlecceptance}
                  />
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Your Signature</span>
                  </label>

                  {signatureSrc != '' ? (
                    <>
                      <div className='col-lg-6 fv-row' style={{border: '1px solid black'}}>
                        <img src={signatureSrc} alt='Signature' className='w-80 h-80' />
                      </div>

                      <div className='col-lg-2'>
                        <a
                          className='btn btn-primary mb-2'
                          target='blank'
                          href={signatureSrc}
                          download='signature.png'
                        >
                          Download
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-lg-6 fv-row' style={{border: '1px solid black'}}>
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor='black'
                          canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                        />
                      </div>
                      <div className='col-lg-2'>
                        <button className='btn btn-secondary mb-2' onClick={clearSignature}>
                          Clear
                        </button>
                        <button className='btn btn-dark mb-2' onClick={uploadSignature}>
                          Save
                        </button>
                        <button className='btn btn-primary mb-2' onClick={saveSignature}>
                          Download
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary'>
                  save changes
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  )
}

function dataURLToBlob(dataURL: string): Blob {
  const byteString = atob(dataURL.split(',')[1])
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], {type: mimeString})
}
export {ProfileOverview}
