import React, { useEffect, useState } from 'react';
import format from "date-fns/format";
import getDay from "date-fns/getDay";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import DatePicker from "react-datepicker";
import Modal from 'react-modal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import axios from 'axios';

// Localizer for react-big-calendar
const locales = {
    "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
});

// Inline styles
const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.25)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
};

const modalContentStyle = {
    position: 'relative',
    color: 'white',
    padding: '20px',
    border: 'none',
    borderRadius: '8px',
    outline: 'none',
    width: '80%',
    maxWidth: '500px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    backgroundColor: '#1e1e2d',
};

const containerStyle = {
    width: '100%',
};

const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    color: '#fff',
    cursor: 'pointer',
};

const Scheduler = () => {
    const [newEvent, setNewEvent] = useState({ title: "", start: null, end: null });
    const [allEvents, setAllEvents] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [allTasks, setAllTasks] = useState([]);

    useEffect(() => {
        axios.get('https://api.inspection.payplatter.in/api/inspection-requests')
            .then(res => {
                const transformedEvents = res.data.map(task => {
                    if (task.assigned_date) {
                        return {
                            title: task.property.address,
                            start: new Date(task.assigned_date),
                            end: new Date(task.assigned_date),
                        };
                    }
                    return null;
                }).filter(event => event !== null);
                setAllEvents(transformedEvents);
                setAllTasks(res.data);
            })
            .catch(err => console.error(err));
    }, []);

    const handleAddEvent = () => {
        if (!newEvent.title || !newEvent.start || !newEvent.end) {
            toast.error("Please fill in all fields");
            return;
        }

        for (let i = 0; i < allEvents.length; i++) {
            const event = allEvents[i];
            if ((new Date(event.start) <= new Date(newEvent.start) && new Date(newEvent.start) <= new Date(event.end)) ||
                (new Date(event.start) <= new Date(newEvent.end) && new Date(newEvent.end) <= new Date(event.end))) {
                toast.error("Event clash detected!");
                return;
            }
        }

        setAllEvents([...allEvents, newEvent]);
        setNewEvent({ title: "", start: null, end: null });
        setModalIsOpen(false);
        toast.success("Event added successfully!");
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setNewEvent({ title: event.title, start: event.start, end: event.end });
        setModalIsOpen(true);
    };


    return (
        <div style={containerStyle}>

            <Calendar
                localizer={localizer}
                events={allEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 300, margin: "20px"  ,width:700}}
                onSelectEvent={handleSelectEvent}
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{ overlay: modalOverlayStyle, content: modalContentStyle }}
                contentLabel="Event Modal"
            >
               
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Add Title"
                        className="form-control mb-2"
                        value={newEvent.title}
                        onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
                    />
                    <div className='d-flex gap-5 pb-5'>
                        <div className="mb-2">
                            <DatePicker
                                placeholderText="Start Date"
                                className="form-control mb-2"
                                selected={newEvent.start}
                                onChange={(start) => setNewEvent({ ...newEvent, start })}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                            />
                        </div>
                        <div className="mb-2">
                            <DatePicker
                                placeholderText="End Date"
                                className="form-control"
                                selected={newEvent.end}
                                onChange={(end) => setNewEvent({ ...newEvent, end })}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                timeCaption="time"
                            />
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-between">
                    <div className="col-auto">
                        
                    </div>
                    <div className="col-auto">
                        <button
                            onClick={() => setModalIsOpen(false)}
                            style={{ ...buttonStyle, backgroundColor: '#dc3545' }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal>
            <ToastContainer />
        </div>
    );
};

export default Scheduler;
