import React from 'react'
import { ListsWidget10 } from './ListsWidget10'



const BuyerDash = () => {
  return (
    <div>
      <ListsWidget10></ListsWidget10>
    </div>
  )
}

export default BuyerDash
